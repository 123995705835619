const EMPTY_TIME_STRING = '0:00'

export function decimalToHhMm(decimal: number): string {
  if (decimal === 0) {
    return EMPTY_TIME_STRING
  }

  let hours = 0
  let minutes = Math.round(decimal * 60)

  while (minutes >= 60) {
    hours += 1
    minutes -= 60
  }

  return hours + ':' + (minutes < 10 ? '0' : '') + minutes
}
