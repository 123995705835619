import { Grid } from '@mui/material'
import * as controllers from 'controllers'
import { PersonDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { default as AccountSetupCard } from './AccountSetupCard'
import { default as PersonDetailsCard } from './PersonDetailsCard'
import { useAuth, useLayout } from 'context'
import HumanResourcesCard from './HumanResourcesCard'
import TextMessagesCard from './TextMessagesCard'

export default function PeopleId() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { id } = useParams()
  const [isGettingPerson, setIsGettingPerson] = useState<boolean>(false)
  const [person, setPerson] = useState<PersonDto>(new PersonDto())
  const {
    permissions: { USERS_ADD_EDIT, HUMAN_RESOURCES }
  } = useAuth()

  useEffect(() => {
    setTitle('Person Details')
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsGettingPerson(true)
      controllers
        .getPersonById(id)
        .then(response => {
          setPerson(response.value)
        })
        .finally(() => {
          setIsGettingPerson(false)
        })
    }
  }, [id])

  return (
    <Grid container spacing={2} alignItems='flex-start'>
      <Grid item xs={12}>
        <PersonDetailsCard person={person} setPerson={setPerson} />
      </Grid>

      {USERS_ADD_EDIT && (
        <Grid item xs={12} sm={3}>
          <AccountSetupCard person={person} setPerson={setPerson} />
        </Grid>
      )}

      {HUMAN_RESOURCES && (
        <Grid item xs={12} sm={3}>
          <HumanResourcesCard person={person} />
        </Grid>
      )}

      <Grid item xs={12} sm={3}>
        <TextMessagesCard person={person} />
      </Grid>
    </Grid>
  )
}

export { default as PersonDetailsCards } from './PersonDetailsCard'
