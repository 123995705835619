import { styled } from '@mui/material'

const MenuLogo = styled('img')({
  minWidth: '128px',
  maxWidth: '128px',
  transition: 'transform .8s ease-in-out',
  ':hover': {
    transform: 'rotate(360deg)'
  }
})

//  MenuLogo spins on hover!
export default MenuLogo
