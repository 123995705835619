import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import { TimePunchDto, timePunchValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { formatDate, formatDateTime } from 'utils'
import LabelField from './LabelField'
import { ExplanationAccordion } from './_template/accordion'

interface TimePunchAddEditDialogProps {
  initialValues: TimePunchDto
  isLoading?: boolean
  // If true, display Missed Punch Date and Missed Punch Time fields.
  missedPunch?: boolean
  onClose?: () => void
  onSave: (values: TimePunchDto) => void
  open: boolean
  timePunches?: TimePunchDto[]
}

export default function TimePunchAddEditDialog({
  initialValues,
  isLoading,
  missedPunch = false,
  timePunches = [],
  onClose,
  onSave,
  open
}: TimePunchAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          timePunchDateTime:
            initialValues.timePunchDateTime && initialValues.timePunchDateTime.length > 19
              ? initialValues.timePunchDateTime.substring(0, 16) // Note: With a datetime-local input, the date value is always normalized to the format YYYY-MM-DDThh:mm
              : ''
        }}
        validateOnBlur
        validateOnChange
        validationSchema={timePunchValidationSchema}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.resetForm()
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <DialogTitle>
                    {missedPunch ? 'Add Missing Time Punch' : (values.id ? 'Edit' : 'Add') + ' Time Punch'}
                  </DialogTitle>

                  {missedPunch && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant='body1'>
                          You did not punch out on {formatDate(timePunches[0].timePunchDateTime)}. Please add the missing time
                          punch to continue in Spud ERP.
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body1'>
                          Time Punches for {formatDate(timePunches[0].timePunchDateTime)}:
                        </Typography>
                      </Grid>

                      {timePunches.map((timePunch, index) => (
                        <Grid item xs={12} container spacing={1} justifyContent='space-between'>
                          <Grid item>
                            <Typography variant='body2'>{formatDateTime(timePunch.timePunchDateTime!)}</Typography>
                          </Grid>

                          <Grid item>
                            <Typography variant='body2'>{index % 2 === 0 ? 'In' : 'Out'}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}

                  {missedPunch ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading}
                          error={Boolean(touched.timePunchDateTime && errors.timePunchDateTime)}
                          helperText={touched.timePunchDateTime && errors.timePunchDateTime}
                          InputLabelProps={{ shrink: true }}
                          label='Missing Time Punch'
                          name='timePunchDateTime'
                          onBlur={handleBlur}
                          onChange={e => {
                            const { timePunchDateTime } = timePunches[0]

                            if (timePunchDateTime) {
                              const date = timePunchDateTime.substring(0, 10)
                              const time = e.target.value + ':00'
                              const offset = timePunchDateTime.substring(19, 25)

                              const value = date + 'T' + time + offset

                              if (date && time) {
                                setFieldValue('timePunchDateTime', value)
                              } else {
                                setFieldValue('timePunchDateTime', undefined)
                              }
                            }
                          }}
                          type='time'
                          value={values.timePunchDateTime ? values.timePunchDateTime?.substring(11, 16) : ''}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <LabelField label='Team Member' value={values.person?.firstName + ' ' + values?.person?.lastName} />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading}
                          error={Boolean(touched.timePunchDateTime && errors.timePunchDateTime)}
                          helperText={touched.timePunchDateTime && errors.timePunchDateTime}
                          InputLabelProps={{ shrink: true }}
                          label='Time Punch Date/Time'
                          name='timePunchDateTime'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type='datetime-local'
                          value={values.timePunchDateTime}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Punch Date/Time will default to todays date. ||Missed Punch Date will only show if the user has a missed
                      punch and defaults to the date of the missed punch. ||Missed Punch Time will show if the user has a missed
                      punch and allows the user to complete the time for the missed punch. ||The Punch Date/Time will be
                      disabled if the user has a missed punch. ||Add Time Punch Dialog box will automatically show after sign in
                      if the user has a missed punch.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                {onClose && (
                  <Button
                    color='inherit'
                    onClick={() => {
                      onClose()
                    }}
                    variant='text'
                  >
                    CANCEL
                  </Button>
                )}

                <LoadingButton loading={isLoading} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
