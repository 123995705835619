import { apiClient } from '_app'
import { ApiResponse, OpportunityActivityDto } from 'dtos'
import { OpportunityActivityParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getOpportunityActivity = (opportunityGuid: string, parameters: OpportunityActivityParameters) =>
  apiClient
    .get<ApiResponse<OpportunityActivityDto[]>>('/api/opportunity-activities' + URIQuery({ ...parameters }))
    .then(response => response.data)

export const getOpportunityActivityById = (id: string) =>
  apiClient
    .get<ApiResponse<OpportunityActivityDto>>('/api/opportunity-activities' + encodeURIComponent(id))
    .then(response => response.data)

export const addActivity = (values: OpportunityActivityDto) =>
  apiClient
    .post<ApiResponse<OpportunityActivityDto>>('/api/opportunity-activities', {
      ...values,
      opportunity: { ...values.opportunity, opportunityActivity: [] }
    })
    .then(({ data }) => data)
