import * as api from 'controllers'
import { TimePunchDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { formatDateValue, useDebounce } from 'utils'
import { TimePunchesParameters } from 'parameters'

export const TimePunchContext = createContext<{
  status: 'loading' | 'in' | 'out'
  punch: () => void
  lastPunch: Date | null
  timePunches: TimePunchDto[]
  isCreatingTimePunch: boolean
  isGettingTimePunches: boolean
  setLastPunch: React.Dispatch<React.SetStateAction<Date | null>>
  getTimePunches: (parameters: TimePunchesParameters) => void
  saveTimePunch: (timePunch: TimePunchDto) => void
} | null>(null)

export default function TimePunchProvider({ children }: React.PropsWithChildren) {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [timePunches, setTimePunches] = useState<TimePunchDto[]>([])
  const [isCreatingTimePunch, setIsCreatingTimePunch] = useState<boolean>(false)
  const [isGettingTimePunches, setGettingTimePunches] = useState<boolean>(false)
  const [status, setStatus] = useState<'loading' | 'in' | 'out'>('loading')
  const [lastPunch, setLastPunch] = useState<Date | null>(null)
  const [lastParameters, setLastParameters] = useState<TimePunchesParameters | null>(null)

  const punch = () => {
    setStatus('loading')
    api.createTimePunch().then(() => {
      getTimePunches(
        lastParameters || {
          page: 0,
          pageSize: 999,
          dateFrom: formatDateValue(new Date())
        }
      )
      setStatus(status === 'in' ? 'out' : 'in')
      const punchedInOut = status === 'in' ? 'Out' : 'In'
      enqueueSnackbar(`Punched ${punchedInOut} Successfully!`, {
        variant: 'success'
      })
    })
  }
  const getTimePunches = (parameters: TimePunchesParameters) => {
    setLastParameters(parameters)
    setGettingTimePunches(true)
    api
      .getTimePunches(parameters)
      .then(res => {
        setTimePunches(res.value)
      })
      .catch((errors: string[]) => {
        errors.forEach(error => {
          enqueueSnackbar(error, { variant: 'error' })
        })
      })
      .finally(() => {
        setGettingTimePunches(false)
      })
  }
  const saveTimePunch = (timePunch: TimePunchDto) => {
    setIsCreatingTimePunch(true)

    const request = timePunch.id ? api.updateTimePunch : api.createTimePunch

    request(timePunch)
      .then(response => {
        enqueueSnackbar('Time Punch Saved Successfully!', { variant: 'success' })
        setTimePunches(
          timePunch.id ? timePunches.map(t => (t.id === timePunch.id ? response.value : t)) : [...timePunches, response.value]
        )
      })
      .finally(() => {
        setIsCreatingTimePunch(false)
      })
  }

  useEffect(() => {
    if (timePunches[0]?.person?.id === user?.id) {
      setStatus(timePunches.length % 2 === 0 ? 'out' : 'in')
    }
  }, [timePunches])

  const checkTimeLapse = () => {
    const now = new Date()

    if (lastPunch && status !== 'loading') {
      const oneDayInMillis = 24 * 60 * 60 * 1000
      if (now.getTime() - lastPunch.getTime() > oneDayInMillis) {
        setStatus('out')
        setLastPunch(null)
      }
    }
  }

  useEffect(() => {
    checkTimeLapse()
    if (user && user.id) {
      api
        .getTimePunches({
          page: 0,
          pageSize: 999,
          personId: user.id,
          dateFrom: formatDateValue(new Date())
        })
        .then(({ value }) => {
          setStatus(value.length % 2 === 0 ? 'out' : 'in')
          setTimePunches(value)
          setLastPunch(new Date())
        })
      // if (isFirstSignInOfDay(lastPunch)) {
      //    punch()
      //  }
    }

    const interval = setInterval(() => {
      checkTimeLapse()
    }, 60 * 60 * 1000)

    return () => clearInterval(interval)
  }, [user])

  return (
    <TimePunchContext.Provider
      value={{
        status,
        punch,
        lastPunch,
        setLastPunch,
        getTimePunches,
        saveTimePunch,
        timePunches,
        isCreatingTimePunch,
        isGettingTimePunches
      }}
    >
      {children}
    </TimePunchContext.Provider>
  )
}

export const useTimePunch = () => useContext(TimePunchContext)!
