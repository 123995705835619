import { apiClient } from '_app'
import { ApiResponse, WorkOrderBillingTypeDto } from 'dtos'
import { WorkOrderBillingTypesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderBillingTypes = (parameters: WorkOrderBillingTypesParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderBillingTypeDto[]>>(
      '/api/work-order-billing-types' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
