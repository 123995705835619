import { apiClient } from '_app'
import { ApiResponse, ToDosSummaryDto, WorkOrderReleaseTaskDto } from 'dtos'
import { WorkOrderReleaseTasksByTeamMemberIdParameters, WorkOrderReleaseTasksParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderReleaseTasks = (parameters: WorkOrderReleaseTasksParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto[]>>('/api/work-order-release-tasks' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrderReleaseTasksByWorkOrderReleaseId = (
  parameters: WorkOrderReleaseTasksParameters,
  workOrderId: string,
  workOrderReleaseId: string
) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto[]>>(
      `/api/work-orders/${encodeURIComponent(workOrderId)}/releases/${encodeURIComponent(workOrderReleaseId)}/tasks` +
        URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getWorkOrderReleaseTaskKpis = (parameters: WorkOrderReleaseTasksParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto[]>>('/api/work-order-release-tasks/kpis' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrderReleaseTasksByWorkOrderId = (parameters: WorkOrderReleaseTasksParameters, workOrderId: string) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto[]>>(`/api/work-orders/${workOrderId}/tasks` + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrderReleaseTasksByTeamMemberId = (
  parameters: WorkOrderReleaseTasksByTeamMemberIdParameters,
  workOrderId?: string,
  releaseId?: string
) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto[]>>(
      `/api/work-orders/${workOrderId}/releases/${releaseId}/tasks/by-team-member-id` + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getToDosSummary = (
  parameters: WorkOrderReleaseTasksByTeamMemberIdParameters,
  workOrderId?: string,
  releaseId?: string
) =>
  apiClient
    .get<ApiResponse<ToDosSummaryDto>>(
      `/api/work-orders/${workOrderId}/releases/${releaseId}/tasks/to-dos-summary` + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getWorkOrderReleaseTaskById = (id: string, workOrderId?: string, releaseId?: string) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskDto>>(`/api/work-orders/${workOrderId}/releases/${releaseId}/tasks/${id}`)
    .then(({ data }) => data)

export const createWorkOrderReleaseTask = (values: WorkOrderReleaseTaskDto, workOrderId?: string, releaseId?: string) =>
  apiClient.post<ApiResponse<WorkOrderReleaseTaskDto>>('/api/work-order-release-tasks', values).then(({ data }) => data)

export const updateWorkOrderReleaseTask = (values: WorkOrderReleaseTaskDto, workOrderId?: string, releaseId?: string) =>
  apiClient.put<ApiResponse<WorkOrderReleaseTaskDto>>('/api/work-order-release-tasks', values).then(({ data }) => data)

export const putPrioritize = (values: WorkOrderReleaseTaskDto, priority?: string) =>
  apiClient
    .put<ApiResponse<WorkOrderReleaseTaskDto>>('/api/work-order-release-tasks/prioritize', values)
    .then(({ data }) => data)
