import { apiClient } from '_app'

export const getWorkOrderStatusReport = (workOrderId: string) => {
  const url = `/api/telerik-reports/work-order-status-report?workOrderId=` + encodeURIComponent(workOrderId)

  return apiClient.get(url).then(response => response.data)
}

export const getDevelopmentStandUpReport = () =>
  apiClient.get<string>('/api/telerik-reports/development-stand-up-report').then(response => response.data)

export const getOpenSaleOpportunitiesReport = () => {
  const url = `/api/telerik-reports/open-sale-opportunities-report`
  return apiClient.get(url).then(response => response.data)
}

export const getPayrollReport = (p0: { startDate: string; endDate: string }) => {
  const url = `/api/telerik-reports/payroll-report`
  return apiClient.get(url).then(response => response.data)
}

export const getSalesActivityToGetDoneReport = (salespersonId: string) => {
  const url = `/api/telerik-reports/sales-activity-to-get-done-report?salespersonId=` + encodeURIComponent(salespersonId)
  return apiClient.get(url).then(response => response.data)
}

export const getTimeBilledByWeekByTeamMemberReport = () => {
  const url = `/api/telerik-reports/time-billed-by-week-by-team-member-report`
  return apiClient.get(url).then(response => response.data)
}

export const getTimeLoggedCteReport = () => {
  const url = `/api/telerik-reports/time-logged-cte-report`
  return apiClient.get(url).then(response => response.data)
}

export const getUserAccessReport = () => {
  const url = `/api/telerik-reports/user-access-report`
  return apiClient.get(url).then(response => response.data)
}

export const getVacationTimeLoggedDetailReport = () => {
  const url = `/api/telerik-reports/vacation-time-logged-detail-report`
  return apiClient.get(url).then(response => response.data)
}

export const getGenericReport = (reportFileName: string) => {
  const url = '/api/telerik-reports/generic?reportFileName=' + encodeURIComponent(reportFileName)

  return apiClient.get(url).then(response => response.data)
}

export const getWorkDoneReport = (workDate: string) => {
  const url = `/api/telerik-reports/work-done-report?workDate=` + encodeURIComponent(workDate)

  return apiClient.get(url).then(response => response.data)
}

export const getVendorReport = () => {
  const url = `/api/telerik-reports/vendor-report`
  return apiClient.get(url).then(response => response.data)
}
