import { TimePunchDto } from 'dtos'

const EMPTY_TIME_STRING = '0:00'

export function getTimePunched(
  timePunches: TimePunchDto[],
  showSeconds: boolean = false
): string {
  const SORTED_TIME_PUNCHES = [...timePunches].sort(
    (a, b) =>
      new Date(a.timePunchDateTime!).getTime() - new Date(b.timePunchDateTime!).getTime()
  )

  // If there are no time punches, return 0:00
  if (SORTED_TIME_PUNCHES.length === 0) {
    return EMPTY_TIME_STRING
  }

  // Api needs to cast time zones :)

  // If the user is currently punched in, and it is the same date, use the current time...
  if (SORTED_TIME_PUNCHES.length % 2 !== 0) {
    let lastPunchDateTime = new Date(
      SORTED_TIME_PUNCHES[SORTED_TIME_PUNCHES.length - 1].timePunchDateTime!
    )
    let rightNow = new Date()

    // If the last punch of SORTED_TIME_PUNCHES is today, use the current time as endTime. Otherwise, use the last punch of SORTED_TIME_PUNCHES' date at 11:59:59 PM.
    let endTime =
      lastPunchDateTime.getFullYear() === rightNow.getFullYear() &&
      lastPunchDateTime.getMonth() === rightNow.getMonth() &&
      lastPunchDateTime.getDate() === rightNow.getDate()
        ? rightNow
        : new Date(
            lastPunchDateTime.getFullYear(),
            lastPunchDateTime.getMonth(),
            lastPunchDateTime.getDate(),
            23,
            59,
            59
          )

    SORTED_TIME_PUNCHES.push({
      ...new TimePunchDto(),
      timePunchDateTime: endTime.toString()
    })
  }

  const timePunched = SORTED_TIME_PUNCHES.reduce(
    (accumulator, timePunch) =>
      new Date(timePunch.timePunchDateTime!).getTime() - accumulator,
    0
  )

  const hours = Math.floor(timePunched / (1_000 * 60 * 60))
  const minutes = Math.floor((timePunched % (1_000 * 60 * 60)) / (1_000 * 60))
  const seconds = Math.floor((timePunched % (1000 * 60)) / 1000)

  return (
    hours +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    (showSeconds ? ':' + (seconds < 10 ? '0' + seconds : seconds) : '')
  )
}
