import { Badge, ListItemButton, ListItemButtonProps } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'

interface AppMenuItemProps extends ListItemButtonProps {
  badgeContent?: number
  onCloseMenu: () => void
  to: string
}

export function AppMenuItem({ badgeContent, children, onCloseMenu, to }: React.PropsWithChildren<AppMenuItemProps>) {
  const location = useLocation()

  return (
    <ListItemButton
      component={NavLink}
      onClick={() => {
        onCloseMenu()
      }}
      selected={location.pathname.endsWith(to)}
      to={to}
      sx={{
        color: '#fff',
        marginLeft: '24px',
        borderRadius: '24px 0px 0px 24px',
        paddingY: '12px',
        '&.active, &.active:hover, :hover': {
          backgroundColor: '#fff',
          color: '#000'
        }
      }}
    >
      <Badge badgeContent={badgeContent} color='error'>
        {children}
      </Badge>
    </ListItemButton>
  )
}
