import * as yup from 'yup'

export class AddressTypeDto {
  id?: string = undefined
  name: string = ''

  constructor(model: any) {}
}

export const addressTypeValidationSchema = yup.object({
  id: yup.string(),
  addressType: yup.string()
})
