import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DepartmentDto, PersonHumanResourcesDto, personHumanResourcesValidationSchema } from 'dtos'
import { Formik } from 'formik'
import moment from 'moment'

interface HumanResourcesEditDialogProps {
  departments: DepartmentDto[]
  initialValues: PersonHumanResourcesDto
  loading: boolean
  onClose: () => void
  onSave: (values: PersonHumanResourcesDto) => void
  open: boolean
}

export default function HumanResourcesEditDialog({
  departments,
  initialValues,
  loading,
  onClose,
  onSave,
  open
}: HumanResourcesEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={personHumanResourcesValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Edit Human Resources</DialogTitle>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={loading || isSubmitting}
                      label='Start Date'
                      name='employmentStartDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('employmentStartDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('employmentStartDate', undefined)
                        }
                      }}
                      value={values.employmentStartDate ? moment(values.employmentStartDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={loading || isSubmitting}
                      label='End Date'
                      name='employmentEndDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('employmentEndDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('employmentEndDate', undefined)
                        }
                      }}
                      value={values.employmentEndDate ? moment(values.employmentEndDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id='departments-label'>Departments</InputLabel>

                      <Select
                        disabled={loading || isSubmitting}
                        labelId='departments-label'
                        multiple
                        fullWidth
                        value={values.departments.map(department => department.id)}
                        onChange={(event: SelectChangeEvent<string[]>, _child: React.ReactNode) => {
                          if (Array.isArray(event.target.value)) {
                            setFieldValue(
                              'departments',
                              departments.filter(department => event.target.value.includes(department.id))
                            )
                          }
                        }}
                        input={<OutlinedInput label='Departments' />}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map(departmentId => (
                              <Chip
                                key={departmentId}
                                label={departments.filter(department => department.id === departmentId)[0]?.name ?? ''}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {departments
                          .map(department => department.id)
                          .map(departmentId => (
                            <MenuItem key={departmentId} value={departmentId}>
                              <Checkbox
                                checked={values.departments.map(department => department.id).indexOf(departmentId) > -1}
                              />
                              <ListItemText
                                primary={departments.filter(department => department.id === departmentId)[0]?.name ?? ''}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.birthMonth && errors.birthMonth)}
                      helperText={touched.birthMonth && errors.birthMonth}
                      label='Birth Month'
                      name='birthMonth'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('birthMonth', e.target.value)
                        setFieldValue('birthDate', undefined)
                      }}
                      select
                      type='number'
                      value={values.birthMonth || ''}
                    >
                      <MenuItem value={1}>January</MenuItem>
                      <MenuItem value={2}>February</MenuItem>
                      <MenuItem value={3}>March</MenuItem>
                      <MenuItem value={4}>April</MenuItem>
                      <MenuItem value={5}>May</MenuItem>
                      <MenuItem value={6}>June</MenuItem>
                      <MenuItem value={7}>July</MenuItem>
                      <MenuItem value={8}>August</MenuItem>
                      <MenuItem value={9}>September</MenuItem>
                      <MenuItem value={10}>October</MenuItem>
                      <MenuItem value={11}>November</MenuItem>
                      <MenuItem value={12}>December</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.birthDate && errors.birthDate)}
                      helperText={touched.birthDate && errors.birthDate}
                      label='Birth Date'
                      name='birthDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      type='number'
                      value={values.birthDate || ''}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={16}>16</MenuItem>
                      <MenuItem value={17}>17</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={19}>19</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                      <MenuItem value={22}>22</MenuItem>
                      <MenuItem value={23}>23</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={26}>26</MenuItem>
                      <MenuItem value={27}>27</MenuItem>
                      <MenuItem value={28}>28</MenuItem>
                      <MenuItem value={29}>29</MenuItem>
                      {values.birthMonth && [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(values.birthMonth) && (
                        <MenuItem value={30}>30</MenuItem>
                      )}
                      {values.birthMonth && [1, 3, 5, 7, 8, 10, 12].includes(values.birthMonth) && (
                        <MenuItem value={31}>31</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
