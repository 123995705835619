import { apiClient } from '_app'
import { ApiResponse, WorkOrderReleaseTaskTimeEntryDto } from 'dtos'
import { WorkOrderReleaseTaskTimeEntriesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderReleaseTaskTimeEntries = (parameters: WorkOrderReleaseTaskTimeEntriesParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseTaskTimeEntryDto[]>>(
      '/api/work-order-release-task-time-entries' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const createWorkOrderReleaseTaskTimeEntry = (values: WorkOrderReleaseTaskTimeEntryDto) =>
  apiClient
    .post<ApiResponse<WorkOrderReleaseTaskTimeEntryDto>>(`/api/work-order-release-task-time-entries`, values)
    .then(({ data }) => data)
    .catch(error => {
      throw error
    })

export const updateWorkOrderReleaseTaskTimeEntry = (values: WorkOrderReleaseTaskTimeEntryDto) =>
  apiClient
    .put<ApiResponse<WorkOrderReleaseTaskTimeEntryDto>>(`/api/work-order-release-task-time-entries`, values)
    .then(({ data }) => data)

export const moveWorkOrderReleaseTaskTimeEntry = (values: WorkOrderReleaseTaskTimeEntryDto) =>
  apiClient
    .put<ApiResponse<WorkOrderReleaseTaskTimeEntryDto>>(`/api/work-order-release-task-time-entries/move`, values)
    .then(({ data }) => data)
