import React, { useEffect } from 'react'
import PDFObject from 'pdfobject'

interface PDFObjectBase64Props {
  base64: string
  visible?: boolean
}

// TODO: Why does this exist? Explain why Telerik sucks
export default function PDFObjectBase64({ base64, visible }: PDFObjectBase64Props) {
  useEffect(() => {
    if (base64) {
      const div = document.getElementById('pdfobject')
      PDFObject.embed('data:application/pdf;base64,' + base64, div, { width: '100%', height: '88vh' })
    }
  }, [base64])

  return <div id='pdfobject' style={{ width: '100%', minHeight: '88vh' }}></div>
}
