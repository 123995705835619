import { Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import MovingIcon from '@mui/icons-material/Moving'
import { useAuth } from 'context'
import { WorkOrderReleaseTaskTimeEntryDto, WorkOrderTaskMoveableTimeEntryDto } from 'dtos'
import { formatDate, getReleaseNumber } from 'utils'
import WorkOrderReleaseTaskMoveTimeEntryAddEditDialog from './id/WorkOrderReleaseTaskMoveTimeEntryAddEditDialog'
import { useState } from 'react'
import * as api from 'controllers'
import { enqueueSnackbar } from 'notistack'
interface TimeEntryListCardProps {
  timeEntry: WorkOrderReleaseTaskTimeEntryDto
  setTimeEntry: (timeEntry: WorkOrderReleaseTaskTimeEntryDto) => void
  setTimeEntryDialogOpen: (isOpen: boolean) => void
  setTimeEntryToMove: (timeEntryToMove: WorkOrderTaskMoveableTimeEntryDto) => void
  setMoveTimeEntryDialogOpen: (isOpen: boolean) => void
}

export default function TimeEntryListCard({
  timeEntry,
  setTimeEntry,
  setTimeEntryDialogOpen,
  setTimeEntryToMove,
  setMoveTimeEntryDialogOpen
}: TimeEntryListCardProps) {
  const {
    permissions: { TIME_ENTRIES_ADD_EDIT, CAN_MOVE_TIME_ENTRIES_FOR_OTHER_USERS }
  } = useAuth()

  const getSanitizedWorkToBeDone = (workToBeDone?: string) => {
    var addEllipsis = false
    if (workToBeDone === undefined || workToBeDone === '') {
      return ''
    } else {
      var specialCharactersToRemoveRegex: RegExp = /[\n\r]/g

      workToBeDone = workToBeDone.replace(specialCharactersToRemoveRegex, ' ').trim()

      if (workToBeDone.length > 400) {
        addEllipsis = true
      }

      workToBeDone = workToBeDone.substring(0, 399) + (addEllipsis ? '...' : '')

      return workToBeDone
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm container spacing={0} alignItems='center'>
                <Grid item>
                  <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                    {timeEntry.workOrderReleaseTask?.workOrderRelease?.workOrder?.company?.name +
                      ' - ' +
                      timeEntry.workOrderReleaseTask?.workOrderRelease?.workOrder?.project?.name +
                      ' - #' +
                      timeEntry.workOrderReleaseTask?.workOrderRelease?.workOrder?.workOrderNumber +
                      ' - ' +
                      timeEntry?.workOrderReleaseTask?.workOrderRelease?.releaseTitle +
                      ' - ' +
                      getReleaseNumber(timeEntry!.workOrderReleaseTask!.workOrderRelease!)}
                  </Typography>
                </Grid>
                {timeEntry.taskCanBeClosed === true && (
                  <Grid item>
                    <Chip variant='gray'>Closed</Chip>
                  </Grid>
                )}
              </Grid>

              {TIME_ENTRIES_ADD_EDIT && (
                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<Edit />}
                    onClick={() => {
                      setTimeEntry(timeEntry)
                      setTimeEntryDialogOpen(true)
                    }}
                    variant='text'
                  >
                    EDIT
                  </Button>
                </Grid>
              )}

              {CAN_MOVE_TIME_ENTRIES_FOR_OTHER_USERS && (
                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<MovingIcon />}
                    onClick={() => {
                      setTimeEntryToMove({
                        id: timeEntry.id
                        // TODO: Should it pre-populate the work order, company, etc?
                      })
                      setMoveTimeEntryDialogOpen(true)
                    }}
                    variant='text'
                  >
                    MOVE TIME ENTRY
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Work To Be Done</Typography>
              <Typography color='primary' variant='body1'>
                {getSanitizedWorkToBeDone(timeEntry.workOrderReleaseTask?.workToBeDone)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Hours Worked</Typography>
              <Typography color='primary' variant='body1'>
                {timeEntry.hoursWorked +
                  (timeEntry.hoursWorked == 1 ? ' hour' : ' hours') +
                  ' worked on ' +
                  formatDate(timeEntry.workDate)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Description of Work Done</Typography>
              <Typography color='primary' variant='body1'>
                {timeEntry.descriptionOfWorkDone!}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
