import { apiClient } from '_app'
import { ApiResponse, ReportDto } from 'dtos'
import { HoursWorkedTrendReportParameters } from 'parameters'
import { WorkDoneReportParameters } from 'parameters/workDoneReportParameters'
import { WorkOrderStatusReportParameters } from 'parameters/workOrderStatusReportParameters'
import { PayrollReportParameters } from 'parameters/payrollReportParameters'
import { NASTFHoursWorkedDetailsReportParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getReports = () => apiClient.get<ApiResponse<ReportDto[]>>('/api/reports').then(response => response.data)

export const getHoursWorkedTrendReport = (parameters: HoursWorkedTrendReportParameters) =>
  apiClient.get('/api/reports/hours-worked-trend' + URIQuery({ ...parameters })).then(response => response.data)

export const getWorkDoneReport = (parameters: WorkDoneReportParameters) =>
  apiClient.get('/api/reports/work-done' + URIQuery({ ...parameters })).then(response => response.data)

export const getUserAccessReport = () => apiClient.get('/api/reports/user-access').then(response => response.data)

export const getWorkOrderStatusReport = (parameters: WorkOrderStatusReportParameters) =>
  apiClient.get('/api/reports/work-order-status' + URIQuery({ ...parameters })).then(response => response.data)

export const getPayrollReport = (parameters: PayrollReportParameters) =>
  apiClient.get('/api/reports/payroll' + URIQuery({ ...parameters })).then(response => response.data)

export const getTimeBilledByWeekByTeamMemberReport = () =>
  apiClient.get('/api/reports/time-billed-by-week-by-team-member').then(response => response.data)

export const getTimeLoggedCteReport = () => apiClient.get('/api/reports/time-logged-cte').then(response => response.data)

export const getVacationTimeLoggedDetailReport = () =>
  apiClient.get('/api/reports/vacation-time-logged-detail').then(response => response.data)

export const getVendorReport = () => apiClient.get('/api/reports/vendor').then(response => response.data)

export const getNASTFHoursWorkedDetailsReport = (parameters: NASTFHoursWorkedDetailsReportParameters) =>
  apiClient.get('/api/reports/nastf-hours-worked-details' + URIQuery({ ...parameters })).then(response => response.data)
