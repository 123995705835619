import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
  TablePaginationBaseProps as MuiTablePaginationBaseProps
} from '@mui/material'
import React, { useEffect } from 'react'

interface Pagination {
  count: number
  page: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

interface TablePaginationProps
  extends Omit<MuiTablePaginationProps, 'count' | 'page' | 'rowsPerPage' | 'onPageChange' | 'onRowsPerPageChange'> {
  component?: React.ElementType
  pagination: Pagination
}

export default function TablePagination({ component, pagination, ...other }: TablePaginationProps) {
  const getCount = (): number => {
    let pageCount = (pagination?.count ?? 0) / (pagination?.pageSize ?? 10)

    pageCount = Math.ceil(pageCount)

    return pageCount
  }

  useEffect(() => {
    const pagesNeeded = getCount()
    // If at least 1 page is needed to fit list items, and current page is greater than last page needed, change to last page.
    if (pagesNeeded >= 1 && (pagination?.page ?? 0) + 1 >= pagesNeeded) {
      // '+ 1' compensates for 0-indexing.
      pagination?.onPageChange(pagesNeeded - 1) // '- 1' compensates for 0-indexing.
    }
  }, [pagination?.count, pagination?.pageSize])

  return (
    <MuiTablePagination
      component={component ?? 'div'}
      count={pagination.count}
      onPageChange={(_, page: number) => {
        pagination.onPageChange(page)
      }}
      onRowsPerPageChange={e => {
        pagination.onPageSizeChange(e.target.value as unknown as number)
      }}
      page={pagination.page}
      rowsPerPage={pagination.pageSize}
      rowsPerPageOptions={
        other.rowsPerPageOptions ?? [
          { label: '5', value: 5 },
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: '100', value: 100 }
        ]
      }
      SelectProps={other.SelectProps ?? { fullWidth: false }}
      {...other}
    />
  )
}
