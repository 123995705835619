import { StatusCount } from 'dtos'

export const getStatusCount = (statusCounts: StatusCount[], status: string) => {
  let statusCount = statusCounts.filter(statusCount => statusCount.status === status)[0]

  if (statusCount) {
    return statusCount.count
  }

  return 0
}
