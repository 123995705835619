import { Button, Divider, Grid } from '@mui/material'
import { useLayout } from 'context'
import * as controllers from 'controllers'
import { OpportunityActivityDto, OpportunityDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OpportunityActivityHistoryCard from './OpportunityActivityHistoryCard'
import OpportunityDetailsCard from './OpportunityDetailsCard'
import OpportunityHeroCard from '../OpportunityHeroCard'
import { Edit, KeyboardArrowLeft } from '@mui/icons-material'
import OpportunityAddEditDialog from './OpportunityAddEditDialog'
import { enqueueSnackbar } from 'notistack'

export default function OpportunityId() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { id } = useParams()
  const navigate = useNavigate()

  const [opportunity, setOpportunity] = useState<OpportunityDto>(new OpportunityDto())
  const [opportunityActivity, setOpportunityActivity] = useState<OpportunityActivityDto>()
  const [opportunityAddEditDialogOpen, setOpportunityAddEditDialogOpen] = useState<boolean>(false)
  const [isUpdatingOpportunity, setIsUpdatingOpportunity] = useState<boolean>(false)

  useEffect(() => {
    setTitle('Opportunity Details')
  }, [])

  useEffect(() => {
    if (id) {
      setIsLayoutLoading(true)
      controllers
        .getOpportunityById(id)
        .then(response => {
          setOpportunity(response.value)
        })
        .finally(() => {
          setIsLayoutLoading(false)
        })
    }
  }, [id])

  const { opportunityGuid } = useParams<{ opportunityGuid: string }>()

  return (
    <>
      <OpportunityAddEditDialog
        open={opportunityAddEditDialogOpen}
        isLoading={isUpdatingOpportunity}
        onClose={() => {
          setOpportunityAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsUpdatingOpportunity(true)
          controllers
            .updateOpportunity(values)
            .then(response => {
              setOpportunity(response.value)
              setOpportunityAddEditDialogOpen(false)
              enqueueSnackbar('Opportunity Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsUpdatingOpportunity(false)
            })
        }}
        initialValues={opportunity}
      />

      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <OpportunityHeroCard opportunity={opportunity}>
            <Grid item>
              <Button
                startIcon={<KeyboardArrowLeft />}
                onClick={() => {
                  navigate(-1)
                }}
                variant='text'
              >
                BACK
              </Button>
            </Grid>

            <Grid item sx={{ alignSelf: 'stretch' }}>
              <Divider orientation='vertical' />
            </Grid>

            <Grid item>
              <Button
                endIcon={<Edit />}
                onClick={() => {
                  setOpportunityAddEditDialogOpen(true)
                }}
                variant='text'
              >
                EDIT
              </Button>
            </Grid>
          </OpportunityHeroCard>
        </Grid>

        <Grid item xs={12}>
          <OpportunityActivityHistoryCard opportunity={opportunity} />
        </Grid>
      </Grid>
    </>
  )
}
