import { useLayout } from 'context'
import { AssetDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as controllers from 'controllers'
import AssetAddEditDialog from './AssetAddEditDialog'
import { enqueueSnackbar } from 'notistack'
import AssetHeroCard from '../AssetHeroCard'
import { Button, Divider, Grid } from '@mui/material'
import { Edit, KeyboardArrowLeft } from '@mui/icons-material'
import AssetDetailsCard from './AssetDetailsCard'
import { useDebounce } from 'utils'

export default function AssetId() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { id } = useParams()
  const navigate = useNavigate()

  const [asset, setAsset] = useState<AssetDto>(new AssetDto())

  const [assetAddEditDialogOpen, setAssetAddEditDialogOpen] = useState<boolean>(false)
  const [isUpdatingAsset, setIsUpdatingAsset] = useState<boolean>(false)

  useEffect(() => {
    setTitle('Asset Details')
  }, [setTitle])

  useEffect(() => {
    getAsset()
  }, [id])

  const getAsset = useDebounce(() => {
    if (id) {
      setIsLayoutLoading(true)
      controllers
        .getAssetById(id!)
        .then(response => {
          setAsset(response.value)
        })
        .finally(() => {
          setIsLayoutLoading(false)
        })
    }
  }, 300)

  return (
    <>
      <AssetAddEditDialog
        open={assetAddEditDialogOpen}
        isLoading={isUpdatingAsset}
        onClose={() => {
          setAssetAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsUpdatingAsset(true)
          controllers
            .updateAsset(values)
            .then(response => {
              setAsset(response.value)
              setAssetAddEditDialogOpen(false)
              enqueueSnackbar('Asset Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsUpdatingAsset(false)
            })
        }}
        initialValues={asset}
      />

      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <AssetHeroCard asset={asset}>
            <Grid container justifyContent='flex-end' alignItems='center'>
              <Grid item>
                <Button
                  color='primary'
                  onClick={() => {
                    navigate('/assets')
                  }}
                  startIcon={<KeyboardArrowLeft />}
                  variant='text'
                >
                  BACK
                </Button>
              </Grid>

              <Grid item sx={{ alignSelf: 'stretch' }}>
                <Divider orientation='vertical' />
              </Grid>

              <Grid item>
                <Button
                  endIcon={<Edit />}
                  onClick={() => {
                    setAssetAddEditDialogOpen(true)
                  }}
                  variant='text'
                >
                  EDIT
                </Button>
              </Grid>
            </Grid>
          </AssetHeroCard>
        </Grid>
      </Grid>
    </>
  )
}
