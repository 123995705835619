import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { SuggestionDto, suggestionValidationSchema } from 'dtos/suggestion'
import { Formik } from 'formik'

interface FeedbackDialogProps {
  isSendingSuggestion: boolean
  onClose: () => void
  onSave: (values: SuggestionDto) => void
  open: boolean
}

export default function FeedbackDialog({ isSendingSuggestion, onClose, onSave, open }: FeedbackDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={new SuggestionDto()}
        validationSchema={suggestionValidationSchema} // Use the suggestionValidationSchema
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} alignItems='center'>
                <DialogTitle>Send Suggestion</DialogTitle>

                <Grid item xs={12}>
                  <TextField
                    disabled={isSendingSuggestion}
                    error={touched.description && Boolean(errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label='Description'
                    multiline
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    rows={4}
                    value={values.description}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button disabled={isSendingSuggestion} variant='text' color='inherit' onClick={onClose}>
                CANCEL
              </Button>

              <Button disabled={isSendingSuggestion} variant='contained' type='submit'>
                {isSendingSuggestion ? 'LOADING...' : 'SEND'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
