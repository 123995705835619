import { Add, Edit, Visibility } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { SecretDto, SecretGroupDto } from 'dtos'
import React, { useEffect, useState } from 'react'
import { formatDate, formatDateTime, useDebounce } from 'utils'
import SecretAddEditDialog from './SecretAddEditDialog'
import { SecretsParameters } from 'parameters'
import * as controllers from 'controllers'
import { useLoadingState } from 'hooks'
import SecretViewDialog from './SecretViewDialog'
import { useAuth } from 'context'

interface SecretsTableProps {
  secretGroup: SecretGroupDto
}

export default function SecretsTable({ secretGroup }: SecretsTableProps) {
  const { permissions } = useAuth()
  const [secret, setSecret] = useState<SecretDto>(new SecretDto())
  const [secrets, setSecrets] = useState<SecretDto[]>([])
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingSecrets: false
  })

  const [isSecretAddEditDialogOpen, setIsSecretAddEditDialogOpen] = useState<boolean>(false)
  const [isSecretViewDialogOpen, setIsSecretViewDialogOpen] = useState<boolean>(false)
  const [parameters, setParameters] = useState<SecretsParameters>({
    page: 0,
    pageSize: 10
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const {
    permissions: { SECRETS_ADD_EDIT, SECRETS_READ_ONLY }
  } = useAuth()

  useEffect(() => {
    if (secretGroup && secretGroup.secretGroupGuid) {
      getSecrets(secretGroup.secretGroupGuid, parameters)
    }
  }, [secretGroup, parameters])

  const getSecrets = useDebounce((secretGroupGuid: string, parameters: SecretsParameters) => {
    setLoadingState('isGettingSecrets', true)
    controllers
      .getSecretsBySecretGroupGuidAndParameters(secretGroupGuid, parameters)
      .then(response => {
        setSecrets(response.value ?? [])
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setLoadingState('isGettingSecrets', false)
      })
  }, 300)

  return (
    <>
      <SecretAddEditDialog
        onClose={() => {
          setIsSecretAddEditDialogOpen(false)
        }}
        onSaved={() => {
          setIsSecretAddEditDialogOpen(false)
          getSecrets(secretGroup!.secretGroupGuid!, parameters)
        }}
        open={isSecretAddEditDialogOpen}
        secret={secret}
        secretGroup={secretGroup}
      />

      <SecretViewDialog
        onClose={() => {
          setIsSecretViewDialogOpen(false)
        }}
        open={isSecretViewDialogOpen}
        secret={secret}
        secretGroup={secretGroup}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item>
                <Typography fontWeight='bold' variant='h6'>
                  Secrets
                </Typography>
              </Grid>

              {permissions.SECRETS_ADD_EDIT && (
                <Grid item>
                  <Button
                    endIcon={<Add />}
                    onClick={() => {
                      setSecret(new SecretDto())
                      setIsSecretAddEditDialogOpen(true)
                    }}
                  >
                    ADD NEW
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Key</TableCell>

                      <TableCell>Created</TableCell>

                      <TableCell>Updated</TableCell>

                      <TableCell>Last Viewed By</TableCell>

                      <TableCell padding='checkbox' align='right'></TableCell>

                      <TableCell padding='checkbox' align='right'></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loadingState.isGettingSecrets && (
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell></TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    )}

                    {!loadingState.isGettingSecrets &&
                      secrets.map(secret => (
                        <TableRow key={secret.secretGuid}>
                          <TableCell>{secret.key}</TableCell>

                          <TableCell>{formatDateTime(secret.createdDateTime!)}</TableCell>

                          <TableCell>{formatDateTime(secret.updatedDateTime!)}</TableCell>

                          <TableCell>
                            {formatDateTime(secret.lastViewedDateTime!)} {'by'} {secret.lastViewedBy?.firstName}{' '}
                            {secret.lastViewedBy?.lastName}
                          </TableCell>

                          <TableCell padding='checkbox' align='right'>
                            <Button
                              endIcon={<Visibility />}
                              onClick={() => {
                                setSecret(secret)
                                setIsSecretViewDialogOpen(true)
                              }}
                            >
                              VIEW
                            </Button>
                          </TableCell>

                          <TableCell padding='checkbox' align='right'>
                            {SECRETS_ADD_EDIT && (
                              <Button
                                endIcon={<Edit />}
                                onClick={() => {
                                  setSecret(secret)
                                  setIsSecretAddEditDialogOpen(true)
                                }}
                              >
                                EDIT
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                <TablePagination
                  component='div'
                  count={totalCount}
                  onPageChange={(_, page: number) => {
                    setParameters({
                      ...parameters,
                      page
                    })
                  }}
                  onRowsPerPageChange={e => {
                    setParameters({
                      ...parameters,
                      pageSize: e.target.value as unknown as number
                    })
                  }}
                  page={parameters.page}
                  rowsPerPage={parameters.pageSize}
                  labelRowsPerPage='Rows per page:'
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                  ]}
                  SelectProps={{ fullWidth: false }}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
