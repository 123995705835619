import { apiClient } from '_app'
import { ApiResponse, OpportunityStatusDto } from 'dtos'
import { OpportunityStatusesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getOpportunityStatuses = (parameters: OpportunityStatusesParameters) =>
  apiClient
    .get<ApiResponse<OpportunityStatusDto[]>>(
      '/api/opportunity-statuses' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
