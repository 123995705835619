import * as yup from 'yup'

export class ResetPasswordDto {
  email?: string = ''
  password?: string = ''
  confirmPassword?: string = ''
}

export const resetPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Email Address is required.'),
  password: yup
    .string()
    .min(8, 'Password cannot be less than 8 characters.')
    .max(64, 'Password cannot be more than 64 characters.')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, and one number.'
    )
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password and Confirm Password must match.')
    .required('Confirm Password is required.')
})
