import { apiClient } from '_app'
import { ApiResponse, VendorContractInformationDto } from 'dtos'
import { VendorContractInformationParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getVendorContractInformation = (parameters: VendorContractInformationParameters, companyId: string) =>
  apiClient
    .get<ApiResponse<VendorContractInformationDto[]>>(
      '/api/companies/' + encodeURIComponent(companyId) + '/vendor-contract-information' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
    .catch(reason => {
      throw new Error(reason)
    })

export const createVendorContractInformation = (values: VendorContractInformationDto) =>
  apiClient
    .post<ApiResponse<VendorContractInformationDto>>('/api/vendor-contract-information', values)
    .then(({ data }) => data)
    .catch(reason => {
      throw new Error(reason)
    })

export const updateVendorContractInformation = (values: VendorContractInformationDto) =>
  apiClient
    .put<ApiResponse<VendorContractInformationDto>>('/api/vendor-contract-information', values)
    .then(({ data }) => data)
    .catch(reason => {
      throw new Error(reason)
    })
