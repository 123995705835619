import * as yup from 'yup'
import { PersonDto } from './person'
import { WorkOrderDto } from './workOrder'
import { WorkOrderReleaseTaskDto } from './workOrderReleaseTask'
import { WorkOrderReleaseTaskTimeEntryDto } from './workOrderReleaseTaskTimeEntry'
import { WorkOrderReleaseTypeDto } from './workOrderReleaseType'

export class WorkOrderReleaseDto {
  id?: string = undefined
  releaseTitle?: string = undefined
  releaseNumber?: string = undefined
  isReleaseNumberNumeric?: boolean = undefined

  signOffDueDate?: string = undefined
  useCaseVerballyApprovedByClientDate?: string = undefined
  designSpecVerballyApprovedByClientDate?: string = undefined
  designSpecSignOffSentToClientDate?: string = undefined
  designSpecSignedOffByClientDate?: string = undefined
  developmentCompleteDate?: string = undefined
  stagingTestedBySpudDate?: string = undefined
  releaseSignOffSentToClientDate?: string = undefined
  releaseSignedOffByClientDate?: string = undefined
  notes?: string = undefined
  isReleaseComplete?: boolean = false

  // This seems redundant now.
  isOpen: boolean = true
  isClosed: boolean = false

  // TODO: These are being removed.
  designHours?: number = undefined
  designSignOffDate?: string = undefined
  developmentHours?: number = undefined
  developmentSignOffDate?: string = undefined
  prodPushRequired: boolean = false
  designer?: PersonDto = undefined
  developer?: PersonDto = undefined
  workOrder?: WorkOrderDto = undefined

  workOrderReleaseType?: WorkOrderReleaseTypeDto = undefined
  workOrderReleaseTasks?: WorkOrderReleaseTaskDto[] = []
  workOrderReleaseTaskTimeEntry?: WorkOrderReleaseTaskTimeEntryDto = undefined
}

export const workOrderReleaseValidationSchema = yup.object({
  id: yup.string().nullable(),
  releaseTitle: yup
    .string()
    .max(200, 'Release Title cannot be more than 200 characters.')
    .required('Release Title is required.'),
  signOffDueDate: yup.string().nullable(),
  useCaseVerballyApprovedByClientDate: yup.string().nullable(),
  designSpecVerballyApprovedByClientDate: yup.string().nullable(),
  designSpecSignOffSentToClientDate: yup.string().nullable(),
  designSpecSignedOffByClientDate: yup.string().nullable(),
  developmentCompleteDate: yup.string().nullable(),
  stagingTestedBySpudDate: yup.string().nullable(),
  releaseSignOffSentToClientDate: yup.string().nullable(),
  releaseSignedOffByClientDate: yup.string().nullable(),
  notes: yup.string().max(200, 'Release Notes cannot be more than 200 characters.').nullable()
})
