import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

interface ListHeaderProps {
  children: string
}

export default function ListHeader({ children }: ListHeaderProps) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant='h5' fontWeight={600}>
            {children}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
