import { Autocomplete, Card, CardContent, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { PDFObjectBase64 } from 'components'
import * as controllers from 'controllers'
import { PersonDto, ReportDto, WorkOrderDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utils'
import BaseReportParameters from './BaseReportParameters'
import { start } from 'repl'

const getYesterdayDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().split('T')[0]
}

const getLast5PayPeriods = () => {
  const payPeriods = []
  const date = new Date()
  date.setDate(date.getDate() - 1)
  for (let i = 0; i < 5; i++) {
    const endDate = new Date(date)
    const startDate = new Date(date)
    startDate.setDate(startDate.getDate() - 13)
    payPeriods.push(`${startDate.toISOString().split('T')[0]} - ${endDate.toISOString().split('T')[0]}`)
    date.setDate(date.getDate() - 14)
  }
  return payPeriods
}

console.log(getLast5PayPeriods())

export default function Reports() {
  const [workOrders, setWorkOrders] = useState<WorkOrderDto[]>([])
  const [isGettingWorkOrders, setIsGettingWorkOrders] = useState<boolean>(false)
  const [workOrderId, setWorkOrderId] = useState<string>('')
  const [workDate, setWorkDate] = useState<string>(getYesterdayDate())
  const [payPeriod, setPayPeriod] = useState<string>('')
  const [base64, setBase64] = useState<string>('')
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false)
  const [reports, setReports] = useState<ReportDto[]>([])
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])

  const [salesperson, setSalesperson] = useState<PersonDto | null>(null)

  const [isGettingReports, setIsGettingReports] = useState<boolean>(false)

  const [report, setReport] = useState<ReportDto | undefined>(undefined)

  useEffect(() => {
    setIsGettingReports(true)
    controllers
      .getReports()
      .then(response => {
        setReports(response.value)
      })
      .finally(() => {
        setIsGettingReports(false)
      })
  }, [])

  useEffect(() => {
    if (report?.specialIdentifier === 'REPORTS_SALES_ACTIVITY_TO_GET_DONE') {
      controllers.getPeople({ page: 0, pageSize: 25, permission: 'SALESPERSON', statuses: ['isActive'] }).then(response => {
        setSalespeople(response.value)
      })
    }
  }, [report])

  const getWorkOrders = useDebounce((search: string) => {
    setIsGettingWorkOrders(true)
    controllers
      .getWorkOrders({ page: 0, pageSize: 25, search })
      .then(response => {
        setWorkOrders(response.value)
      })
      .finally(() => {
        setIsGettingWorkOrders(false)
      })
  }, 300)

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Reports
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm='auto'>
                  <Autocomplete
                    disabled={isGettingReports || isGeneratingReport}
                    onChange={(_, value: ReportDto | null) => {
                      if (value) {
                        setReport(value)
                      } else {
                        setReport(undefined)
                      }
                    }}
                    options={reports}
                    fullWidth
                    getOptionLabel={report => report.name!}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Report'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isGettingReports && (
                                <InputAdornment position='end'>
                                  <CircularProgress size={16} />
                                </InputAdornment>
                              )}

                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    sx={{ minWidth: '512px' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {report?.specialIdentifier === 'REPORTS_DEVELOPMENT_STAND_UP' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getDevelopmentStandUpReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_OPEN_SALE_OPPORTUNITIES' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getOpenSaleOpportunitiesReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_PAYROLL' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getPayrollReport({ startDate: payPeriod.split(' - ')[0], endDate: payPeriod.split(' - ')[1] })
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            >
              <Autocomplete
                disabled={isGeneratingReport}
                onChange={(_, value: string | null) => {
                  if (value) {
                    setPayPeriod(value)
                  } else {
                    setPayPeriod('')
                  }
                }}
                options={getLast5PayPeriods()}
                fullWidth
                noOptionsText='Type to search . . . '
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Pay Period'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
                sx={{ minWidth: '512px' }}
              />
            </BaseReportParameters>
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_SALES_ACTIVITY_TO_GET_DONE' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              disabled={!salesperson?.id}
              onGenerate={() => {
                if (salesperson) {
                  setIsGeneratingReport(true)
                  controllers
                    .getSalesActivityToGetDoneReport(salesperson.id!)
                    .then(response => setBase64(response))
                    .finally(() => {
                      setIsGeneratingReport(false)
                    })
                }
              }}
            >
              <Autocomplete
                disabled={isGeneratingReport}
                onChange={(_, value: PersonDto | null) => {
                  setSalesperson(value)
                }}
                options={salespeople}
                fullWidth
                getOptionLabel={salesperson => salesperson.firstName + ' ' + salesperson.lastName}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Salesperson'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {false && (
                            <InputAdornment position='end'>
                              <CircularProgress size={16} />
                            </InputAdornment>
                          )}

                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
                sx={{ minWidth: '512px' }}
              />
            </BaseReportParameters>
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_TIME_BILLED_BY_WEEK_BY_TEAM_MEMBER' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getTimeBilledByWeekByTeamMemberReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_TIME_LOGGED_CTE' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getTimeLoggedCteReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_USER_ACCESS' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getUserAccessReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_VACATION_TIME_LOGGED_DETAIL' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getVacationTimeLoggedDetailReport()
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            />
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_WORK_DONE' && (
          <Grid item xs={12}>
            <BaseReportParameters
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getWorkDoneReport(workDate)
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            >
              <TextField
                disabled={isGeneratingReport}
                fullWidth
                label='Work Date'
                type='date'
                value={workDate}
                onChange={e => setWorkDate(e.target.value)}
              />
            </BaseReportParameters>
          </Grid>
        )}

        {report?.specialIdentifier === 'REPORTS_PROJECT_STATUS' && (
          <Grid item xs={12}>
            <BaseReportParameters
              disabled={!workOrderId}
              isGeneratingReport={isGeneratingReport}
              onGenerate={() => {
                setIsGeneratingReport(true)
                controllers
                  .getWorkOrderStatusReport(workOrderId)
                  .then(response => setBase64(response))
                  .finally(() => {
                    setIsGeneratingReport(false)
                  })
              }}
            >
              <Autocomplete
                disabled={isGeneratingReport}
                onChange={(_, value: WorkOrderDto | null) => {
                  if (value) {
                    setWorkOrderId(value.id!)
                  } else {
                    setWorkOrderId('')
                  }
                }}
                onInputChange={(_, value: string) => {
                  getWorkOrders(value)
                }}
                options={workOrders}
                fullWidth
                noOptionsText='Type to search . . . '
                getOptionLabel={workOrder =>
                  `${workOrder.company?.name} ${workOrder.project?.name} #${workOrder.workOrderNumber}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Work Order'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isGettingWorkOrders && (
                            <InputAdornment position='end'>
                              <CircularProgress size={16} />
                            </InputAdornment>
                          )}

                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
                sx={{ minWidth: '512px' }}
              />
            </BaseReportParameters>
          </Grid>
        )}

        {report &&
          report.specialIdentifier &&
          [
            'REPORTS_ALL_OPEN_TASKS',
            'REPORTS_BA_STAND_UP',
            'REPORTS_BLUE_WATER_VTS2_PROJECT_STATUS',
            'REPORTS_BLUEWATER_VTS2_PROJECT_STATUS_SUMMARY_REPORT',
            'REPORTS_WORK_DONE',
            'REPORTS_NORTHGATE_GUMMY_PROJECT_STATUS_REPORT_FOR_CLIENT',
            'REPORTS_NORTHGATE_GUMMY_PROJECT_STATUS',
            'REPORTS_RECRUITING',
            'REPORTS_SALES',
            'REPORTS_TIME_BILLED_BY_WEEK_BY_TEAM_MEMBER',
            'REPORTS_VACATION_TIME',
            'REPORTS_VENDORS',
            'REPORTS_WORK_DONE_YESTERDAY'
          ].includes(report.specialIdentifier) && (
            <Grid item xs={12}>
              <BaseReportParameters
                isGeneratingReport={isGeneratingReport}
                onGenerate={() => {
                  setIsGeneratingReport(true)
                  controllers
                    .getGenericReport(report.name!)
                    .then(response => setBase64(response))
                    .finally(() => {
                      setIsGeneratingReport(false)
                    })
                }}
              />
            </Grid>
          )}

        {base64 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <PDFObjectBase64 base64={base64} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}
