import { apiClient } from '_app'
import { ApiResponse, OpportunityProbabilityDto } from 'dtos'
import { OpportunityProbabilitiesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getOpportunityProbabilities = (
  parameters: OpportunityProbabilitiesParameters
) =>
  apiClient
    .get<ApiResponse<OpportunityProbabilityDto[]>>(
      '/api/opportunity-probabilities' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
