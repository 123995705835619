import { apiClient } from '_app'
import { AddressDto, ApiResponse, CompanyDto } from 'dtos'
import { CompaniesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getCompanies = (parameters: CompaniesParameters) =>
  apiClient.get<ApiResponse<CompanyDto[]>>('/api/companies' + URIQuery({ ...parameters })).then(({ data }) => data)

export const getCompanyById = (id: string) =>
  apiClient.get<ApiResponse<CompanyDto>>('/api/companies/' + encodeURIComponent(id)).then(res => res.data)

export const getCompanyAddresses = (id: string) =>
  apiClient.get<ApiResponse<AddressDto[]>>('/api/companies/' + encodeURIComponent(id) + '/addresses').then(res => res.data)

export const createCompany = (values: CompanyDto) =>
  apiClient.post<ApiResponse<CompanyDto>>('/api/companies', values).then(({ data }) => data)

export const createCompanyAddress = (values: AddressDto, companyId: string) =>
  apiClient
    .post<ApiResponse<AddressDto>>('/api/companies/' + encodeURIComponent(companyId) + '/addresses', values)
    .then(res => res.data)

export const updateCompany = (values: CompanyDto) =>
  apiClient.put<ApiResponse<CompanyDto>>('/api/companies', values).then(({ data }) => data)
