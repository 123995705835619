import { apiClient } from '_app'
import { ApiResponse, TimePunchDto } from 'dtos'
import { TimePunchesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getTimePunches = (parameters?: TimePunchesParameters) =>
  apiClient
    .get<ApiResponse<TimePunchDto[]>>('/api/time-punches' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getMissingTimePunches = () =>
  apiClient
    .get<ApiResponse<TimePunchDto[]>>('/api/time-punches/missing')
    .then(res => res.data)

export const createTimePunch = (values?: TimePunchDto) =>
  apiClient
    .post<ApiResponse<TimePunchDto>>('/api/time-punches', values)
    .then(({ data }) => data)

export const updateTimePunch = (values: TimePunchDto) =>
  apiClient
    .put<ApiResponse<TimePunchDto>>('/api/time-punches', values)
    .then(({ data }) => data)
