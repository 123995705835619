import { apiClient } from '_app'
import { ApiResponse, ProjectDto } from 'dtos'
import { ProjectsParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getProjects = (parameters: ProjectsParameters) =>
  apiClient
    .get<ApiResponse<ProjectDto[]>>('/api/projects' + URIQuery({ ...parameters }))
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getProjectById = (id: string) =>
  apiClient
    .get<ApiResponse<ProjectDto>>('/api/projects/' + id)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })
