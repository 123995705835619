import * as yup from 'yup'
import { AddressDto, addressValidationSchema } from './address'
import { CampaignDto } from './campaign'

export class CompanyDto {
  id?: string = undefined
  name: string = ''
  howTheyFoundUs?: string = undefined
  industry?: string = undefined
  vendor: boolean = false
  companyCode?: string = undefined
  phone?: string = undefined
  website?: string = undefined
  logoFileName?: string = undefined
  notes?: string = undefined
  isVendor?: boolean = false
  isActive: boolean = true
  addresses: AddressDto[] = []
  campaigns: CampaignDto[] = []
}

export const companyValidationSchema = yup.object<CompanyDto>({
  id: yup.string().nullable(),
  name: yup.string().max(200, 'Company Name cannot be more than 200 characters.').required('Company Name is required.'),
  companyCode: yup.string().max(200, 'Company Code cannot be more than 200 characters.').nullable(),
  website: yup.string().max(2_083, 'Website cannot be more than 2,083 characters.').nullable(),
  campaigns: yup.array().of(yup.object()).nullable(),
  notes: yup.string().max(2_000, 'Notes cannot be more than 2,000 characters.').nullable()
})
