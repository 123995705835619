import styled from '@emotion/styled'
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function Last7DaysBilled() {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            // border: '4px solid white',
            // borderRadius: '1px',
            // backgroundColor: '#8C6751',
            // color: '#fff',
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          Last 7 Days Billed
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>

                <TableCell align='left'>Last Name</TableCell>

                <TableCell align='left'>Hours Billed</TableCell>

                <TableCell align='left'>KPI</TableCell>

                <TableCell align='right'>Difference</TableCell>
              </TableRow>
            </TableHead>

            <TableBody></TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
