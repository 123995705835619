import { AppRegistration } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import { PersonDto } from 'dtos'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

interface PersonListCardProps {
  person: PersonDto
}

export default function PersonListCard({ person }: PersonListCardProps) {
  const navigate = useNavigate()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {person.firstName} {person.lastName}
                </Typography>
              </Grid>

              {person.isActive && (
                <Grid item>
                  <Chip variant='green'>Active</Chip>
                </Grid>
              )}

              {!person.isActive && (
                <Grid item>
                  <Chip variant='gray'>Inactive</Chip>
                </Grid>
              )}

              {person.isUser && (
                <Grid item>
                  <Chip variant='blue'>User</Chip>
                </Grid>
              )}

              {person.company?.isVendor && (
                <Grid item>
                  <Chip variant='purple'>Vendor</Chip>
                </Grid>
              )}

              {person.isRecruitingCandidate && (
                <Grid item>
                  <Chip variant='yellow'>Recruiting Candidate</Chip>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm='auto' alignItems='flex-end'>
              <Button
                endIcon={<AppRegistration />}
                onClick={() => {
                  navigate('/people/' + person.id)
                }}
                variant='text'
              >
                DETAILS
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Company</Typography>
            <Typography color='primary' variant='body1'>
              <Link to={'/companies/' + person.company?.id} component={ReactRouterLink}>
                {person.company?.name}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Title</Typography>
            <Typography color='primary' variant='body1'>
              {person.title}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Email</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={'mailto:' + person.email}>{person.email}</Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Text Email</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={'mailto:' + person.textEmail}>{person.textEmail}</Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Mobile Phone</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={'tel:' + person.mobilePhone}>{person.mobilePhone}</Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Work Phone</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={'tel:' + person.workPhone}>{person.workPhone}</Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Notes</Typography>
            <Typography color='primary' variant='body1'>
              {person.notes}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
