import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  maxString2000Message,
  maxString2000Value,
  maxString200Message,
  maxString200Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '../constants'
import { PersonDto } from './person'
import { WorkOrderReleaseDto } from './workOrderRelease'
import { WorkOrderReleaseTaskTypeDto } from './workOrderReleaseTaskType'
import { formatDateValue } from 'utils'
import { WorkOrderReleaseTaskDto } from './workOrderReleaseTask'
import { CompanyDto } from './company'
import { WorkOrderDto } from './workOrder'

export class WorkOrderTaskMoveableTimeEntryDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  workOrder?: WorkOrderDto = undefined
  workOrderRelease?: WorkOrderReleaseDto = undefined
  workOrderReleaseTaskType?: WorkOrderReleaseTaskTypeDto = undefined
  workOrderReleaseTask?: WorkOrderReleaseTaskDto = undefined
}

export const workOrderTaskMoveableTimeEntryValidationSchema = yup.object({
  id: yup.string().nullable(),
  workOrderReleaseTask: yup.object().required(requiredFieldMessage),
  workDate: yup.string().required(requiredFieldMessage)
})
