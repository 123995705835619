import axios from 'axios'
import { enqueueSnackbar } from 'notistack'

class ApiError {
  statusCode?: number = undefined
  message?: string = undefined

  constructor(error: any) {
    if (error && error.response && error.response.data) {
      if (error.response.data.statusCode) {
        this.statusCode = error.response.data.statusCode
      }

      if (error.response.data.message) {
        this.message = error.response.data.message
      }
    }
  }
}

const apiClient = axios.create({
  // Pass cookies
  // withCredentials: true
})

apiClient.interceptors.response.use(
  response => response,
  error => {
    const apiError = new ApiError(error)

    if (apiError.message) {
      enqueueSnackbar(apiError.message, { variant: 'error' })
    }

    if (apiError.statusCode === 401 || apiError.statusCode === 403) {
      apiClient.post('/api/account/sign-out').then(() => {
        window.location.assign('/account/sign-in')
      })
    }

    throw new ApiError(error)
  }
)

export { apiClient }
