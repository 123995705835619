import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  maxString2000Message,
  maxString2000Value,
  maxString200Message,
  maxString200Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '../constants'
import { PersonDto } from './person'
import { WorkOrderReleaseTaskDto } from './workOrderReleaseTask'
import { formatDateValue } from 'utils'

export class WorkOrderReleaseTaskTimeEntryDto {
  id?: string = undefined
  workOrderReleaseTask?: WorkOrderReleaseTaskDto = undefined
  workDoneBy?: PersonDto = undefined
  hoursWorked?: number = undefined
  descriptionOfWorkDone?: string = undefined
  // DateOnly needs to be a string on the front-end
  workDate?: string = formatDateValue(new Date())
  approved?: boolean = undefined
  hoursStillNeeded?: number = undefined
  hoursBilled?: number = undefined
  hoursNotBilled?: number = undefined
  travelMiles?: number = undefined
  specialIdentifier?: string = undefined
  isActive: boolean = false
  taskCanBeClosed?: boolean = false
}

export const workOrderReleaseTaskTimeEntryValidationSchema = yup.object({
  id: yup.string().nullable(),
  workOrderReleaseTask: yup.object().required(requiredFieldMessage),
  workDoneBy: yup.object().required(requiredFieldMessage),
  hoursWorked: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  descriptionOfWorkDone: yup.string().required(requiredFieldMessage).max(maxString2000Value, maxString2000Message),
  workDate: yup.string().required(requiredFieldMessage),
  hoursStillNeeded: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  travelMiles: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  specialIdentifier: yup.string().max(maxString200Value, maxString200Message).nullable(),
  isActive: yup.boolean().required(requiredFieldMessage)
})
