import { Add, SearchOutlined } from '@mui/icons-material'
import { Button, FormControl, Grid, InputAdornment, MenuItem, TextField } from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { List, ListBody, ListFilters, ListHeader, ListRow, ListRowAction } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import { PersonDto, StatusCount, WorkOrderDto } from 'dtos'
import { useQuery } from 'hooks'
import { enqueueSnackbar, useSnackbar } from 'notistack'
import { WorkOrdersParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'utils'
import WorkOrderAddEditDialog from './id/WorkOrderAddEditDialog'
import * as controllers from 'controllers'
import WorkOrderListCard from './WorkOrderListCard'

const sessionStorageKey = 'workOrders:parameters'

export default function WorkOrders() {
  // #region hooks
  const { setIsLayoutLoading, setTitle } = useLayout()
  const navigate = useNavigate()
  const query = useQuery()

  const queryParams = new URLSearchParams(window.location.search)
  const initialSearchValue = queryParams.get('search') || ''

  const {
    user,
    permissions: { PROJECT_MANAGER, WORK_ORDER_DETAILS_ADD_EDIT }
  } = useAuth()

  const [searchValue, setSearchValue] = useState<string>(initialSearchValue)

  const defaultParameters: WorkOrdersParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    projectManagerId: '',
    statuses: ['includeOpen'],
    workOrderTypeOptions: ['includeAssessment', 'includeInternalWork', 'includeProject', 'includeSales']
  }

  const getWorkOrders = useDebounce((parameters: WorkOrdersParameters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    setIsLayoutLoading(true)
    api
      .getWorkOrders(parameters)
      .then(res => {
        setWorkOrders(res.value)
        setCount(res.totalCount ?? 0)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'includeOpen', count: 0 },
    { status: 'includeClosed', count: 0 }
  ])

  const [workOrderTypeOptionCounts, setWorkOrderTypeOptionCounts] = useState<StatusCount[]>([
    {
      status: 'includeAssessment',
      count: 0
    },
    {
      status: 'includeCallCenter',
      count: 0
    },
    {
      status: 'includeInternalWork',
      count: 0
    },
    {
      status: 'includeNetworkSupport',
      count: 0
    },
    {
      status: 'includeProject',
      count: 0
    },
    {
      status: 'includeSales',
      count: 0
    }
  ])

  const findStatusCount = (status: string) => {
    return statusCounts.filter(statusCount => statusCount.status === status)[0].count
  }

  const findWorkOrderTypeOptionCount = (status: string) => {
    return workOrderTypeOptionCounts.filter(workOrderTypeOptionCount => workOrderTypeOptionCount.status === status)[0].count
  }
  // #endregion

  // #region useState
  const [addActivityDialogOpen, setAddActivityDialogOpen] = useState<boolean>(false)
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [workOrders, setWorkOrders] = useState<WorkOrderDto[]>([])
  const [workOrderAddEditDialogOpen, setWorkOrderAddEditDialogOpen] = useState<boolean>(false)
  const [isSavingWorkOrder, setIsSavingWorkOrder] = useState<boolean>(false)
  const [workOrder, setWorkOrder] = useState<WorkOrderDto>(new WorkOrderDto())
  // const [workOrdersSummary, setWorkOrdersSummary] = useState<WorkOrdersSummaryDto>(
  //   new WorkOrdersSummaryDto()
  // )
  const [count, setCount] = useState<number>(0)
  const [isGettingProjectManagers, setIsGettingProjectManagers] = useState<boolean>(false)
  const [isGettingWorkOrders, setIsGettingWorkOrders] = useState<boolean>(true)
  const [parameters, setParameters] = useState<WorkOrdersParameters>(
    sessionStorage.getItem(sessionStorageKey) ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!) : defaultParameters
  )
  // #endregion

  // #region useEffect
  useEffect(() => {
    setTitle('Work Orders')

    setIsGettingProjectManagers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'PROJECT_MANAGER',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setProjectManagers(value)
      })
      .finally(() => {
        setIsGettingProjectManagers(false)
      })
  }, [])

  useEffect(() => {
    getWorkOrders(parameters)
  }, [getWorkOrders, parameters])
  // #endregion

  useEffect(() => {
    // The Project Manager filter should only default to the current user IF there
    // is no search query parameter (via Quick Search.)
    // Another conditional that will need to be checked is if the current user has the Project Manager role.
    if (PROJECT_MANAGER && !query.get('search')) {
      setParameters({
        ...parameters,
        projectManagerId: user!.id
      })
    }
  }, [user])

  useEffect(() => {
    if (query.get('search')) {
      setParameters({
        ...parameters,
        search: query.get('search') ?? undefined
      })
    }
  }, [query.get('search')])

  return (
    <>
      <WorkOrderAddEditDialog
        initialValues={workOrder}
        loading={isSavingWorkOrder}
        onClose={() => {
          setWorkOrderAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingWorkOrder(true)
          controllers
            .createWorkOrder(values)
            .then(() => {
              enqueueSnackbar('Work Order Saved Successfully!', { variant: 'success' })
              getWorkOrders(parameters)
              setWorkOrderAddEditDialogOpen(false)
            })
            .finally(() => {
              setIsSavingWorkOrder(false)
            })
        }}
        open={workOrderAddEditDialogOpen}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
        sortedByDescription=''
      >
        <ListHeader>Work Orders</ListHeader>

        <ListFilters>
          <TextField
            data-search
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined fontSize='small' />
                </InputAdornment>
              )
            }}
            label='Search'
            name='search'
            onChange={e => setParameters({ ...parameters, projectManagerId: '', search: e.target.value ?? undefined })}
            size='small'
            type='search'
            value={parameters.search}
          />

          <FormControl
            sx={{
              m: 1,
              minWidth: 240,
              mr: 4,
              minHeight: '26px',
              justifyContent: 'center'
            }}
            size='small'
          >
            {/* <TextField
              label='Project Manager'
              name='name'
              onChange={e => {
                setParameters({
                  ...parameters,
                  projectManagerId: e.target.value,
                  teamMemeberId: '',
                  companyId: ''
                })
              }}
              select
              size='small'
              value={parameters.projectManagerId || ''}
            >
              <MenuItem value={undefined} key={0}>
                All project managers
              </MenuItem>
              {projectManagers
                ?.sort((a, b) =>
                  (a.firstName + ' ' + a.lastName).localeCompare(
                    b.firstName + ' ' + b.lastName
                  )
                )
                .map(projectManager => (
                  <MenuItem
                    value={JSON.stringify(projectManager)}
                    key={projectManager.id}
                  >
                    {projectManager.firstName + ' ' + projectManager.lastName}
                  </MenuItem>
                ))} */}
            <TextField
              label='Project Manager'
              onChange={e => {
                setParameters({ ...parameters, projectManagerId: e.target.value })
              }}
              select
              SelectProps={{
                displayEmpty: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              sx={{ minWidth: 240 }}
              value={parameters.projectManagerId || ''}
            >
              <MenuItem value={''}>All Team Members</MenuItem>
              {projectManagers
                .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                .map(projectManager => (
                  <MenuItem key={projectManager.id} value={projectManager.id}>
                    {projectManager.firstName} {projectManager.lastName}
                  </MenuItem>
                ))}

              {/*{projectManagers.map(projectManager => (
                <MenuItem key={projectManager.id} value={projectManager.id}>
                  {projectManager.firstName} {projectManager.lastName}
              </MenuItem>*/}
            </TextField>
          </FormControl>

          {/* <FormControlLabel
            control={
              <Checkbox
                value={parameters.includeCallCenterAndNetworkSupport}
                checked={parameters.includeCallCenterAndNetworkSupport}
                onChange={e =>
                  setParameters({
                    ...parameters,
                    includeCallCenterAndNetworkSupport: e.target.checked
                  })
                }
              />
            }
            label='Include call center and network support'
          /> */}

          {/* <FormControlLabel
            control={
              <Checkbox
                value={parameters.includeCompleted}
                checked={parameters.includeCompleted}
                onChange={e =>
                  setParameters({ ...parameters, includeCompleted: e.target.checked })
                }
              />
            }
            label='Include completed'
          /> */}

          <ChipStatusSelect
            label='Statuses'
            onChange={value => {
              setParameters({
                ...parameters,
                statuses: value
              })
            }}
            options={[
              {
                label: 'Open',
                color: 'green',
                value: 'includeOpen'
                // count: findStatusCount('includeOpen')
              },
              {
                label: 'Closed',
                color: 'red',
                value: 'includeClosed'
                // count: findStatusCount('includeClosed')
              }
            ]}
            value={parameters.statuses ?? []}
          />

          <ChipStatusSelect
            label='Work Order Types'
            onChange={value => {
              setParameters({
                ...parameters,
                workOrderTypeOptions: value
              })
            }}
            options={[
              {
                label: 'Assessment',
                color: 'red',
                value: 'includeAssessment'
                // count: findStatusCount('includeAssessment')
              },
              {
                label: 'Call Center',
                color: 'purple',
                value: 'includeCallCenter'
                // count: findStatusCount('includeCallCenter')
              },
              {
                label: 'Internal Work',
                color: 'gray',
                value: 'includeInternalWork'
                // count: findStatusCount('includeInternalWork')
              },
              {
                label: 'Network Support',
                color: 'yellow',
                value: 'includeNetworkSupport'
                // count: findStatusCount('includeNetworkSupport')
              },
              {
                label: 'Project',
                color: 'green',
                value: 'includeProject'
                // count: findStatusCount('includeProject')
              },
              {
                label: 'Sales',
                color: 'blue',
                value: 'includeSales'
                // count: findStatusCount('includeSales')
              }
            ]}
            value={parameters.workOrderTypeOptions ?? []}
          />

          {WORK_ORDER_DETAILS_ADD_EDIT && (
            <Button
              color='primary'
              endIcon={<Add />}
              onClick={() => {
                setWorkOrder(new WorkOrderDto())
                setWorkOrderAddEditDialogOpen(true)
              }}
              size='medium'
              variant='text'
            >
              ADD NEW
            </Button>
          )}
        </ListFilters>

        <ListBody>
          {workOrders.map(workOrder => (
            <Grid item xs={12}>
              <WorkOrderListCard workOrder={workOrder} />
            </Grid>
          ))}

          <Grid item xs={12}>
            <ExplanationAccordion>
              Search searches Company Name, Project Name, and Work Order Number. || Lists all Work Orders based on filters in
              order by Company Name, Project Name, Work Order Number.||By default the Project Manager drop down is populated
              with the signed in user if they are marked as a project manager otherwise it defaults to All.||The user can select
              All under Project Manager to view all Work Orders.||When searching the Project Manager Drop Down is set to All.
              ||The Status field allows the user to filter Work Orders by Open/Closed status. ||The Add New button is disabled
              unless the user has Add/Edit permissions.||If the Release is complete to the point where it has been pushed to
              production or will be pushed to production as part of another Release (make a note of that) and toggle Release is
              Complete to True.
            </ExplanationAccordion>
          </Grid>
        </ListBody>
      </List>
    </>
  )
}
