import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { GuestLayout } from 'components/_template'
import * as controllers from 'controllers'
import { ResetPasswordDto, resetPasswordValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function ResetPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams, _setSearchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Reset Password | Spud ERP'
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={new ResetPasswordDto()}
      validateOnChange
      validateOnBlur
      validationSchema={resetPasswordValidationSchema}
      onSubmit={(values, formikHelpers) => {
        const { email, password } = values

        controllers
          .resetPassword(email!, password!, searchParams.get('t')!)
          .then(({ value }) => {
            enqueueSnackbar(value, { variant: 'success' })
            navigate('/sign-in', { replace: true })
          })
          .catch(() => {
            formikHelpers.setSubmitting(false)
          })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => {
        return (
          <GuestLayout
            handleSubmit={handleSubmit}
            title='Reset Password'
            subtitle='Your password must be between 8 and 64 characters long and must contain at least one uppercase letter, one lowercase letter, and one number.'
          >
            <TextField
              autoComplete='email'
              autoFocus
              disabled={isSubmitting}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label='Email Address'
              margin='normal'
              name='email'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ''}
            />

            <TextField
              disabled={isSubmitting}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { color: 'black' }
              }}
              label='Password'
              margin='normal'
              name='password'
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              value={values.password || ''}
            />

            <TextField
              disabled={isSubmitting}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={_ => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { color: 'black' }
              }}
              label='Confirm Password'
              margin='normal'
              name='confirmPassword'
              onBlur={handleBlur}
              onChange={handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword || ''}
            />

            <Button type='submit' fullWidth variant='contained' disabled={isSubmitting}>
              RESET PASSWORD
            </Button>
          </GuestLayout>
        )
      }}
    </Formik>
  )
}
