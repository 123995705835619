import * as yup from 'yup'

export class SuggestionDto {
  description?: string = undefined
}

export const suggestionValidationSchema = yup.object({
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .required('Description is required.')
})
