import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from './_template/accordion'
import { AddressDto, AddressTypeDto, addressValidationSchema } from 'dtos'
import { Formik } from 'formik'
import React from 'react'
import { LoadingButton } from '@mui/lab'

type Props = {
  address?: AddressDto
  addressTypes: AddressTypeDto[]
  loading?: boolean
  onClose: () => void
  onSave: (values: any) => void
  open: boolean
}

export default function AddressAddEditDialog({
  address = new AddressDto(),
  addressTypes,
  loading,
  onClose,
  onSave,
  open
}: React.PropsWithChildren<Props>) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize={true}
        initialValues={address}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={addressValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Address Details</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.addressType && errors.addressType)}
                      helperText={touched.addressType && errors.addressType}
                      label='Address'
                      name='addressType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('addressType', JSON.parse(e.target.value))
                      }}
                      select
                      value={
                        values?.addressType ? JSON.stringify({ id: values.addressType.id, name: values.addressType.name }) : ''
                      }
                    >
                      {addressTypes
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(addressType => (
                          <MenuItem value={JSON.stringify({ id: addressType.id, name: addressType.name })} key={addressType.id}>
                            {addressType.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      label='Address Notes'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      label='Phone'
                      name='phone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      label='Email'
                      name='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.address1 && errors.address1)}
                      helperText={touched.address1 && errors.address1}
                      label='Address 1'
                      name='address1'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address1}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.address2 && errors.address2)}
                      helperText={touched.address2 && errors.address2}
                      label='Address 2'
                      name='address2'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                      label='City'
                      name='city'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                      label='State'
                      name='state'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                      label='Postal Code'
                      name='postalCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postalCode}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                      label='Country'
                      name='country'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          disabled={loading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <ExplanationAccordion>
                    Only U.S. based addresses can be added at this time. || Only 1 address per Address Type allowed (Physical,
                    Billing, Shipping).
                  </ExplanationAccordion>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton type='submit' variant='contained' loading={loading || isSubmitting}>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
