import { Card, CardActions, CardContent, Chip, Grid, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { ListRowActionProps } from './ListRowAction'

interface Chip {
  backgroundColor: string
  color?: string
  label: string
  when: boolean
}

interface ListRowProps {
  children?: false | ReactElement<ListRowActionProps> // false is included in type to allow conditional children
  title: string
  subtitle1: string
  subtitle2: string
  subtitle3?: string
  subtitle4?: string
  variant?: 'outlined' | 'elevation'
  chips?: Chip[]
}

export default function ListRow({
  children,
  chips,
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  title,
  variant = 'elevation'
}: ListRowProps) {
  return (
    <Grid item xs={12}>
      <Card sx={{ minHeight: '152.5px', maxWidth: '100%' }} variant={variant}>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start' justifyContent='space-between'>
            <Grid item xs={12} sm={8} sx={{ minHeight: '108px', textOverflow: 'ellipsis' }}>
              <Typography variant='h6' fontWeight={600}>
                {title}
              </Typography>

              <Typography variant='subtitle2' fontWeight={500}>
                {subtitle1}
              </Typography>

              <Typography variant='subtitle2' fontWeight={500}>
                {subtitle2}
              </Typography>

              {subtitle3 && (
                <Typography variant='subtitle2' fontWeight={500}>
                  {subtitle3}
                </Typography>
              )}
            </Grid>

            {chips && chips.length > 0 && (
              <Grid item xs={12} sm='auto' container justifyContent='flex-end'>
                {chips
                  .filter(chip => chip.when)
                  .map((chip, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={chip.label}
                        sx={{
                          backgroundColor: chip.backgroundColor,
                          color: chip.color ?? '#fff',
                          fontWeight: 'bold'
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
        </CardContent>

        <CardActions>
          <Grid container alignItems='center' justifyContent='flex-end'>
            <Grid item>{children}</Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  )
}
