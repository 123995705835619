import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import * as controllers from 'controllers'
import {
  CompanyDto,
  OpportunityDto,
  PersonDto,
  ProjectDto,
  WorkOrderBillingTypeDto,
  WorkOrderDto,
  WorkOrderTypeDto,
  workOrderValidationSchema
} from 'dtos'
import { Formik, getIn } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface WorkOrderAddEditDialogProps {
  initialValues: WorkOrderDto
  loading: boolean
  onClose: () => void
  onSave: (values: WorkOrderDto) => void
  open: boolean
}

export default function WorkOrderAddEditDialog({ initialValues, loading, onClose, onSave, open }: WorkOrderAddEditDialogProps) {
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  // companyId is used to get projects and companyPeople for the selected company.
  const [companyId, setCompanyId] = useState<string>('')
  const [projects, setProjects] = useState<ProjectDto[]>([])
  const [companyPeople, setCompanyPeople] = useState<PersonDto[]>([])
  const [opportunities, setOpportunities] = useState<OpportunityDto[]>([])
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])
  const [workOrderBillingTypes, setWorkOrderBillingTypes] = useState<WorkOrderBillingTypeDto[]>([])
  const [workOrderTypes, setWorkOrderTypes] = useState<WorkOrderTypeDto[]>([])

  useEffect(() => {
    /**
     * TODO:
     * Priority: Very High
     * Description: If there was 2001 workOrderBillingTypes, it would not be visible to the end user. What is a better
     * way of handling this scenario? An autocomplete? Grab them all on application load and store them in single memoized state?
     */
    controllers.getCompanies({ page: 0, pageSize: 2000 }).then(response => {
      setCompanies(response.value)
    })

    controllers.getWorkOrderBillingTypes({ page: 0, pageSize: 2000 }).then(response => {
      setWorkOrderBillingTypes(response.value)
    })

    controllers.getWorkOrderTypes({ page: 0, pageSize: 2000 }).then(response => {
      setWorkOrderTypes(response.value)
    })

    controllers.getPeople({ page: 0, pageSize: 2000, permission: 'PROJECT_MANAGER' }).then(response => {
      setProjectManagers(response.value)
    })

    controllers.getPeople({ page: 0, pageSize: 2000, permission: 'SALESPERSON' }).then(response => {
      setSalespeople(response.value)
    })

    // controllers.getOpportunities({ page: 0, pageSize: 2000, statuses: ['sold'], hasWorkOrder: false }).then(response => {
    //   setOpportunities(response.value)
    // })
  }, [])

  useEffect(() => {
    setCompanyId(initialValues?.company?.id ?? '')
  }, [initialValues])

  useEffect(() => {
    if (companyId) {
      controllers.getPeople({ page: 0, pageSize: 2000, companyId, statuses: ['isActive'] }).then(response => {
        setCompanyPeople(response.value)
      })
      controllers.getProjects({ page: 0, pageSize: 2000, activeOnly: true, companyId: companyId }).then(response => {
        setProjects(response.value)
      })
    }
  }, [companyId])

  useEffect(() => {
    console.log(initialValues.project)
    // Push the project on the work order to the list of projects if it is not currently in the list because it was added to the work order then saved since the page was loaded
    if (initialValues.project !== undefined && !projects.some(project => project.id === initialValues.project?.id)) {
      projects.push({ ...initialValues.project, company: initialValues.company })
      projects.sort((a, b) => a.name!.localeCompare(b.name!))
    }
  }, [companyId, initialValues.project])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={workOrderValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Work Order</DialogTitle>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        if (value && value.id) {
                          setCompanyId(value.id)
                        }

                        setFieldValue('company', value)
                        setFieldValue('project', '')
                        setFieldValue('companyPerson', undefined)
                      }}
                      options={companies.map(company => ({
                        id: company.id,
                        name: company.name
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.company && errors.company)}
                          helperText={touched.company && errors.company}
                          label='Company'
                          name='company'
                        />
                      )}
                      value={
                        values.company
                          ? {
                              id: values.company.id,
                              name: values.company.name
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      disabled={loading || isSubmitting || values.company === undefined}
                      disableListWrap
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        console.log(value)
                        setFieldValue('project', { id: undefined, name: value })
                      }}
                      onInputChange={(_e, value) => {
                        setFieldValue('project', { id: undefined, name: value })
                      }}
                      options={projects
                        .filter(project => project?.company?.id === values.company?.id)
                        .map(project => project.name)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.project && errors.project)}
                          helperText={
                            touched.project &&
                            (typeof errors.project === 'string' ? errors.project : getIn(errors, 'project.name'))
                          }
                          label='Project'
                          name='project.name'
                        />
                      )}
                      value={values.project?.name || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('companyPerson', value)
                      }}
                      options={companyPeople.map(person => ({
                        id: person.id,
                        firstName: person.firstName,
                        lastName: person.lastName
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.companyPerson && errors.companyPerson)}
                          helperText={touched.companyPerson && errors.companyPerson}
                          label='Primary Contact'
                          name='companyPerson'
                        />
                      )}
                      value={
                        values.companyPerson
                          ? {
                              id: values.companyPerson.id,
                              firstName: values.companyPerson.firstName,
                              lastName: values.companyPerson.lastName
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('projectManager', value)
                      }}
                      options={projectManagers.map(projectManager => ({
                        id: projectManager.id,
                        firstName: projectManager.firstName,
                        lastName: projectManager.lastName
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.projectManager && errors.projectManager)}
                          helperText={touched.projectManager && errors.projectManager}
                          label='Project Manager'
                          name='projectManager'
                        />
                      )}
                      value={
                        values.projectManager
                          ? {
                              id: values.projectManager.id,
                              firstName: values.projectManager.firstName,
                              lastName: values.projectManager.lastName
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('workOrderBillingType', value)
                        setFieldValue('fixedPriceHoursAllowed', undefined)
                      }}
                      options={workOrderBillingTypes.map(workOrderBillingType => ({
                        id: workOrderBillingType.id,
                        name: workOrderBillingType.name,
                        specialIdentifier: workOrderBillingType.specialIdentifier
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.workOrderBillingType && errors.workOrderBillingType)}
                          helperText={touched.workOrderBillingType && errors.workOrderBillingType}
                          label='Billing Type'
                          name='workOrderBillingType'
                        />
                      )}
                      value={
                        values.workOrderBillingType
                          ? {
                              id: values.workOrderBillingType.id,
                              name: values.workOrderBillingType.name,
                              specialIdentifier: values.workOrderBillingType.specialIdentifier
                            }
                          : null
                      }
                    />
                  </Grid>

                  {values.workOrderBillingType?.specialIdentifier === 'FIXED_PRICE' && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={loading || isSubmitting}
                        error={Boolean(touched.fixedPriceHoursAllowed && errors.fixedPriceHoursAllowed)}
                        helperText={touched.fixedPriceHoursAllowed && errors.fixedPriceHoursAllowed}
                        label='Fixed Price Hours Allowed'
                        name='fixedPriceHoursAllowed'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fixedPriceHoursAllowed || ''}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.billRate && errors.billRate)}
                      helperText={touched.billRate && errors.billRate}
                      InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                      label='Bill Rate'
                      name='billRate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billRate || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('workOrderType', value)
                      }}
                      options={workOrderTypes.map(workOrderType => ({
                        id: workOrderType.id,
                        name: workOrderType.name,
                        specialIdentifier: workOrderType.specialIdentifier
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.workOrderType && errors.workOrderType)}
                          helperText={touched.workOrderType && errors.workOrderType}
                          label='Work Order Type'
                          name='workOrderType'
                        />
                      )}
                      value={
                        values.workOrderType
                          ? {
                              id: values.workOrderType.id,
                              name: values.workOrderType.name,
                              specialIdentifier: values.workOrderType.specialIdentifier
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.purchaseOrderNumber && errors.purchaseOrderNumber)}
                      helperText={touched.purchaseOrderNumber && errors.purchaseOrderNumber}
                      label='Purchase Order Number'
                      name='purchaseOrderNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.purchaseOrderNumber || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.opportunityName!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('opportunity', value)

                        if (value?.salesperson) {
                          setFieldValue('salesperson', value.salesperson)
                        }
                      }}
                      options={opportunities.map(opportunity => ({
                        id: opportunity.id,
                        opportunityName: opportunity.opportunityName,
                        salesperson: opportunity.salesperson
                          ? {
                              id: opportunity.salesperson.id,
                              firstName: opportunity.salesperson.firstName,
                              lastName: opportunity.salesperson.lastName
                            }
                          : {}
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.opportunity && errors.opportunity)}
                          helperText={touched.opportunity && errors.opportunity}
                          label='Opportunity'
                          name='opportunity'
                        />
                      )}
                      value={
                        values.opportunity
                          ? {
                              id: values.opportunity.id,
                              opportunityName: values.opportunity.opportunityName,
                              salesperson: values.opportunity.salesperson
                                ? {
                                    id: values.opportunity.salesperson.id,
                                    firstName: values.opportunity.salesperson.firstName,
                                    lastName: values.opportunity.salesperson.lastName
                                  }
                                : {}
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('salesperson', value)
                      }}
                      options={salespeople.map(salesperson => ({
                        id: salesperson.id,
                        firstName: salesperson.firstName,
                        lastName: salesperson.lastName
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.salesperson && errors.salesperson)}
                          helperText={touched.salesperson && errors.salesperson}
                          label='Salesperson'
                          name='salesperson'
                        />
                      )}
                      value={
                        values.salesperson
                          ? {
                              id: values.salesperson.id,
                              firstName: values.salesperson.firstName,
                              lastName: values.salesperson.lastName
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.devUrl && errors.devUrl)}
                      helperText={touched.devUrl && errors.devUrl}
                      label='Development'
                      name='devUrl'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.devUrl || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.qaUrl && errors.qaUrl)}
                      helperText={touched.qaUrl && errors.qaUrl}
                      label='Staging'
                      name='qaUrl'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.qaUrl || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.prodUrl && errors.prodUrl)}
                      helperText={touched.prodUrl && errors.prodUrl}
                      label='Production'
                      name='prodUrl'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.prodUrl || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isOpen}
                          disabled={loading || isSubmitting}
                          name='isOpen'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isOpen}
                        />
                      }
                      label='Open'
                    />
                  </Grid>

                  {!values.isOpen && (
                    <Grid item xs={12}>
                      <DatePicker
                        disabled={loading || isSubmitting}
                        label='Closed Date'
                        name='closedDate'
                        onChange={newValue => {
                          if (newValue) {
                            setFieldValue('closedDate', newValue.format('yyyy-MM-DD'))
                          } else {
                            setFieldValue('closedDate', undefined)
                          }
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(touched.closedDate && errors.closedDate),
                            helperText: touched.closedDate && errors.closedDate
                          }
                        }}
                        value={values.closedDate ? moment(values.closedDate) : null}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={loading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
