/**
 * @param inputDate Date used to find the pay period.
 * @returns An array of Dates where the first element is the start of the pay period, and the second element is the end of the pay period.
 */
export function getPayPeriod(inputDate: Date): Date[] {
  inputDate.setHours(0)
  inputDate.setMinutes(0)
  inputDate.setSeconds(0)

  // 1/2/2023 - 1/15/2023 was the first pay period of 2023. It is used as the bounds for pay period ranges.
  // Please note: This function *will not* get the pay periods for times before 1/2/2023!
  const firstPayPeriodStartDate = new Date(2023, 0, 2, 0, 0, 0)
  const firstPayPeriodEndDate = new Date(2023, 0, 15, 0, 0, 0)

  const payPeriodStartDate = firstPayPeriodStartDate
  const payPeriodEndDate = firstPayPeriodEndDate

  // If the input date is not greater-than-or-equal-to the payPeriodStartDate, subtract 14.
  const addend = inputDate >= payPeriodStartDate ? 14 : -14

  while (!(inputDate <= payPeriodEndDate && inputDate >= payPeriodStartDate)) {
    payPeriodStartDate.setDate(payPeriodStartDate.getDate() + addend)
    payPeriodEndDate.setDate(payPeriodEndDate.getDate() + addend)
  }

  return [payPeriodStartDate, payPeriodEndDate]
}
