import { Button, Grid, Menu, MenuItem, TextField } from '@mui/material'
import { WorkOrderReleaseTaskTimeEntryAddEditDialog } from 'components'
import LabelField from 'components/LabelField'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListBody, ListFilterGroup, ListFilters, ListHeader } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import { CompanyDto, PersonDto, TimePunchDto, WorkOrderReleaseTaskTimeEntryDto, WorkOrderTaskMoveableTimeEntryDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { TimePunchesParameters, WorkOrderReleaseTaskTimeEntriesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import {
  decimalToHhMm,
  formatDate,
  formatDateValue,
  getPayPeriod,
  getTimePunched,
  legacy_hhMmToDecimal,
  useDebounce
} from 'utils'
import TimeEntryListCard from './TimeEntryListCard'
import WorkOrderReleaseTaskMoveTimeEntryAddEditDialog from './id/WorkOrderReleaseTaskMoveTimeEntryAddEditDialog'

export default function TimeEntries() {
  const { setIsLayoutLoading, setTitle } = useLayout()

  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [moveTimeEntryDialogOpen, setMoveTimeEntryDialogOpen] = useState<boolean>(false)
  const [timeEntryToMove, setTimeEntryToMove] = useState<WorkOrderTaskMoveableTimeEntryDto>(
    new WorkOrderTaskMoveableTimeEntryDto()
  )

  const [isMovingTimeEntry, setIsMovingTimeEntry] = useState<boolean>(false)

  const [timeEntry, setTimeEntry] = useState<WorkOrderReleaseTaskTimeEntryDto>(new WorkOrderReleaseTaskTimeEntryDto())
  const [timeEntryDialogOpen, setTimeEntryDialogOpen] = useState<boolean>(false)

  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)

  const [isGettingTimePunches, setIsGettingTimePunches] = useState<boolean>(false)

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [isCreatingTimeEntry, setIsCreatingTimeEntry] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [timeEntries, setTimeEntries] = useState<WorkOrderReleaseTaskTimeEntryDto[]>([])

  const [timePunches, setTimePunches] = useState<TimePunchDto[]>([])
  const getTimePunches = useDebounce((parameters: TimePunchesParameters) => {
    setIsGettingTimePunches(true)
    api
      .getTimePunches(parameters)
      .then(res => {
        setTimePunches(res.value)
        setCount(res.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingTimePunches(false)
      })
  }, 300)

  const [parameters, setParameters] = useState<WorkOrderReleaseTaskTimeEntriesParameters>({
    page: 0,
    pageSize: 999,
    teamMemberId: '',
    projectManagerId: '',
    companyId: '',
    startDate: formatDateValue(new Date())
  })

  const getTimeEntries = useDebounce((parameters: WorkOrderReleaseTaskTimeEntriesParameters) => {
    setIsLayoutLoading(true)
    api
      .getWorkOrderReleaseTaskTimeEntries(parameters)
      .then(({ totalCount, value }) => {
        setCount(totalCount ?? 0)
        setTimeEntries(value)
      })
      .catch()
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 333)

  useEffect(() => {
    setTitle('Time Entries')

    setIsGettingTeamMembers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'TASKS',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })
  }, [])

  useEffect(() => {
    getTimeEntries(parameters)
    getTimePunches({
      personId: parameters.teamMemberId,
      dateFrom: parameters.startDate,
      dateTo: parameters.endDate
    })
  }, [parameters])

  useEffect(() => {
    setParameters({
      ...parameters,
      teamMemberId: user!.id
    })
  }, [user])

  const onClickTodayShortcut = () => {
    setParameters({
      ...parameters,
      startDate: new Date().toISOString().split('T')[0],
      endDate: undefined
    })

    handleClose()
  }

  const onClickYesterdayShortcut = () => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    setParameters({
      ...parameters,
      startDate: yesterday.toISOString().split('T')[0],
      endDate: undefined
    })

    handleClose()
  }

  const onClickCurrentPayPeriodShortcut = () => {
    const payPeriod = getPayPeriod(new Date())

    setParameters({
      ...parameters,
      startDate: payPeriod[0].toISOString().split('T')[0],
      endDate: payPeriod[1].toISOString().split('T')[0]
    })

    handleClose()
  }

  const onClickLastPayPeriodShortcut = () => {
    const fourteenDaysAgo = new Date()
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14)

    const payPeriod = getPayPeriod(fourteenDaysAgo)

    setParameters({
      ...parameters,
      startDate: payPeriod[0].toISOString().split('T')[0],
      endDate: payPeriod[1].toISOString().split('T')[0]
    })

    handleClose()
  }

  const getNextPayday = () => {
    const today = new Date()
    const nextPayday = new Date(2023, 9, 27, 0, 0, 0)

    while (today > nextPayday) {
      nextPayday.setDate(nextPayday.getDate() + 2 * 7)
    }

    return nextPayday
  }

  return (
    <>
      <WorkOrderReleaseTaskTimeEntryAddEditDialog
        open={timeEntryDialogOpen}
        initialValues={timeEntry}
        isLoading={isCreatingTimeEntry}
        onClose={() => {
          setTimeEntryDialogOpen(false)
          setTimeEntry(new WorkOrderReleaseTaskTimeEntryDto())
        }}
        onSave={values => {
          setIsCreatingTimeEntry(true)
          api
            .updateWorkOrderReleaseTaskTimeEntry(values)
            .then(() => {
              enqueueSnackbar('Time Entry Saved Successfully!', { variant: 'success' })
              setTimeEntryDialogOpen(false)
              getTimeEntries(parameters)
            })
            .finally(() => {
              setIsCreatingTimeEntry(false)
            })
        }}
      />

      <WorkOrderReleaseTaskMoveTimeEntryAddEditDialog
        timeEntry={timeEntry}
        initialValues={timeEntryToMove}
        open={moveTimeEntryDialogOpen}
        onClose={() => setMoveTimeEntryDialogOpen(false)}
        onSave={values => {
          setIsMovingTimeEntry(true)
          api
            .moveWorkOrderReleaseTaskTimeEntry({ ...values, isActive: true })
            .then(() => {
              enqueueSnackbar('Time entry moved successfully', { variant: 'success' })
              getTimeEntries(parameters)
              setMoveTimeEntryDialogOpen(false)
            })
            .finally(() => {
              setIsMovingTimeEntry(false)
            })
        }}
      />

      <List>
        <ListHeader>Time Entries</ListHeader>

        <ListFilters>
          <TextField
            label='Team Member'
            onChange={e => {
              setParameters({
                ...parameters,
                teamMemberId: e.target.value,
                projectManagerId: '',
                companyId: ''
              })
            }}
            select
            sx={{ minWidth: 200 }}
            value={parameters.teamMemberId || ''}
          >
            {teamMembers.map(teamMember => (
              <MenuItem key={teamMember.id} value={teamMember.id}>
                {teamMember.firstName} {teamMember.lastName}
              </MenuItem>
            ))}
          </TextField>

          <ListFilterGroup>
            <TextField
              InputLabelProps={{ shrink: true }}
              label='Start Date'
              onChange={e => {
                setParameters({
                  ...parameters,
                  startDate: e.target.value,
                  endDate: undefined
                })
              }}
              sx={{ minWidth: 200 }}
              type='date'
              value={parameters.startDate || ''}
            />

            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: parameters.startDate } }}
              label='End Date'
              onChange={e => {
                setParameters({ ...parameters, endDate: e.target.value })
              }}
              sx={{ minWidth: 200 }}
              type='date'
              value={parameters.endDate || ''}
              disabled={!parameters.startDate}
            />

            <Button variant='text' onClick={handleClick}>
              QUICK FILTERS...
            </Button>

            <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
              <MenuItem onClick={onClickTodayShortcut}>Today (Default)</MenuItem>

              <MenuItem onClick={onClickYesterdayShortcut}>Yesterday</MenuItem>

              <MenuItem onClick={onClickCurrentPayPeriodShortcut}>Current Pay Period</MenuItem>

              <MenuItem onClick={onClickLastPayPeriodShortcut}>Last Pay Period</MenuItem>
            </Menu>
          </ListFilterGroup>

          <ListFilterGroup>
            <LabelField label='Time Logged' value={decimalToHhMm(timeEntries?.reduce((a, b) => a + b.hoursWorked!, 0))} />

            <LabelField
              label='Time Punched'
              value={getTimePunched(timePunches)} //{timeEntries?.reduce((a, b) => a + b.hoursWorked!, 0) + ''}
            />

            <LabelField
              label='Difference'
              value={decimalToHhMm(
                Math.abs(
                  timeEntries?.reduce((a, b) => a + b.hoursWorked!, 0) - legacy_hhMmToDecimal(getTimePunched(timePunches))
                )
              )}
            />

            <LabelField label='Next Payday' value={formatDate(getNextPayday())} />
          </ListFilterGroup>
        </ListFilters>

        <ListBody>
          {timeEntries.map(timeEntry => (
            <Grid item xs={12}>
              <TimeEntryListCard
                timeEntry={timeEntry}
                setTimeEntry={setTimeEntry}
                setTimeEntryDialogOpen={setTimeEntryDialogOpen}
                setTimeEntryToMove={setTimeEntryToMove}
                setMoveTimeEntryDialogOpen={setMoveTimeEntryDialogOpen}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ExplanationAccordion>
              Ordered by Work Date Ascending then by Created Date/Time Ascending. ||Team member dropdown field defaults to the
              signed in user. ||Start Date and End Date can be Chosen independently and will always default to today's date.
              ||QUICK FILTERS allows the user to choose Today (Default), Current Pay Period, or Last Pay Period. ||Time Logged
              calculates the time the user has logged to Tasks within the chosen dates. ||Time Punched calculates the amount of
              time the user was punched in for on the chosen dates. ||Difference calculates the time difference between Time
              Logged and Time Punched. ||Next Payday displays the users next Payday Date.
            </ExplanationAccordion>
          </Grid>
        </ListBody>
      </List>
    </>
  )
}
