import { createTheme, Theme as MuiTheme } from '@mui/material/styles'

// The muiTheme is the MUI theme without custom variables.
const muiTheme: MuiTheme = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        variant: 'elevation',
        elevation: 4
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: theme => ({
          paddingBottom: theme.theme.spacing(3) + ' !important',
          boxSizing: 'border-box'
        })
      }
    },
    MuiGrid: {
      defaultProps: {
        alignItems: 'center',
        spacing: 1
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small'
      }
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
        size: 'small'
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: 'small'
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
        color: 'primary'
      }
    },
    MuiTablePagination: {
      defaultProps: {
        sx: {
          color: 'primary',

          '& .MuiSelect-icon': {
            color: 'primary'
            // backgroundColor: timePunchType?.hexColor ?? '#000'
          },

          '& .MuiTablePagination-actions': {
            '& .MuiButtonBase-root': {
              color: 'secondary'
            },
            '& .Mui-disabled': {
              color: 'secondary'
            }
          }
        }
      }
    }
  }
})

interface BaseTheme extends MuiTheme {
  drawer: {
    width: number
  }
}

// theme is the extended theme with custom variables.
// It outlines common styles across both light and dark themes.
export const baseTheme: BaseTheme = {
  ...muiTheme,
  drawer: {
    width: 240
  },

  components: {
    ...muiTheme.components,
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*': {
            boxSizing: 'border-box'
          },
          html: {
            scrollBehavior: 'smooth',
            color: 'primary'
          }
        }
      }
    }
  }
}

interface Formats {
  currency: Intl.NumberFormat
  date: string
  dateTime: string
  decimal: Intl.NumberFormat
  percent: Intl.NumberFormat
  phone: string
  time: string
  weekDayDate: string
}

export const formats: Formats = {
  currency: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }),
  date: 'M/D/YYYY',
  dateTime: 'M/D/YYYY h:mm A',
  decimal: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }),
  percent: new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  }),
  phone: '(###) ###-####',
  time: 'h:mm A',
  weekDayDate: 'ddd. M/D/YYYY'
}

export const format = (value: any, type: Formats[keyof Formats] | undefined = undefined): string => {
  switch (type) {
    case formats.currency:
      return value || value === 0 ? formats.currency.format(value) : 'Not set'
    case formats.decimal:
      return value || value === 0 ? formats.decimal.format(value) : 'Not set'
    case formats.percent:
      return value || value === 0 ? formats.percent.format(value / 100) : 'Not set'
    default:
      return value || value === 0 ? value : 'Not set'
  }
}
