import * as yup from 'yup'
import { CampaignDto } from './campaign'
import { CompanyDto, companyValidationSchema } from './company'
import { OpportunityActivityDto } from './opportunityActivity'
import { PersonDto, peopleValidationSchema } from './person'
import { OpportunityActivityTypeDto } from './opportunityActivityType'
import { OpportunityNextActivityTypeDto } from './opportunityNextActivityType'
import { OpportunityProbabilityDto } from './opportunityProbability'
import { OpportunityStatusDto } from './opportunityStatus'

export class OpportunityDto {
  id?: string = undefined
  opportunityNumber?: number = undefined
  opportunityName?: string = undefined
  opportunityDescription?: string = undefined
  activityDate?: string = new Date().toISOString().split('T')[0]
  activityNote?: string = undefined
  nextActivityDate?: string = undefined

  isPastDue?: boolean = undefined
  lastOpportunityActivity?: OpportunityActivityDto = undefined

  targetCompany?: CompanyDto = undefined
  targetPerson?: PersonDto = undefined
  salesperson?: PersonDto = undefined
  campaign?: CampaignDto = undefined
  opportunityActivity?: OpportunityActivityDto[] = undefined
  opportunityActivityType?: OpportunityActivityTypeDto = undefined
  opportunityNextActivityType?: OpportunityNextActivityTypeDto = undefined
  opportunityProbability?: OpportunityProbabilityDto = undefined
  opportunityStatus?: OpportunityStatusDto = undefined
}

export const opportunityValidationSchema = yup.object({
  opportunityName: yup
    .string()
    .max(200, 'Opportunity Name cannot be more than 200 characters.')
    .required('Opportunity Name is required.'),
  targetCompany: companyValidationSchema.default(undefined).required('Target Company is required.'),
  targetPerson: peopleValidationSchema.default(undefined).nullable(),
  opportunityDescription: yup
    .string()
    .max(2_000, 'Opportunity Description cannot be more than 2,000 characters.')
    .required('Opportunity Description is required.'),
  salesperson: yup.object().required('Salesperson is required.'),
  campaign: yup.object().nullable()
})
