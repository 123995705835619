import * as yup from 'yup'

export class CampaignDto {
  id?: string = undefined
  name?: string = undefined
  startDate?: string = undefined
  endDate?: string = undefined
  description?: string = undefined
  isActive: boolean = true
}

export const campaignValidationSchema = yup.object({
  name: yup.string().max(200, 'Campaign Name cannot be more than 200 characters.').required('Campaign Name is required.'),
  description: yup.string().max(2_000, 'Description cannot be more than 2,000 characters.').nullable()
})
