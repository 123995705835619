import { Button, TextField } from '@mui/material'
import axios from 'axios'
import { GuestLayout } from 'components/_template'
import { ApiResponse, ForgotPasswordDto, forgotPasswordValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Forgot Password | Spud ERP'
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={new ForgotPasswordDto()}
      validateOnChange
      validateOnBlur
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await axios
          .post<ApiResponse<string>>('/api/account/forgot-password', values)
          .then(({ data: { value } }) => {
            enqueueSnackbar(value, { variant: 'success' })
          })
          .catch(_ => {})
          .finally(() => navigate('/account/sign-in'))
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => {
        return (
          <GuestLayout handleSubmit={handleSubmit} title='Forgot Password'>
            <TextField
              autoComplete='email'
              autoFocus
              disabled={isSubmitting}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              InputProps={{ sx: { color: 'black' } }}
              label='Email Address'
              margin='normal'
              name='email'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ''}
            />

            <Button type='submit' fullWidth variant='contained' disabled={isSubmitting}>
              SEND PASSWORD RESET EMAIL
            </Button>
          </GuestLayout>
        )
      }}
    </Formik>
  )
}
