import { Grid, MenuItem, TextField } from '@mui/material'
import { WorkOrderReleaseTaskTimeEntryAddEditDialog } from 'components'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { List, ListBody, ListFilters, ListHeader, ListRow, ListRowAction } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import { CompanyDto, PersonDto, StatusCount, WorkOrderReleaseTaskDto, WorkOrderReleaseTaskTimeEntryDto } from 'dtos'
import { useQuery } from 'hooks'
import { enqueueSnackbar } from 'notistack'
import { WorkOrderReleaseTasksParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, useDebounce } from 'utils'

export default function Kpis() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const navigate = useNavigate()
  const query = useQuery()
  const { user } = useAuth()

  // Loading state
  const [isGettingTasks, setIsGettingTasks] = useState<boolean>(true)
  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)
  const [isGettingProjectManagers, setIsGettingProjectManagers] = useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)
  const [isCreatingTimeEntry, setIsCreatingTimeEntry] = useState<boolean>(false)

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [companies, setCompanies] = useState<CompanyDto[]>([])

  const [timeEntry, setTimeEntry] = useState<WorkOrderReleaseTaskTimeEntryDto>(new WorkOrderReleaseTaskTimeEntryDto())
  const [timeEntryDialogOpen, setTimeEntryDialogOpen] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [tasks, setTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  const [parameters, setParameters] = useState<WorkOrderReleaseTasksParameters>({
    page: 0,
    pageSize: 10,
    teamMemberId: '',
    projectManagerId: '',
    search: query.get('search') ?? '',
    statuses: ['isOpen', 'isComplete', 'needsEstimation', 'needsDueDate'],
    activeOnly: true,
    companyId: ''
  })

  const getTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters) => {
    setIsLayoutLoading(true)
    api
      .getWorkOrderReleaseTaskKpis(parameters)
      .then(({ totalCount, value }) => {
        // if (!parameters.includeCompleted) {
        //   value = value.filter(task => !task.isClosed)
        // }
        setCount(totalCount ?? 0)
        setTasks(value)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 333)

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'isOpen', count: 0 },
    { status: 'isComplete', count: 0 },
    { status: 'needsEstimation', count: 0 },
    { status: 'needsDueDate', count: 0 }
  ])

  const findStatusCount = (status: string) => statusCounts.filter(statusCount => statusCount.status === status)[0].count

  useEffect(() => {
    setTitle('Tasks')

    setIsGettingTeamMembers(true)
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })

    setIsGettingProjectManagers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'PROJECT_MANAGER',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setProjectManagers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingProjectManagers(false)
      })
  }, [])

  useEffect(() => {
    setParameters({
      ...parameters,
      teamMemberId: user!.id
    })
  }, [user])

  useEffect(() => {
    getTasks(parameters)
  }, [parameters])

  useEffect(() => {
    if (parameters.teamMemberId) {
      setIsGettingCompanies(true)
      api
        .getCompanies({ page: 0, pageSize: 999, teamMemberId: parameters.teamMemberId })
        .then(({ value }) => {
          setCompanies(value)
        })
        .catch(() => {})
        .finally(() => {
          setIsGettingCompanies(false)
        })
    }
  }, [parameters.teamMemberId])

  return (
    <>
      <WorkOrderReleaseTaskTimeEntryAddEditDialog
        open={timeEntryDialogOpen}
        initialValues={timeEntry}
        isLoading={isCreatingTimeEntry}
        onClose={() => {
          setTimeEntryDialogOpen(false)
          setTimeEntry(new WorkOrderReleaseTaskTimeEntryDto())
        }}
        onSave={values => {
          setIsCreatingTimeEntry(true)
          api
            .createWorkOrderReleaseTaskTimeEntry(values)
            .then(() => {
              enqueueSnackbar('Time Entry Saved Successfully!', { variant: 'success' })
              setTimeEntryDialogOpen(false)
              getTasks(parameters)
            })
            .finally(() => {
              setIsCreatingTimeEntry(false)
            })
        }}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
      >
        <ListHeader>Tasks</ListHeader>

        <ListFilters>
          <TextField
            label='Team Member'
            onChange={e => {
              setParameters({
                ...parameters,
                teamMemberId: e.target.value,
                projectManagerId: '',
                companyId: ''
              })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.teamMemberId || ''}
          >
            {teamMembers.map(teamMember => (
              <MenuItem key={teamMember.id} value={teamMember.id}>
                {teamMember.firstName} {teamMember.lastName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label='Project Manager'
            onChange={e => {
              setParameters({ ...parameters, projectManagerId: e.target.value })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.projectManagerId || ''}
          >
            {projectManagers.map(projectManager => (
              <MenuItem key={projectManager.id} value={projectManager.id}>
                {projectManager.firstName} {projectManager.lastName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label='Company'
            onChange={e => {
              setParameters({ ...parameters, companyId: e.target.value })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.companyId || ''}
          >
            {companies.map(company => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>

          <ChipStatusSelect
            label='Statuses'
            onChange={value => {
              setParameters({
                ...parameters,
                statuses: value
              })
            }}
            options={[
              {
                label: 'Open',
                color: 'green',
                value: 'isOpen'
                // count: findStatusCount('isOpen')
              },
              {
                label: 'Complete',
                color: 'gray',
                value: 'isComplete'
                // count: findStatusCount('isComplete')
              },
              {
                label: 'Needs Estimation',
                color: 'red',
                value: 'needsEstimation'
                // count: findStatusCount('needsEstimation')
              },
              {
                label: 'Needs Due Date',
                color: 'red',
                value: 'needsDueDate'
                // count: findStatusCount('needsDueDate')
              }
            ]}
            value={parameters.statuses ?? []}
          />
          {/*
          <FormControlLabel
            control={
              <Checkbox
                value={parameters.includeCompleted}
                checked={parameters.includeCompleted}
                onChange={e =>
                  setParameters({
                    ...parameters,
                    includeCompleted: e.target.checked
                  })
                }
              />
            }
            label='Include Completed'
          /> */}
        </ListFilters>

        <ListBody>
          {tasks.map(task => (
            <ListRow
              title={
                '#' +
                task.workOrderRelease?.workOrder?.workOrderNumber +
                ' - ' +
                task.workOrderRelease?.workOrder?.company?.name +
                ' - ' +
                task.workOrderRelease?.workOrder?.project?.name +
                ' - ' +
                task.workOrderRelease?.workOrderReleaseType?.name +
                ' - ' +
                task.workOrderRelease?.releaseTitle
              }
              subtitle1={'Due ' + formatDate(task.dueDate)}
              subtitle2={task.workToBeDone!}
              chips={[
                {
                  backgroundColor: '#f1f1f2',
                  color: '#9d9fa4',
                  label: 'Complete',
                  when: Boolean(task.completedDateTime)
                },
                {
                  backgroundColor: '#ffe9ea',
                  color: '#ff8185',
                  label: 'Needs Estimation',
                  when: Boolean(task.needsEstimation)
                }
              ]}
            >
              <ListRowAction
                label='LOG TIME'
                onClick={() => {
                  setTimeEntry({
                    ...new WorkOrderReleaseTaskTimeEntryDto(),
                    workOrderReleaseTask: task
                  })
                  setTimeEntryDialogOpen(true)
                }}
              >
                {/* <MenuItem>ROADBLOCK</MenuItem> */}
              </ListRowAction>
            </ListRow>
          ))}
        </ListBody>
        <Grid item xs={12}>
          <ExplanationAccordion>
            Needs Estimation will appear at the top of the list and will be ordered according to due date. ||A red badge will
            appear with the count of Tasks that need estimation on the menu Tasks button. || Ordered by Due Date Ascending then
            by Created Date/Time Ascending. ||Tasks will automatically default to the user that is signed in if they have tasks.
            ||The Project Manager dropdown allows the user to search for a project based on the Project Manager. ||The Company
            dropdown allows the user to search for a project based on the Company the project is under. ||Statuses multi-select
            filters the list based on the selected Statuses. ||A green chip will display 'Active' if the Tasks current status is
            "Active". ||A grey chip will display 'Complete' if the current status of the Task is "Complete". ||A red chip will
            display 'Needs Estimation' if the current status of the Task is "Needs Estimation".
          </ExplanationAccordion>
        </Grid>
      </List>
    </>
  )
}
