import { Box, useMediaQuery, useScrollTrigger } from '@mui/material'
import { AppHeader, AppMenu } from '_app'
import { Loading } from 'components/_template'
import { LoadingScreen } from 'pages'
import { createContext, useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { baseTheme } from 'theme/baseTheme'
import { useAuth } from './AuthContext'
import TimePunchProvider from './TimePunchContext'

interface LayoutContext {
  setIsLayoutLoading: (isLayoutLoading: boolean) => void
  setTitle: (title: string) => void
}

export const LayoutContext = createContext<LayoutContext | null>(null)

export default function LayoutProvider() {
  const { status } = useAuth()
  const location = useLocation()
  const [isLayoutLoading, _setIsLayoutLoading] = useState<boolean>(false)
  const [title, _setTitle] = useState<string>('')
  const { pathname } = useLocation()
  const isMobile = useMediaQuery(baseTheme.breakpoints.down('sm')) // useMediaQuery('(max-width: 768px)') // 960px?
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(!isMobile)
  // `trigger` is used for collapsing the header when the user scrolls down.
  const trigger = useScrollTrigger({ target: window ?? undefined })

  useEffect(() => {
    setIsMenuOpen(!isMobile)
  }, [isMobile])

  const onOpenMenu = () => {
    setIsMenuOpen(true)
  }

  const onCloseMenu = () => {
    setIsMenuOpen(false)
  }

  const setIsLayoutLoading = (isLayoutLoading: boolean) => {
    _setIsLayoutLoading(isLayoutLoading)
  }

  const setTitle = (title: string) => {
    _setTitle(title)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (title) {
      document.title = title + ' | Spud ERP'
    } else {
      document.title = 'Spud ERP'
    }
  }, [title])

  if (status === 'loading') {
    return <LoadingScreen />
  } else if (status === 'unauthorized') {
    return <Navigate to={'/account/sign-in'} state={{ from: location }} />
  }

  return (
    <LayoutContext.Provider
      value={{
        setIsLayoutLoading,
        setTitle
      }}
    >
      <TimePunchProvider>
        <Box component='div' sx={{ display: 'flex', minHeight: '100%', minWidth: '100%' }}>
          <AppHeader isMobile={isMobile} trigger={trigger} />

          <AppMenu isMenuOpen={isMenuOpen} isMobile={isMobile} onCloseMenu={onCloseMenu} onOpenMenu={onOpenMenu} />

          <Box
            component='main'
            sx={theme => ({
              flexGrow: 1,
              overflow: 'hidden',
              padding: theme.spacing(2),
              marginTop: '64px',
              marginBottom: '32px'
            })}
          >
            <Outlet />
            {isLayoutLoading && <Loading />}
          </Box>
        </Box>
      </TimePunchProvider>
    </LayoutContext.Provider>
  )
}

export const useLayout = () => useContext(LayoutContext)!
