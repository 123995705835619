import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import LabelField from 'components/LabelField'
import { SecretGroupDto } from 'dtos'
import React from 'react'

interface SecretGroupHeroCardProps {
  secretGroup: SecretGroupDto
}

export default function SecretGroupHeroCard({ children, secretGroup }: React.PropsWithChildren<SecretGroupHeroCardProps>) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='space-between'>
            <Grid item xs={12} sm={true}>
              <Typography fontWeight='bold' variant='h5'>
                {secretGroup.name}
              </Typography>
            </Grid>

            <Grid item xs={12} sm='auto' container spacing={1}>
              {children}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <LabelField label='Description' value={secretGroup.description} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
