import { useAuth } from 'context'
import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

interface RequirePermissionsProps {
  permissions: string[]
  redirect?: string
}

export default function RequirePermissions({
  children,
  permissions,
  redirect = '/blank'
}: PropsWithChildren<RequirePermissionsProps>) {
  const { user } = useAuth()

  if (!user?.permissions.some(permission => permissions.includes(permission.specialIdentifier!))) {
    return <Navigate to={redirect} />
  }

  return <>{children}</>
}
