import * as yup from 'yup'
import { PersonDto } from './person'

export class TextMessageDto {
  message?: string = undefined
  recipient?: PersonDto = undefined
  createdBy?: PersonDto = undefined
  createdDateTime?: Date = undefined
}

export const textMessageValidationSchema = yup.object({
  recipient: yup.object().required('Recipient is required.'),
  message: yup.string().max(2_000, 'Message cannot be more than 2,000 characters.').required('Message is required.')
})
