import { Grid } from '@mui/material'
import * as controllers from 'controllers'
import { CompanyDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CompanyAddressesListCard from './CompanyAddressesListCard'
import CompanyDetailsCard from './CompanyDetailsCard'
import CompanyVendorContractInformationCard from './CompanyVendorContractInformationCard'
import { useLayout } from 'context'
import CompanyProjectsCard from './CompanyProjectsCard'

export default function CompaniesId() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { id } = useParams()

  const [company, setCompany] = useState<CompanyDto>(new CompanyDto())

  useEffect(() => {
    setTitle('Company Details')
  }, [])

  useEffect(() => {
    if (id) {
      setIsLayoutLoading(true)
      controllers
        .getCompanyById(id)
        .then(response => {
          setCompany(response.value)
        })
        .finally(() => {
          setIsLayoutLoading(false)
        })
    }
  }, [id])

  return (
    <Grid container spacing={2} alignItems='flex-start'>
      <Grid item xs={12}>
        <CompanyDetailsCard company={company} setCompany={setCompany} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <CompanyAddressesListCard company={company} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <CompanyProjectsCard company={company} />
      </Grid>

      {company && company.isVendor && (
        <Grid item xs={12} sm={8}>
          <CompanyVendorContractInformationCard company={company} />
        </Grid>
      )}
    </Grid>
  )
}
