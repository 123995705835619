import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { RecruitingDto } from './recruiting'
import { RecruitingActivityTypeDto } from './recruitingActivityType'
import { RecruitingNextActivityTypeDto } from './recruitingNextActivityType'
import { RecruitingStatusDto } from './recruitingStatus'
import { PersonDto } from './person'

export interface FormAttachment {
  fileName: string
  attachment: File
}

export class RecruitingActivityDto {
  id?: string = undefined
  activityDate?: string = new Date().toISOString().split('T')[0]
  activityNote?: string = undefined
  nextActivityDate?: string = undefined
  activityStatus?: string = undefined
  formAttachment?: FormAttachment[] = undefined
  recruiting?: RecruitingDto = undefined
  recruitingActivityType?: RecruitingActivityTypeDto = undefined
  recruitingNextActivityType?: RecruitingNextActivityTypeDto = undefined
  recruitingStatus?: RecruitingStatusDto = undefined
  READONLY_createdBy?: PersonDto = undefined
}

export const recruitingActivityValidationSchema = yup.object({
  recruitingActivityType: yup.object().required(requiredFieldMessage),
  activityDate: yup.date().required(requiredFieldMessage),
  activityNote: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  recruitingStatus: yup.object().required(requiredFieldMessage),
  recruitingActivityDate: yup
    .date()
    .when('recruitingStatus.specialIdentifier', (values, schema) => {
      const specialIdentifier = values[0]

      if (
        'RECRUITING_STATUS_RECRUITING' === specialIdentifier ||
        'RECRUITING_STATUS_REVIEW_RESUME' === specialIdentifier ||
        'RECRUITING_STATUS_PHONE_SCREEN' === specialIdentifier ||
        'RECRUITING_STATUS_IN_PERSON_INTERVIEW' === specialIdentifier ||
        'RECRUITING_STATUS_SEND_OFFER_LETTER' === specialIdentifier ||
        'RECRUITING_STATUS_NOT_A_GOOD_FIT' === specialIdentifier ||
        'RECRUITING_STATUS_HIRED' === specialIdentifier ||
        'RECRUITING_STATUS_TERMINATED' === specialIdentifier
      ) {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  recruitingNextActivityType: yup
    .object()
    .when('recruitingStatus.specialIdentifier', (values, schema) => {
      const specialIdentifier = values[0]

      if (
        'RECRUITING_STATUS_RECRUITING' === specialIdentifier ||
        'RECRUITING_STATUS_REVIEW_RESUME' === specialIdentifier ||
        'RECRUITING_STATUS_PHONE_SCREEN' === specialIdentifier ||
        'RECRUITING_STATUS_IN_PERSON_INTERVIEW' === specialIdentifier ||
        'RECRUITING_STATUS_SEND_OFFER_LETTER' === specialIdentifier ||
        'RECRUITING_STATUS_NOT_A_GOOD_FIT' === specialIdentifier ||
        'RECRUITING_STATUS_HIRED' === specialIdentifier ||
        'RECRUITING_STATUS_TERMINATED' === specialIdentifier
      ) {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    })
})
