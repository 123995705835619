import { Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { DepartmentDto, PersonDto, PersonHumanResourcesDto } from 'dtos'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import * as controllers from 'controllers'
import HumanResourcesEditDialog from './HumanResourcesEditDialog'
import { enqueueSnackbar } from 'notistack'

interface HumanResourcesCardProps {
  person: PersonDto
}

export default function HumanResourcesCard({ person }: HumanResourcesCardProps) {
  const [departments, setDepartments] = useState<DepartmentDto[]>([])
  const [humanResources, setHumanResources] = useState<PersonHumanResourcesDto>(new PersonHumanResourcesDto())
  const [humanResourcesEditDialogOpen, setHumanResourcesEditDialogOpen] = useState<boolean>(false)
  const [isSavingHumanResources, setIsSavingHumanResources] = useState<boolean>(false)

  useEffect(() => {
    controllers.getDepartments().then(response => {
      setDepartments(response.value)
    })
  }, [])

  useEffect(() => {
    if (person && person.id && person.id.toLowerCase() !== 'new' && person.id.toLowerCase() !== 'undefined') {
      controllers.getHumanResources(person.id).then(response => {
        setHumanResources(response.value)
      })
    }
  }, [person])

  return (
    <>
      <HumanResourcesEditDialog
        departments={departments}
        initialValues={humanResources}
        loading={isSavingHumanResources}
        onClose={() => {
          setHumanResourcesEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingHumanResources(true)
          controllers
            .updateHumanResources(person.id!, values)
            .then(response => {
              setHumanResources(response.value)
              setHumanResourcesEditDialogOpen(false)
              enqueueSnackbar('Human Resources Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingHumanResources(false)
            })
        }}
        open={humanResourcesEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Human Resources
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  onClick={() => {
                    setHumanResourcesEditDialogOpen(true)
                  }}
                  variant='text'
                >
                  EDIT&nbsp;
                  <Edit fontSize='small' />
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Start Date</Typography>
              <Typography variant='body1'>
                {humanResources.employmentStartDate ? moment.utc(humanResources.employmentStartDate).format('M/D/yyyy') : ''}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>End Date</Typography>
              <Typography variant='body1'>
                {humanResources.employmentEndDate ? moment.utc(humanResources.employmentEndDate).format('M/D/yyyy') : ''}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Departments</Typography>
              <Typography variant='body1'>
                {humanResources.departments?.map(department => department.name).join(', ')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2'>Birthday</Typography>
              <Typography variant='body1'>
                {humanResources.birthMonth && humanResources.birthDate
                  ? `${humanResources.birthMonth}/${humanResources.birthDate}`
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
