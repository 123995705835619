import { AppRegistration } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import { CampaignDto } from 'dtos'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils'

interface CampaignListCardProps {
  campaign: CampaignDto
}

export default function CampaignListCard({ campaign }: CampaignListCardProps) {
  const navigate = useNavigate()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {campaign.name}
                </Typography>
              </Grid>

              {campaign.isActive && (
                <Grid item>
                  <Chip variant='green'>Active</Chip>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm='auto' alignItems='flex-end'>
              <Button
                endIcon={<AppRegistration />}
                onClick={() => {
                  navigate('/campaigns/' + campaign.id)
                }}
                variant='text'
              >
                DETAILS
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2'>Description</Typography>
            <Typography color='primary' variant='body1'>
              {campaign.description}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Start Date</Typography>
            <Typography color='primary' variant='body1'>
              {campaign.startDate ? formatDate(campaign.startDate) : ''}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>End Date</Typography>
            <Typography color='primary' variant='body1'>
              {campaign.endDate ? formatDate(campaign.endDate) : ''}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
