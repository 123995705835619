import { Add, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material'
import { AddressAddEditDialog } from 'components'
import { Chip } from 'components/chips'
import * as controllers from 'controllers'
import { AddressDto, AddressTypeDto, CompanyDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

interface CompanyAddressesListCardProps {
  company: CompanyDto
}

export default function CompanyAddressesListCard({ company }: CompanyAddressesListCardProps) {
  const [isSavingAddress, setIsSavingAddress] = useState<boolean>(false)
  const [addressAddEditDialogOpen, setAddressAddEditDialogOpen] = useState<boolean>(false)
  const [address, setAddress] = useState<AddressDto>(new AddressDto())
  const [addresses, setAddresses] = useState<AddressDto[]>([])
  const [addressTypes, setAddressTypes] = useState<AddressTypeDto[]>([])

  useEffect(() => {
    controllers.getAddressTypes().then(response => {
      setAddressTypes(response.value)
    })
  }, [])

  useEffect(() => {
    if (company && company.id) {
      controllers.getCompanyAddresses(company.id).then(response => {
        setAddresses(response.value)
      })
    }
  }, [company])

  return (
    <>
      <AddressAddEditDialog
        address={address}
        addressTypes={addressTypes}
        loading={isSavingAddress}
        onClose={() => {
          setAddressAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingAddress(true)
          if (values.id) {
            controllers
              .updateAddress(values)
              .then(response => {
                setAddresses(addresses.map(address => (address.id == response.value.id ? response.value : address)))
                setAddressAddEditDialogOpen(false)
                enqueueSnackbar('Address Saved Successfully!', { variant: 'success' })
              })
              .finally(() => {
                setIsSavingAddress(false)
              })
          } else {
            controllers
              .createCompanyAddress(values, company.id!)
              .then(response => {
                setAddresses([...addresses, response.value])
                setAddressAddEditDialogOpen(false)
                enqueueSnackbar('Address saved successfully!', { variant: 'success' })
              })
              .finally(() => {
                setIsSavingAddress(false)
              })
          }
        }}
        open={addressAddEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight='bold' variant='h5'>
                  Addresses
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  onClick={() => {
                    setAddress(new AddressDto())
                    setAddressAddEditDialogOpen(true)
                  }}
                  variant='text'
                >
                  ADD&nbsp;
                  <Add fontSize='small' />
                </Button>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}

            {addresses.map(address => (
              <Grid item xs={12} key={address.id}>
                <Card variant='outlined'>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                          <Grid item>
                            <Typography variant='h6' sx={{ pr: 1 }}>
                              {address.addressType?.name} - {address.notes}
                            </Typography>
                          </Grid>

                          {address.isActive && (
                            <Grid item>
                              <Chip variant='green'>Active</Chip>
                            </Grid>
                          )}

                          {!address.isActive && (
                            <Grid item>
                              <Chip variant='gray'>Inactive</Chip>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12} sm='auto' alignItems='flex-end'>
                          <Button
                            onClick={() => {
                              setAddress(address)
                              setAddressAddEditDialogOpen(true)
                            }}
                            variant='text'
                          >
                            EDIT&nbsp;
                            <Edit fontSize='small' />
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body1'>
                          <Link href={'tel:' + address.phone}>{address.phone}</Link>
                        </Typography>

                        <Typography variant='body1'>
                          <Link href={'mailto:' + address.email}>{address.email}</Link>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body1'>{address.address1}</Typography>
                        <Typography variant='body1'>{address.address2}</Typography>
                        <Typography variant='body1'>
                          {address.city}, {address.state} {address.postalCode}
                        </Typography>
                        <Typography variant='body1'>{address.country}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
