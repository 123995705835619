import { CloudUploadOutlined } from '@mui/icons-material'
import { ButtonBase, FormHelperText, Typography, styled } from '@mui/material'
import Dropzone from 'react-dropzone'

const Emphasize = styled('span')(props => ({
  color: `${props.theme.palette.primary.main}`,
  textDecoration: 'underline'
}))

interface OutlineProps {
  error: boolean
}

const Outline = styled(ButtonBase)<OutlineProps>(props => ({
  border: `${
    props.error === true
      ? '2px dashed props.theme.palette.error.main'
      : '2px dashed #cecece'
  }`,
  color: `${props.error === true ? props.theme.palette.error.main : 'inherit'}`,
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  width: '100%'
}))

export default function UploadDropzone() {
  return (
    <Dropzone onDrop={() => {}} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <>
          <Outline {...getRootProps()}>
            <CloudUploadOutlined
              color='disabled'
              /*color={error ? 'error' : 'disabled'}*/ fontSize='large'
            />
            <input {...getInputProps()} />
            <Typography variant='body1'>
              Drag and drop or <Emphasize>browse</Emphasize>
            </Typography>
          </Outline>
          <FormHelperText error>{/*helperText*/}</FormHelperText>
        </>
      )}
    </Dropzone>
  )
}
