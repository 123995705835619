import { Add, AppRegistration, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import SecretGroupAddEditDialog from './id/SecretGroupAddEditDialog'
import { SecretGroupDto } from 'dtos'
import { useLoadingState, useQuery } from 'hooks'
import * as controllers from 'controllers'
import { enqueueSnackbar } from 'notistack'
import { SecretGroupsParameters } from 'parameters'
import { useDebounce } from 'utils'
import { useAuth, useLayout } from 'context'
import SecretGroupHeroCard from './SecretGroupHeroCard'
import { useNavigate } from 'react-router-dom'

const sessionStorageKey = 'secrets:parameters'

export default function Secrets() {
  const { permissions } = useAuth()
  const query = useQuery()
  const layout = useLayout()
  const navigate = useNavigate()

  const defaultParameters: SecretGroupsParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    orderBy: 'name',
    order: 'asc'
  }

  const [isSecretGroupAddEditDialogOpen, setIsSecretGroupAddEditDialogOpen] = useState<boolean>(false)
  const [secretGroup, setSecretGroup] = useState<SecretGroupDto>(new SecretGroupDto())
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingSecretGroups: false,
    isCreatingSecretGroup: false
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [parameters, setParameters] = useState<SecretGroupsParameters>(
    sessionStorage.getItem(sessionStorageKey) ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!) : defaultParameters
  )
  const [secretGroups, setSecretGroups] = useState<SecretGroupDto[]>([])

  const getSecretGroups = useDebounce((parameters: SecretGroupsParameters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))

    setLoadingState('isGettingSecretGroups', true)
    controllers
      .getSecretGroups(parameters)
      .then(response => {
        setSecretGroups(response.value ?? [])
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setLoadingState('isGettingSecretGroups', false)
      })
  }, 300) // TODO: CONSTANTS!!!!

  useEffect(() => {
    getSecretGroups(parameters)
  }, [getSecretGroups, parameters])

  useEffect(() => {
    layout.setTitle('Secrets')

    return () => {
      layout.setTitle('')
    }
  }, [layout])

  useEffect(() => {
    layout.setIsLayoutLoading(loadingState.isGettingSecretGroups)
  }, [layout, loadingState.isGettingSecretGroups])

  return (
    <>
      <SecretGroupAddEditDialog
        initialValues={secretGroup}
        loading={loadingState.isCreatingSecretGroup}
        onClose={() => {
          setIsSecretGroupAddEditDialogOpen(false)
        }}
        onSave={values => {
          setLoadingState('isCreatingSecretGroup', true)
          controllers
            .createSecretGroup(values)
            .then(response => {
              enqueueSnackbar('Secret Group Saved Successfully!', { variant: 'success' })
              setIsSecretGroupAddEditDialogOpen(false)
              getSecretGroups(parameters)
            })
            .finally(() => {
              setLoadingState('isCreatingSecretGroup', false)
            })
        }}
        open={isSecretGroupAddEditDialogOpen}
      />

      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Secrets
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm={true} container spacing={1}>
                  <Grid item xs={12} sm='auto'>
                    <TextField
                      data-search
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <SearchOutlined fontSize='small' />
                          </InputAdornment>
                        )
                      }}
                      label='Search'
                      onChange={e => {
                        // setParameters({ ...parameters, search: e.target.value ?? undefined })
                      }}
                      size='small'
                      sx={{ minWidth: '200px', maxWidth: '200px' }}
                      type='search'
                    />
                  </Grid>
                </Grid>

                {permissions.SECRETS_ADD_EDIT && (
                  <Grid item xs={12} sm='auto'>
                    <Button
                      endIcon={<Add />}
                      onClick={() => {
                        setSecretGroup(new SecretGroupDto())
                        setIsSecretGroupAddEditDialogOpen(true)
                      }}
                      variant='text'
                    >
                      ADD NEW
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {secretGroups.map(secretGroup => (
          <Grid item xs={12} key={secretGroup.secretGroupGuid}>
            <SecretGroupHeroCard secretGroup={secretGroup}>
              <Grid item xs={12} sm='auto'>
                <Button
                  endIcon={<AppRegistration />}
                  onClick={() => {
                    navigate(`/secrets/${secretGroup.secretGroupGuid}`)
                  }}
                  variant='text'
                >
                  DETAILS
                </Button>
              </Grid>
            </SecretGroupHeroCard>
          </Grid>
        ))}

        <Grid item xs={12}>
          <TablePagination
            component='div'
            count={totalCount}
            onPageChange={(_, page: number) => {
              setParameters({
                ...parameters,
                page
              })
            }}
            onRowsPerPageChange={e => {
              setParameters({
                ...parameters,
                pageSize: e.target.value as unknown as number
              })
            }}
            page={parameters.page}
            rowsPerPage={parameters.pageSize}
            labelRowsPerPage={
              <Grid container spacing={1}>
                <Grid item>Order By:</Grid>

                <Grid item>
                  <TextField
                    InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                    onChange={e => {
                      setParameters({ ...parameters, orderBy: e.target.value as typeof parameters.orderBy })
                    }}
                    select
                    size='small'
                    value={parameters.orderBy}
                    variant='standard'
                  >
                    <MenuItem value='name'>Name</MenuItem>

                    <MenuItem value='description'>Description</MenuItem>
                  </TextField>
                </Grid>

                <Grid item>
                  <TextField
                    InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                    onChange={e => {
                      setParameters({ ...parameters, order: e.target.value as 'asc' | 'desc' })
                    }}
                    select
                    size='small'
                    value={parameters.order}
                    variant='standard'
                  >
                    <MenuItem value='asc'>Ascending</MenuItem>

                    <MenuItem value='desc'>Descending</MenuItem>
                  </TextField>
                </Grid>

                <Grid item sx={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item>Cards Per Page:</Grid>
              </Grid>
            }
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '25', value: 25 },
              { label: '50', value: 50 },
              { label: '100', value: 100 }
            ]}
            SelectProps={{ fullWidth: false }}
          />
        </Grid>
      </Grid>
    </>
  )
}
