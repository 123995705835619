import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@mui/material'
import { VendorContractInformationDto, VendorServiceTypeDto, vendorContactInformationValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { ExplanationAccordion } from './_template/accordion'
import { LoadingButton } from '@mui/lab'

interface VendorContractInformationAddEditDialogProps {
  initialValues: VendorContractInformationDto
  loading: boolean
  onClose: () => void
  onSave: (values: VendorContractInformationDto) => void
  open: boolean
  vendorServiceTypes: VendorServiceTypeDto[]
}

export default function VendorContractInformationAddEditDialog({
  initialValues,
  loading,
  onClose,
  onSave,
  open,
  vendorServiceTypes
}: VendorContractInformationAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur={false}
        validationSchema={vendorContactInformationValidationSchema}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{(values.id ? 'Edit' : 'Add') + ' Vendor Contract Information'}</DialogTitle>

                  {/* <Grid item xs={12}>
                    <LabelField label='Company' value={values.company?.name} />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onChange={(_e, value) => {
                        setFieldValue('vendorServiceType', value)
                      }}
                      onBlur={handleBlur}
                      renderInput={params => (
                        <TextField
                          {...params}
                          disabled={loading || isSubmitting}
                          error={Boolean(touched.vendorServiceType && errors.vendorServiceType)}
                          helperText={touched.vendorServiceType && errors.vendorServiceType}
                          label='Vendor Service Type'
                          name='vendorServiceType'
                        />
                      )}
                      options={vendorServiceTypes}
                      value={values.vendorServiceType ?? null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.account && errors.account)}
                      helperText={touched.account && errors.account}
                      label='Account'
                      name='account'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.account}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.serviceDescription && errors.serviceDescription)}
                      helperText={touched.serviceDescription && errors.serviceDescription}
                      label='Service Description'
                      name='serviceDescription'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.serviceDescription}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.annualBudget && errors.annualBudget)}
                      helperText={touched.annualBudget && errors.annualBudget}
                      label='Annual Budget'
                      name='annualBudget'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.annualBudget}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.billingFrequency && errors.billingFrequency)}
                      helperText={touched.billingFrequency && errors.billingFrequency}
                      label='Billing Frequency'
                      name='billingFrequency'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingFrequency}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.billingAmountPerFrequency && errors.billingAmountPerFrequency)}
                      helperText={touched.billingAmountPerFrequency && errors.billingAmountPerFrequency}
                      label='Billing Amount Per Frequency'
                      name='billingAmountPerFrequency'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.billingAmountPerFrequency}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.contractExpirationDate && errors.contractExpirationDate)}
                      helperText={touched.contractExpirationDate && errors.contractExpirationDate}
                      label='Contract Expiration Date'
                      InputLabelProps={{ shrink: true }}
                      name='contractExpirationDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='date'
                      value={values.contractExpirationDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          disabled={loading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All fields, except for Notes, are required. ||If there is no “contract” and it is month to month put
                      12/31/20XX of the current year for the expiration date.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={loading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
