import styled from '@emotion/styled'
import {
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Avatar from 'boring-avatars'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import * as controllers from 'controllers'
import { TeamMemberContactDto } from 'dtos'
import { useEffect, useState } from 'react'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function TeamMemberContact() {
  const [teamMemberContact, setTeamMemberContact] = useState<TeamMemberContactDto[]>([])
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    controllers.getTeamMemberContact().then(res => {
      setTeamMemberContact(res.value)
    })
  }, [])

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          Team Member
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {/* Left blank for avatar */}
                <TableCell colSpan={2}>Name</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {teamMemberContact?.map(({ teamMember }) => (
                <TableRow
                  key={teamMember?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Avatar
                      size='30'
                      square={false}
                      name={teamMember!.firstName + ' ' + teamMember!.lastName}
                      variant='beam'
                      colors={['#212640', '#D9C7A7', '#BFA380', '#8C6751', '#593E3C']}
                    />
                  </TableCell>

                  <TableCell>
                    <Typography variant='body2' noWrap>
                      {teamMember?.firstName + ' ' + teamMember?.lastName}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
