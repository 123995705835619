import { ArrowBack, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography, Avatar } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { Chip } from 'components/chips'
import * as controllers from 'controllers'
import { PersonDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import PersonAddEditDialog from './PersonAddEditDialog'

interface PersonDetailsProps {
  person: PersonDto
  setPerson: React.Dispatch<React.SetStateAction<PersonDto>>
}

export default function PersonDetailsCard({ person, setPerson }: PersonDetailsProps) {
  const [isSavingPerson, setIsSavingPerson] = useState<boolean>(false)
  const [personAddEditDialogOpen, setPersonAddEditDialogOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <>
      <PersonAddEditDialog
        initialValues={person}
        loading={isSavingPerson}
        onClose={() => {
          setPersonAddEditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id ? controllers.updatePerson : controllers.createPerson

          setIsSavingPerson(true)
          requestMethod(values)
            .then(response => {
              setPerson(response.value)
              setPersonAddEditDialogOpen(false)
              enqueueSnackbar('Person Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingPerson(false)
            })
        }}
        open={personAddEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
                <Grid item>
                  <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                    {person.firstName} {person.lastName}
                  </Typography>
                </Grid>

                {person.isActive && (
                  <Grid item>
                    <Chip variant='green'>Active</Chip>
                  </Grid>
                )}

                {!person.isActive && (
                  <Grid item>
                    <Chip variant='gray'>Inactive</Chip>
                  </Grid>
                )}

                {person.isUser && (
                  <Grid item>
                    <Chip variant='blue'>User</Chip>
                  </Grid>
                )}

                {person.company?.isVendor && (
                  <Grid item>
                    <Chip variant='purple'>Vendor</Chip>
                  </Grid>
                )}

                {person.isRecruitingCandidate && (
                  <Grid item>
                    <Chip variant='yellow'>Recruiting Candidate</Chip>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm='auto' container spacing={1} alignItems='center'>
                <Grid item xs={12} sm='auto'>
                  <Button
                    color='primary'
                    onClick={() => {
                      navigate(-1)
                    }}
                    startIcon={<ArrowBack />}
                    variant='text'
                  >
                    BACK
                  </Button>
                </Grid>

                <Grid item sx={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item xs={12} sm='auto' alignItems='flex-end'>
                  <Button
                    onClick={() => {
                      setPersonAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    EDIT&nbsp;
                    <Edit fontSize='small' />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Company</Typography>
              <Typography color='primary' variant='body1'>
                <Link to={'/companies/' + person.company?.id} component={ReactRouterLink}>
                  {person.company?.name}
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Title</Typography>
              <Typography color='primary' variant='body1'>
                {person.title}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Email</Typography>
              <Typography color='primary' variant='body1'>
                <Link href={'mailto:' + person.email}>{person.email}</Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Text Email</Typography>
              <Typography color='primary' variant='body1'>
                <Link href={'mailto:' + person.textEmail}>{person.textEmail}</Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Mobile Phone</Typography>
              <Typography color='primary' variant='body1'>
                <Link href={'tel:' + person.mobilePhone}>{person.mobilePhone}</Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Work Phone</Typography>
              <Typography color='primary' variant='body1'>
                <Link href={'tel:' + person.workPhone}>{person.workPhone}</Link>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Notes</Typography>
              <Typography color='primary' variant='body1'>
                {person.notes}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
