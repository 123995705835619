import { apiClient } from '_app'
import { ApiResponse, WorkOrderReleaseDto } from 'dtos'
import { WorkOrderReleasesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderReleases = (parameters: WorkOrderReleasesParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseDto[]>>('/api/work-order-releases' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrderReleasesByWorkOrderId = (parameters: WorkOrderReleasesParameters, workOrderId: string) =>
  apiClient
    .get<ApiResponse<WorkOrderReleaseDto[]>>(`/api/work-orders/${workOrderId}/releases` + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrderReleaseById = (id: string, workOrderId: string) =>
  apiClient.get<ApiResponse<WorkOrderReleaseDto>>(`/api/work-orders/${workOrderId}/releases/${id}`).then(({ data }) => data)

export const createWorkOrderRelease = (values: WorkOrderReleaseDto) =>
  apiClient.post<ApiResponse<WorkOrderReleaseDto>>(`/api/work-order-releases`, values).then(({ data }) => data)

export const updateWorkOrderRelease = (values: WorkOrderReleaseDto) =>
  apiClient.put<ApiResponse<WorkOrderReleaseDto>>(`/api/work-order-releases`, values).then(({ data }) => data)
