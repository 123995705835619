import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React from 'react'
import Chip from './Chip'

interface StatusOption {
  label: string
  color: 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'gray'
  count?: number
  value: string
}

interface ChipStatusSelectProps {
  label: string
  onChange: (values: string[]) => void
  options: StatusOption[]
  value: string[]
}

export default function ChipStatusSelect({ label, onChange, options, value }: ChipStatusSelectProps) {
  return (
    <FormControl fullWidth size='small' sx={{ minWidth: '100%' }}>
      <InputLabel /*id='departments-label'*/>{label}</InputLabel>
      <Select
        // labelId='departments-label'
        multiple
        fullWidth
        value={value.filter(v => v)}
        size='small'
        onChange={(event: SelectChangeEvent<string[]>, _child: React.ReactNode) => {
          if (Array.isArray(event.target.value)) {
            onChange(event.target.value)
          }
        }}
        input={<OutlinedInput label={label} fullWidth size='small' />}
        renderValue={selected =>
          selected.map(status => (
            <Chip variant={options.filter(option => option.value === status)[0]?.color ?? 'gray'}>
              {options.filter(option => option.value === status)[0]?.label ?? ''}
            </Chip>
          ))
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250
            }
          }
        }}
        sx={{
          minWidth: '100%'
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />

            <ListItemText primary={options.filter(o => o.value === option.value)[0].label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
