import { Add, SearchOutlined } from '@mui/icons-material'
import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { List, ListBody, ListFilterGroup, ListFilters, ListHeader } from 'components/list'
import { useLayout } from 'context'
import * as controllers from 'controllers'
import { PersonDto, StatusCount } from 'dtos'
import { useQuery } from 'hooks'
import { enqueueSnackbar } from 'notistack'
import { PeopleParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatusCount, useDebounce } from 'utils'
import PersonListCard from './PersonListCard'
import PersonAddEditDialog from './id/PersonAddEditDialog'

const sessionStorageKey = 'people:parameters'

export default function People() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const navigate = useNavigate()
  const query = useQuery()

  const defaultParameters: PeopleParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    statuses: ['isActive'],
    activeOnly: true
  }

  const getPeople = useDebounce((parameters: PeopleParameters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    setIsLayoutLoading(true)
    controllers
      .getPeople(parameters)
      .then(response => {
        setPeople(response.value)
        setStatusCounts(response.statusCounts ?? [])
        setCount(response.totalCount!)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  const [count, setCount] = useState<number>(0)
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'isActive', count: 0 },
    { status: 'isInactive', count: 0 },
    { status: 'isUser', count: 0 },
    { status: 'isVendor', count: 0 },
    { status: 'isRecruitingCandidate', count: 0 }
  ])

  const findStatusCount = (status: string) => statusCounts.filter(statusCount => statusCount.status === status)[0].count

  const [person, setPerson] = useState<PersonDto>(new PersonDto())
  const [personAddEditDialogOpen, setPersonAddEditDialogOpen] = useState<boolean>(false)
  const [isCreatingPerson, setIsCreatingPerson] = useState<boolean>(false)

  const [parameters, setParameters] = useState<PeopleParameters>(
    sessionStorage.getItem(sessionStorageKey) ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!) : defaultParameters
  )

  const [people, setPeople] = useState<PersonDto[]>([])

  useEffect(() => {
    setTitle('People')
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    getPeople(parameters)
  }, [getPeople, parameters])

  return (
    <>
      <PersonAddEditDialog
        initialValues={person}
        loading={isCreatingPerson}
        onClose={() => {
          setPersonAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingPerson(true)
          controllers
            .createPerson(values)
            .then(response => {
              // TODO: Should creating a person navigate you to the details screen?
              enqueueSnackbar('Person Created Successfully!', { variant: 'success' })
              getPeople(parameters)
              setPersonAddEditDialogOpen(false)
            })
            .finally(() => {
              setIsCreatingPerson(false)
            })
        }}
        open={personAddEditDialogOpen}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
      >
        <ListHeader>People</ListHeader>

        <ListFilters>
          <TextField
            data-search
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined fontSize='small' />
                </InputAdornment>
              )
            }}
            label='Search'
            name='search'
            onChange={e => setParameters({ ...parameters, search: e.target.value ?? undefined })}
            size='small'
            type='search'
            value={parameters.search}
          />

          <ChipStatusSelect
            label='Statuses'
            onChange={value => {
              setParameters({
                ...parameters,
                statuses: value
              })
            }}
            options={[
              {
                label: `Active (${getStatusCount(statusCounts, 'isActive')})`,
                color: 'green',
                value: 'isActive'
              },
              {
                label: `Inactive (${getStatusCount(statusCounts, 'isInactive')})`,
                color: 'gray',
                value: 'isInactive'
              },
              {
                label: `User (${getStatusCount(statusCounts, 'isUser')})`,
                color: 'blue',
                value: 'isUser'
              },
              {
                label: `Vendor (${getStatusCount(statusCounts, 'isVendor')})`,
                color: 'purple',
                value: 'isVendor'
              },
              {
                label: `Recruiting Candidate (${getStatusCount(statusCounts, 'isRecruitingCandidate')})`,
                color: 'yellow',
                value: 'isRecruitingCandidate'
              }
            ]}
            value={parameters.statuses ?? []}
          />

          <Button
            color='primary'
            endIcon={<Add />}
            onClick={() => {
              setPerson(new PersonDto())
              setPersonAddEditDialogOpen(true)
            }}
            size='medium'
            variant='text'
          >
            ADD NEW
          </Button>
        </ListFilters>

        <ListBody>
          {people.map(person => (
            <Grid item xs={12}>
              <PersonListCard person={person} />
            </Grid>
          ))}

          <Grid item xs={12}>
            <ExplanationAccordion>
              Cards are sorted by First Name + Last Name, Company Name. || If person has access to Spud ERP, blue User Chip
              appears. If the person is associated with a company that is marked as a vendor, Vendor Chip appears. If the person
              is marked as inactive, Inactive Chip appears. || Search searches all fields shown on the card. || The Statuses
              multi-select filters the list by the selected Statuses. || The Active, Inactive labels display the total count for
              each status within the current search criteria. This helps to prevent users from adding a person that already
              exists.
            </ExplanationAccordion>
          </Grid>
        </ListBody>
      </List>
    </>
  )
}
