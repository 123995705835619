import { apiClient } from '_app'
import { ApiResponse, SuggestionDto } from 'dtos'

export const createSuggestion = (values: SuggestionDto) =>
  apiClient
    .post<ApiResponse<SuggestionDto>>('/api/suggestions', values)
    // We return res.data so the consumer of this function doesn't get unnecessary details, like the response status code, content length, type, etc.
    .then(res => res.data)
    .catch(reason => {
      // We always throw new Error (reason) so that the apiClient base axios variable can handle it with enqueueSnackbar and it is displayed to the end user.
      throw new Error(reason)
    })

// export const createSuggestion = async (
//   suggestionData: SuggestionDto

//   // v By having this be asyncronous and return a Promise, consumers of this function would have to do .then(...).then(...) syntax
// ): Promise<ApiResponse<SuggestionDto>> => {

//   // Post is correct. the sample above is a more condense version of the try/catch syntax.
//   try {
//     const response = await apiClient.post<ApiResponse<SuggestionDto>>(
//       // v API endpoints will always be prefixed with "/api/"
//       '/suggestion-endpoint',
//       suggestionData
//     )
//     return response.data
//   } catch (error) {
//     throw new Error('Failed to create suggestion: ')
//   }
// }
