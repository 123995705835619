import styled from '@emotion/styled'
import { Card, Chip, Link, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as controllers from 'controllers'
import { PrepaidHoursDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'utils'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function PrepaidHoursRemaining() {
  const navigate = useNavigate()

  const [prepaidHoursRemaining, setPrepaidHoursRemaining] = useState<PrepaidHoursDto[]>(
    []
  )
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    getPrepaidHoursRemaining()
  }, [])

  const getPrepaidHoursRemaining = useDebounce(() => {
    controllers.getPrepaidHoursRemaining().then(res => {
      setPrepaidHoursRemaining(res.value)
    })
  }, 100)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{ ml: '8px', fontWeight: 'bold', padding: '8px', minWidth: '100%' }}
        >
          Pre-paid Hours Remaining
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>

                <TableCell align='center'>WO#</TableCell>

                <TableCell align='center'>Hours Remaining</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prepaidHoursRemaining.map(prepaidHours => (
                <TableRow
                  key={prepaidHours.workOrder?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='left'>
                    <Chip
                      label={prepaidHours.workOrder?.company?.name}
                      size='small'
                      sx={{ backgroundColor: '#eaf9e0', color: '#56ca00', m: 0.25 }}
                      variant='filled'
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <Link
                      onClick={() => {
                        navigate('/work-orders/' + prepaidHours.workOrder?.id)
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      {prepaidHours.workOrder?.workOrderNumber}
                    </Link>
                  </TableCell>

                  <TableCell align='center'>{prepaidHours.hoursRemaining}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
