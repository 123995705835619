import * as yup from 'yup'

export class SecretGroupDto {
  secretGroupGuid?: string = undefined
  name?: string = undefined
  description?: string = undefined
}

export const secretGroupValidationSchema = yup.object({
  name: yup.string().max(255, 'Name cannot be more than 255 characters.').required('Name is required.'),
  description: yup.string().max(255, 'Description cannot be more than 255 characters.').nullable()
})
