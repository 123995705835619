export function formatDate(
  inputDate: Date | string | undefined,
  dateOnly: boolean = false
): string {
  if (!inputDate) {
    return ''
  }

  const date = new Date(inputDate + 'T00:00:00')
  const month = date.getMonth() + 1 // Months are 0-based in JavaScript Date
  const day = date.getDate()
  const year = date.getFullYear()
  return dateOnly
    ? `${month}/${day}/${year}`
    : new Date(inputDate.toString()).toLocaleString('en-US', {
        timeZone: 'UTC',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      })
}
