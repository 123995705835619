import { Bolt } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import React, { Children } from 'react'

export interface BaseReportParametersProps {
  disabled?: boolean
  isGeneratingReport: boolean
  onGenerate: () => void
}

export default function BaseReportParameters({
  children,
  disabled,
  isGeneratingReport,
  onGenerate
}: React.PropsWithChildren<BaseReportParametersProps>) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} sm='auto' container spacing={1} alignItems='center'>
            {Children.map(children, child => (
              <Grid item xs={12} sm='auto'>
                {child}
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} sm={true} container alignItems='center' justifyContent='flex-end'>
            <Grid item xs={12} sm='auto'>
              <LoadingButton
                disabled={disabled}
                loading={isGeneratingReport}
                endIcon={<Bolt />}
                onClick={onGenerate}
                variant='text'
              >
                GENERATE
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
