import { Card, Grid } from '@mui/material'
import React from 'react'

// Define a helper type to infer the element type of an array
// type ArrayElement<ArrayType extends readonly any[]> =
//   ArrayType extends readonly (infer ElementType)[] ? ElementType : never

// class ListBodyColumn<T> {
//   column: string = ''
//   value: string = ''
//   // How do I make it so callers of this can use type/intellisense
//   // Like Material UI Autocomplete Options property?
//   getValue?: (arg: T) => string
// }

class ListBodyProps {
  // rows: any[] = []
  // columns: ListBodyColumn<ArrayElement<typeof this.rows>>[] = []
}

export default function ListBody({ children }: React.PropsWithChildren<ListBodyProps>) {
  // This component is *intentionally* left bare bones for now due to the launch Monday, October 2nd coming up.
  // In the future, this will support Table View as well as rendering both the Card View and Table View.
  // Stay tuned!
  return (
    <Grid item xs={12} container spacing={1} alignItems='center'>
      {children}
    </Grid>
  )
}
