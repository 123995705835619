import { CopyAll } from '@mui/icons-material'
import { Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

interface LabelFieldProps {
  copy?: boolean
  error?: boolean
  label?: string
  to?: string
  value?: string
}

export default function LabelField({ copy = false, error, label, to, value }: LabelFieldProps) {
  const [title, setTitle] = useState<string>('Copy to clipboard')

  return (
    <>
      <Typography variant='body2'>{label}</Typography>

      <Grid container alignItems='center'>
        <Grid item sm={true}>
          <Typography
            color={error ? 'error' : 'primary'}
            fontWeight={Boolean(value) ? 'inherit' : 'bold'}
            variant='body1'
            sx={{ minHeight: '24px' }}
          >
            {to && value ? (
              <Link
                {...(to && (to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel'))
                  ? { href: to }
                  : { to, component: ReactRouterLink })}
              >
                {Boolean(value) ? value : '-'}
              </Link>
            ) : Boolean(value) ? (
              value
            ) : (
              '-'
            )}
          </Typography>
        </Grid>

        {copy && value && (
          <Grid item sm='auto'>
            <Tooltip title={title}>
              <IconButton
                onClick={() => {
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(value)
                  setTitle('Copied ' + value)
                  setTimeout(() => {
                    setTitle('Copy to clipboard')
                  }, 1000)
                }}
                size='small'
              >
                <CopyAll fontSize='small' />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  )
}
