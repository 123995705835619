import { apiClient } from '_app'
import { ApiResponse, OpportunityActivityTypeDto } from 'dtos'
import { OpportunityActivityTypesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getOpportunityActivityTypes = (
  parameters: OpportunityActivityTypesParameters
) =>
  apiClient
    .get<ApiResponse<OpportunityActivityTypeDto[]>>(
      '/api/opportunity-activity-types' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
