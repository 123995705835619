import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { SecretGroupDto, secretGroupValidationSchema } from 'dtos'
import { Formik } from 'formik'
import React from 'react'

interface SecretGroupAddEditDialogProps {
  initialValues: SecretGroupDto
  loading: boolean
  onClose: () => void
  onSave: (values: SecretGroupDto) => void
  open: boolean
}

export default function SecretGroupAddEditDialog({
  initialValues,
  loading,
  onClose,
  onSave,
  open
}: SecretGroupAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={secretGroupValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{initialValues.secretGroupGuid ? 'Edit' : 'Add'} Secret Group</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      label='Name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                      label='Description'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ''}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} color='inherit' onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
