import styled from '@emotion/styled'
import { ChatBubble, Email, Phone } from '@mui/icons-material'
import {
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Avatar from 'boring-avatars'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import * as controllers from 'controllers'
import { TeamMemberStatusDto } from 'dtos'
import { useEffect, useState } from 'react'
import { decimalToHhMm, getTimePunched, useDebounce } from 'utils'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function TeamMembers() {
  const [teamMemberStatuses, setTeamMemberStatuses] = useState<TeamMemberStatusDto[]>([])
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    getTeamMemberStatuses()
  }, [])

  const getTeamMemberStatuses = useDebounce(() => {
    controllers.getTeamMemberStatuses().then(({ value }) => {
      setTeamMemberStatuses(value)
    })
  }, 100)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            // border: '4px solid white',
            // borderRadius: '1px',
            // backgroundColor: '#8C6751',
            // color: '#fff',
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          Team Members
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Name</TableCell>

                <TableCell align='left'>Availability</TableCell>

                <TableCell align='left'>Punch Time</TableCell>

                <TableCell align='center'>Hours Logged</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {teamMemberStatuses?.map(
                ({ person, timePunches, workOrderReleaseTaskTimeEntries }) => {
                  const punchedIn = (timePunches?.length ?? 0) % 2 !== 0

                  return (
                    <TableRow
                      key={person?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <Avatar
                          size='30'
                          square={false}
                          name={person!.firstName + ' ' + person!.lastName}
                          variant='beam'
                          colors={['#212640', '#D9C7A7', '#BFA380', '#8C6751', '#593E3C']}
                        />
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={person?.firstName + ' ' + person?.lastName}
                          size='small'
                          sx={{
                            backgroundColor: punchedIn ? '#eaf9e0' : '#ffe9ea',
                            color: punchedIn ? '#56ca00' : '#ff8185',
                            m: 0.25
                          }}
                          variant='filled'
                        />
                      </TableCell>

                      <TableCell align='left'>
                        <Typography variant='body2'>
                          {punchedIn ? 'Available' : 'Unavailable'}
                        </Typography>

                        <Typography variant='body2'>
                          {/* <Groups fontSize='small' style={{ marginRight: '4' }} /> */}
                          {person?.mobilePhone && (
                            <Phone fontSize='small' style={{ marginRight: '4' }} />
                          )}
                          {person?.mobilePhone && (
                            <ChatBubble fontSize='small' style={{ marginRight: '4' }} />
                          )}
                          <Email fontSize='small' style={{ marginRight: '4' }} />
                        </Typography>
                      </TableCell>

                      <TableCell align='left'>
                        {getTimePunched(timePunches ?? [])}
                      </TableCell>

                      <TableCell align='center'>
                        {decimalToHhMm(
                          workOrderReleaseTaskTimeEntries?.reduce(
                            (a, b) => a + b.hoursWorked!,
                            0
                          ) ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
