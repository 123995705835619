// The purpose for this function is to format Date values as yyyy-MM-dd without
// converting to UTC (which .toISOString() does.)
export function formatDateValue(date: Date) {
  // [0] = Month, [1] = Day, [2] = Year
  let dateParts = date.toLocaleDateString('en-US').split('/')

  // If the Month is a single digit, prefix 0 so it serializes as a Date.
  if (dateParts[0].length === 1) {
    dateParts[0] = '0' + dateParts[0]
  }

  // If the Day is a single digit, prefix 0 so it serializes as a Date.
  if (dateParts[1].length === 1) {
    dateParts[1] = '0' + dateParts[1]
  }

  return dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1]
}
