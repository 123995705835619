import { apiClient } from '_app'
import { ApiResponse, WorkOrderInvoiceDto } from 'dtos'
import { WorkOrderInvoicesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderInvoicesByWorkOrderId = (
  parameters: WorkOrderInvoicesParameters,
  workOrderId: string
) =>
  apiClient
    .get<ApiResponse<WorkOrderInvoiceDto[]>>(
      `/api/work-orders/${workOrderId}/invoices` + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getWorkOrderInvoiceById = (id: string, workOrderId: string) =>
  apiClient
    .get<ApiResponse<WorkOrderInvoiceDto>>(
      `/api/work-orders/${workOrderId}/invoices/${id}`
    )
    .then(({ data }) => data)

export const createWorkOrderInvoice = (values: WorkOrderInvoiceDto) =>
  apiClient
    .post<ApiResponse<WorkOrderInvoiceDto>>(`/api/work-order-invoices`, values)
    .then(({ data }) => data)

export const updateWorkOrderInvoice = (values: WorkOrderInvoiceDto) =>
  apiClient
    .put<ApiResponse<WorkOrderInvoiceDto>>(`/api/work-order-invoices`, values)
    .then(({ data }) => data)

export const deleteWorkOrderInvoice = (id: string, workOrderId: string) =>
  apiClient
    .delete<ApiResponse<string>>(`/api/work-orders/${workOrderId}/invoices/${id}`)
    .then(({ data }) => data)
