import * as yup from 'yup'
import { PersonDto } from './person'

export class TimePunchDto {
  id?: string = undefined
  timePunchDateTime?: string = undefined
  isManual?: boolean = undefined
  person?: PersonDto = undefined
  createdBy?: PersonDto = undefined
  isActive: boolean = true
}

export const timePunchValidationSchema = yup.object({
  timePunchDateTime: yup
    .string()
    // .min(23, 'Time Punch Date/Time is required.') Why is this limited to 23 characters? Isn't a time (string yyyy-mm-dd hh:mm MM) 19 characters?
    .required('Time Punch Date/Time is required.')
})
