import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField
} from '@mui/material'
import { CompanyDto, PersonDto, peopleValidationSchema } from 'dtos'
import { Formik, getIn } from 'formik'
import { useEffect, useState } from 'react'
import * as controllers from 'controllers'

interface PersonAddEditDialogProps {
  initialValues: PersonDto
  loading: boolean
  onClose: () => void
  onSave: (values: PersonDto) => void
  open: boolean
}

export default function PersonAddEditDialog({ initialValues, loading, onClose, onSave, open }: PersonAddEditDialogProps) {
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [companyId, setCompanyId] = useState<string>('')
  const [useExistingCompany, setUseExistingCompany] = useState<boolean>(true)

  useEffect(() => {
    setCompanyId(initialValues?.company?.id ?? '')
  }, [initialValues])

  useEffect(() => {
    setUseExistingCompany(true)
  }, [open])

  useEffect(() => {
    controllers.getCompanies({ page: 0, pageSize: 2000, statuses: ['isActive'] }).then(response => {
      setCompanies(response.value)
    })
  }, [])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={peopleValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Person</DialogTitle>

                  <Grid item xs={12} container spacing={1} alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={loading || isSubmitting}
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label='First Name'
                        name='firstName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName || ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={loading || isSubmitting}
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label='Last Name'
                        name='lastName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName || ''}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container spacing={1} alignItems='center'>
                    {useExistingCompany ? (
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={loading || isSubmitting}
                          disableListWrap
                          getOptionLabel={option => option.name}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('company', value)
                          }}
                          options={companies.map(company => ({ id: company.id, name: company.name }))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(touched.company && errors.company)}
                              helperText={touched.company && errors.company}
                              label='Company'
                              name='company'
                            />
                          )}
                          value={values.company ? { id: values.company.id, name: values.company.name } : null}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <TextField
                          disabled={loading || isSubmitting}
                          error={Boolean(getIn(touched, 'company.name') && getIn(errors, 'company.name'))}
                          helperText={getIn(touched, 'company.name') && getIn(errors, 'company.name')}
                          fullWidth
                          label='Company Name'
                          name='company.name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          value={values.company?.name || ''}
                        />
                      </Grid>
                    )}

                    <Link
                      onClick={() => {
                        if (!(loading || isSubmitting)) {
                          if (useExistingCompany) {
                            setFieldValue('company', new CompanyDto())
                            setFieldValue('person', null)
                          } else {
                            setFieldValue('company', null)
                          }
                          setUseExistingCompany(!useExistingCompany)
                        }
                      }}
                      sx={{ cursor: 'pointer', ml: 2 }}
                    >
                      {useExistingCompany ? 'Add New Company' : 'Use Existing Company'}
                    </Link>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('company', value)
                      }}
                      options={companies.map(company => ({ id: company.id, name: company.name }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.company && errors.company)}
                          helperText={touched.company && errors.company}
                          label='Company'
                          name='company'
                        />
                      )}
                      value={values.company ? { id: values.company.id, name: values.company.name } : null}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label='Title'
                      name='title'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label='Email'
                      name='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.textEmail && errors.textEmail)}
                      fullWidth
                      helperText={touched.textEmail && errors.textEmail}
                      label='Text Email'
                      name='textEmail'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.textEmail || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                      fullWidth
                      helperText={touched.mobilePhone && errors.mobilePhone}
                      label='Mobile Phone'
                      name='mobilePhone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobilePhone || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.workPhone && errors.workPhone)}
                      fullWidth
                      helperText={touched.workPhone && errors.workPhone}
                      label='Work Phone'
                      name='workPhone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.workPhone || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isRecruitingCandidate}
                          disabled={loading || isSubmitting}
                          name='isRecruitingCandidate'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isRecruitingCandidate}
                        />
                      }
                      label='Recruiting Candidate'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          disabled={loading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
