import { PermissionDto } from './permission'
import * as yup from 'yup'

export class PersonAccountSetupDto {
  allowUserToSignIn?: boolean = false
  email?: string = undefined
  id?: string = undefined
  isActive?: boolean = false
  isUser?: boolean = false
  permissions: PermissionDto[] = []
}

export const personAccountSetupValidationSchema = yup.object({
  id: yup.string(),
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .max(320, 'Email cannot be more than 320 characters.')
    .nullable(),
  isActive: yup.boolean(),
  allowUserToSignIn: yup.boolean()
})
