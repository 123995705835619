import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAuth } from 'context'
import { WorkOrderInvoiceDto, workOrderInvoiceValidationSchema } from 'dtos'
import { Formik } from 'formik'
import moment from 'moment'

interface WorkOrderInvoiceAddEditDialogProps {
  initialValues: WorkOrderInvoiceDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderInvoiceDto) => void
  open: boolean
}

export default function WorkOrderInvoiceAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open
}: WorkOrderInvoiceAddEditDialogProps) {
  const {
    permissions: { WORK_ORDER_INVOICES_EDIT }
  } = useAuth()

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={workOrderInvoiceValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Invoice</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.invoiceAmount && errors.invoiceAmount)}
                      fullWidth
                      helperText={touched.invoiceAmount && errors.invoiceAmount}
                      InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                      label='Invoice Amount'
                      name='invoiceAmount'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.invoiceAmount || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Needs To Be Sent'
                      name='needsToBeSentDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('needsToBeSentDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('needsToBeSentDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.needsToBeSentDate && errors.needsToBeSentDate),
                          helperText: touched.needsToBeSentDate && errors.needsToBeSentDate
                        }
                      }}
                      value={values.needsToBeSentDate ? moment(values.needsToBeSentDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.prePaidHours && errors.prePaidHours)}
                      fullWidth
                      helperText={touched.prePaidHours && errors.prePaidHours}
                      label='Prepaid Hours'
                      name='prePaidHours'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.prePaidHours || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting || !WORK_ORDER_INVOICES_EDIT}
                      label='Sent'
                      name='sentDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('sentDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('sentDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.sentDate && errors.sentDate),
                          helperText: touched.sentDate && errors.sentDate
                        }
                      }}
                      value={values.sentDate ? moment(values.sentDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting || !WORK_ORDER_INVOICES_EDIT}
                      error={Boolean(touched.quickBooksInvoiceNumber && errors.quickBooksInvoiceNumber)}
                      fullWidth
                      helperText={touched.quickBooksInvoiceNumber && errors.quickBooksInvoiceNumber}
                      label='Invoice #'
                      name='quickBooksInvoiceNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.quickBooksInvoiceNumber || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting || !WORK_ORDER_INVOICES_EDIT}
                      label='Received'
                      name='receivedDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('receivedDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('receivedDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.receivedDate && errors.receivedDate),
                          helperText: touched.receivedDate && errors.receivedDate
                        }
                      }}
                      value={values.receivedDate ? moment(values.receivedDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive || !WORK_ORDER_INVOICES_EDIT}
                          disabled={isLoading}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
