import { apiClient } from '_app'
import { ApiResponse, WorkOrderTypeDto } from 'dtos'
import { WorkOrderTypesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrderTypes = (parameters: WorkOrderTypesParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderTypeDto[]>>(
      '/api/work-order-types' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
