import { apiClient } from '_app'
import { ApiResponse, TextMessageDto } from 'dtos'
import { TextMessagesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getTextMessages = (parameters?: TextMessagesParameters) =>
  apiClient.get<ApiResponse<TextMessageDto[]>>('/api/text-messages' + URIQuery({ ...parameters })).then(res => res.data)

export const createTextMessage = (values: TextMessageDto) =>
  apiClient
    .post<ApiResponse<TextMessageDto>>('/api/text-messages', values)
    .then(res => res.data)
    .catch(reason => {
      throw new Error(reason)
    })
