import { Logout, PunchClock, TipsAndUpdates } from '@mui/icons-material'
import { Badge, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import Avatar from 'boring-avatars'
import FeedbackDialog from 'components/FeedbackDialog'
import { useAuth, useTimePunch } from 'context'
import { SyntheticEvent, useEffect, useState } from 'react'
import * as controllers from 'controllers'
import { enqueueSnackbar } from 'notistack'

//  Summary
//  Display's the current user's profile image with options for punching in/out, creating suggestions, and signing out.
export default function User() {
  const { user, signOut } = useAuth()
  const { status, punch, setLastPunch } = useTimePunch()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const onClickUserProfile = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseUserProfileMenu = () => {
    setAnchorEl(null)
  }

  const onClickPunch = () => {
    const now = new Date()
    punch()
    setLastPunch(now)
    onCloseUserProfileMenu()
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        onCloseUserProfileMenu()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [anchorEl])

  const [isSendingSuggestion, setIsSendingSuggestion] = useState<boolean>(false)
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false)

  return (
    <>
      <FeedbackDialog
        isSendingSuggestion={isSendingSuggestion}
        open={feedbackDialogOpen}
        onClose={() => {
          setFeedbackDialogOpen(false)
        }}
        onSave={values => {
          setIsSendingSuggestion(true)
          controllers
            .createSuggestion(values)
            .then(() => {
              enqueueSnackbar('Suggestion Sent Successfully!', { variant: 'success' })
              setFeedbackDialogOpen(false)
            })
            .finally(() => {
              setIsSendingSuggestion(false)
            })
        }}
      />

      <Grid container alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <Typography color='primary'>
            {user?.firstName} {user?.lastName}
          </Typography>
        </Grid>

        <Grid item xs='auto' onClick={onClickUserProfile} sx={{ cursor: 'pointer' }}>
          <Avatar
            size='40'
            square={false}
            name={user?.firstName + ' ' + user?.lastName}
            variant='beam'
            colors={['#212640', '#D9C7A7', '#BFA380', '#8C6751', '#593E3C']}
          />

          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent=' '
            overlap='circular'
            sx={{
              cursor: 'pointer',
              zIndex: '1115',
              bottom: '8px',
              right: '3px',
              '& .MuiBadge-badge': {
                color: '#000',
                backgroundColor: status === 'in' ? '#00e676' : '#d32f2f'
              }
            }}
            variant='dot'
          />
        </Grid>
      </Grid>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
        <MenuItem dense onClick={onClickPunch}>
          <ListItemText primaryTypographyProps={{ color: 'primary', fontSize: '10pt', fontWeight: 'bold', marginRight: 1 }}>
            Punch {status === 'in' ? 'Out' : 'In'}
          </ListItemText>

          <ListItemIcon>
            <PunchClock color='primary' />
          </ListItemIcon>
        </MenuItem>

        <Divider />

        <MenuItem
          dense
          onClick={() => {
            setFeedbackDialogOpen(true)
          }}
        >
          <ListItemText primaryTypographyProps={{ color: 'primary', fontSize: '10pt', fontWeight: 'bold', marginRight: 1 }}>
            Suggestion
          </ListItemText>

          <ListItemIcon>
            <TipsAndUpdates color='primary' />
          </ListItemIcon>
        </MenuItem>

        <Divider />

        <MenuItem onClick={signOut} dense>
          <ListItemText primaryTypographyProps={{ color: 'primary', fontSize: '10pt', fontWeight: 'bold', marginRight: 1 }}>
            Sign Out
          </ListItemText>

          <ListItemIcon>
            <Logout color='primary' />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  )
}
