import { Add, Edit } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import { VendorContractInformationAddEditDialog } from 'components'
import TablePagination from 'components/table/TablePagination'
import * as controllers from 'controllers'
import { CompanyDto, VendorContractInformationDto, VendorServiceTypeDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { VendorContractInformationParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { formatCurrency, formatDate, idIsNotNullUndefinedOrNew, useDebounce } from 'utils'

interface CompanyVendorContractInformationCardProps {
  company: CompanyDto
}

export default function CompanyVendorContractInformationCard({ company }: CompanyVendorContractInformationCardProps) {
  const [initialValues, setInitialValues] = useState<VendorContractInformationDto>(new VendorContractInformationDto())
  const [isGettingVendorContractInformation, setIsGettingVendorContractInformation] = useState<boolean>(false)
  const [isSavingVendorContractInformation, setIsSavingVendorContractInformation] = useState<boolean>(false)
  const [vendorContractInformationAddeditDialogOpen, setVendorContractInformationAddeditDialogOpen] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [vendorContractInformation, setVendorContractInformation] = useState<VendorContractInformationDto[]>([])
  const [vendorServiceTypes, setVendorServiceTypes] = useState<VendorServiceTypeDto[]>([])
  const [parameters, setParameters] = useState<VendorContractInformationParameters>({
    page: 0,
    pageSize: 10,
    order: 'asc',
    orderBy: 'vendorServiceType'
  })

  useEffect(() => {
    controllers.getVendorServiceTypes().then(response => {
      setVendorServiceTypes(response.value)
    })
  }, [])

  useEffect(() => {
    if (company && idIsNotNullUndefinedOrNew(company)) {
      getVendorContractInformation(parameters, company)
    }
  }, [parameters])

  const getVendorContractInformation = useDebounce((parameters: VendorContractInformationParameters, company: CompanyDto) => {
    setIsGettingVendorContractInformation(true)
    controllers
      .getVendorContractInformation(parameters, company.id!)
      .then(response => {
        setVendorContractInformation(response.value)
        setTotalCount(response.totalCount!)
      })
      .finally(() => {
        setIsGettingVendorContractInformation(false)
      })
  }, 300)

  return (
    <>
      <VendorContractInformationAddEditDialog
        initialValues={initialValues}
        loading={isSavingVendorContractInformation}
        onClose={() => {
          setVendorContractInformationAddeditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id
            ? controllers.updateVendorContractInformation
            : controllers.createVendorContractInformation

          setIsSavingVendorContractInformation(true)
          requestMethod({ ...values, company })
            .then(() => {
              enqueueSnackbar('Vendor Contract Information Saved Successfully!', { variant: 'success' })
              getVendorContractInformation()
              setVendorContractInformationAddeditDialogOpen(false)
            })
            .finally(() => {
              setIsSavingVendorContractInformation(false)
            })
        }}
        open={vendorContractInformationAddeditDialogOpen}
        vendorServiceTypes={vendorServiceTypes}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Vendor Contract Information
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  onClick={() => {
                    setInitialValues(new VendorContractInformationDto())
                    setVendorContractInformationAddeditDialogOpen(true)
                  }}
                  variant='text'
                >
                  ADD&nbsp;
                  <Add fontSize='small' />
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sortDirection={parameters.orderBy === 'vendorServiceType' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'vendorServiceType'}
                            direction={parameters.orderBy === 'vendorServiceType' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order:
                                  parameters.orderBy === 'vendorServiceType' && parameters.order === 'asc' ? 'desc' : 'asc',
                                orderBy: 'vendorServiceType'
                              })
                            }}
                          >
                            {/* Vendor Service Type */}
                            Type
                          </TableSortLabel>
                        </TableCell>

                        <TableCell sortDirection={parameters.orderBy === 'account' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'account'}
                            direction={parameters.orderBy === 'account' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order: parameters.orderBy === 'account' && parameters.order === 'asc' ? 'desc' : 'asc',
                                orderBy: 'account'
                              })
                            }}
                          >
                            Account
                          </TableSortLabel>
                        </TableCell>

                        <TableCell sortDirection={parameters.orderBy === 'serviceDescription' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'serviceDescription'}
                            direction={parameters.orderBy === 'serviceDescription' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order:
                                  parameters.orderBy === 'serviceDescription' && parameters.order === 'asc' ? 'desc' : 'asc',
                                orderBy: 'serviceDescription'
                              })
                            }}
                          >
                            Description
                          </TableSortLabel>
                        </TableCell>

                        <TableCell sortDirection={parameters.orderBy === 'annualBudget' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'annualBudget'}
                            direction={parameters.orderBy === 'annualBudget' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order: parameters.orderBy === 'annualBudget' && parameters.order === 'asc' ? 'desc' : 'asc',
                                orderBy: 'annualBudget'
                              })
                            }}
                          >
                            {/* Annual Budget */}
                            Budget
                          </TableSortLabel>
                        </TableCell>

                        <TableCell sortDirection={parameters.orderBy === 'billingFrequency' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'billingFrequency'}
                            direction={parameters.orderBy === 'billingFrequency' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order: parameters.orderBy === 'billingFrequency' && parameters.order === 'asc' ? 'desc' : 'asc',
                                orderBy: 'billingFrequency'
                              })
                            }}
                          >
                            {/* Billing Frequency */}
                            Frequency
                          </TableSortLabel>
                        </TableCell>

                        <TableCell
                          sortDirection={parameters.orderBy === 'billingAmountPerFrequency' ? parameters.order : false}
                        >
                          <TableSortLabel
                            active={parameters.orderBy == 'billingAmountPerFrequency'}
                            direction={parameters.orderBy === 'billingAmountPerFrequency' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order:
                                  parameters.orderBy === 'billingAmountPerFrequency' && parameters.order === 'asc'
                                    ? 'desc'
                                    : 'asc',
                                orderBy: 'billingAmountPerFrequency'
                              })
                            }}
                          >
                            {/* Billing Amount Per Frequency */}Amount
                          </TableSortLabel>
                        </TableCell>

                        <TableCell sortDirection={parameters.orderBy === 'contractExpirationDate' ? parameters.order : false}>
                          <TableSortLabel
                            active={parameters.orderBy == 'contractExpirationDate'}
                            direction={parameters.orderBy === 'contractExpirationDate' ? parameters.order : undefined}
                            onClick={() => {
                              setParameters({
                                ...parameters,
                                order:
                                  parameters.orderBy === 'contractExpirationDate' && parameters.order === 'asc'
                                    ? 'desc'
                                    : 'asc',
                                orderBy: 'contractExpirationDate'
                              })
                            }}
                          >
                            {/* Contract Expiration Date */}
                            Expires
                          </TableSortLabel>
                        </TableCell>

                        {/* <TableCell sortDirection={parameters.orderBy === 'notes' ? parameters.order : false}>
                        <TableSortLabel
                          active={parameters.orderBy == 'notes'}
                          direction={parameters.orderBy === 'notes' ? parameters.order : undefined}
                          onClick={() => {
                            setParameters({
                              ...parameters,
                              order: parameters.orderBy === 'notes' && parameters.order === 'asc' ? 'desc' : 'asc',
                              orderBy: 'notes'
                            })
                          }}
                        >
                          Notes
                        </TableSortLabel>
                      </TableCell> */}

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {vendorContractInformation.map(vendorContractInformation => (
                        <TableRow key={vendorContractInformation.id}>
                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.vendorServiceType?.name}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.account}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.serviceDescription}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.annualBudget
                              ? formatCurrency(vendorContractInformation.annualBudget)
                              : ''}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.billingFrequency}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {vendorContractInformation.billingAmountPerFrequency
                              ? formatCurrency(vendorContractInformation.billingAmountPerFrequency)
                              : ''}
                          </TableCell>

                          <TableCell size='small' variant='body'>
                            {formatDate(vendorContractInformation.contractExpirationDate)}
                          </TableCell>

                          {/* <TableCell>{vendorContractInformation.notes}</TableCell> */}

                          <TableCell padding='checkbox'>
                            <Button
                              onClick={() => {
                                setInitialValues(vendorContractInformation)
                                setVendorContractInformationAddeditDialogOpen(true)
                              }}
                              variant='text'
                            >
                              EDIT&nbsp;
                              <Edit fontSize='small' />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          component={'td'}
                          pagination={{
                            count: totalCount,
                            page: parameters.page,
                            pageSize: parameters.pageSize,
                            onPageChange: (page: number) => {
                              setParameters({ ...parameters, page })
                            },
                            onPageSizeChange: (pageSize: number) => {
                              setParameters({ ...parameters, pageSize })
                            }
                          }}
                          rowsPerPageOptions={[5, 10, 25]}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
