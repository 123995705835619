import { apiClient } from '_app'
import {
  ApiResponse,
  PersonAccountSetupDto,
  PersonDto,
  PersonHumanResourcesDto
} from 'dtos'
import { PeopleParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getPeople = (parameters: PeopleParameters) =>
  apiClient
    .get<ApiResponse<PersonDto[]>>('/api/people' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getPersonById = (id: string) =>
  apiClient
    .get<ApiResponse<PersonDto>>('/api/people/' + encodeURIComponent(id))
    .then(({ data }) => data)

export const createPerson = (values: PersonDto) =>
  apiClient.post<ApiResponse<PersonDto>>('/api/people', values).then(res => res.data)

export const updatePerson = (values: PersonDto) =>
  apiClient.put<ApiResponse<PersonDto>>('/api/people', values).then(res => res.data)

export const getAccountSetup = (id: string) =>
  apiClient
    .get<ApiResponse<PersonAccountSetupDto>>(
      '/api/people/' + encodeURIComponent(id) + '/account-setup'
    )
    .then(res => res.data)

export const updateAccountSetup = (id: string, values: PersonAccountSetupDto) =>
  apiClient
    .put<ApiResponse<PersonAccountSetupDto>>(
      '/api/people/' + encodeURIComponent(id) + '/account-setup',
      values
    )
    .then(res => res.data)

export const getHumanResources = (id: string) =>
  apiClient
    .get<ApiResponse<PersonHumanResourcesDto>>(
      '/api/people/' + encodeURIComponent(id) + '/human-resources'
    )
    .then(res => res.data)

export const updateHumanResources = (id: string, values: PersonHumanResourcesDto) =>
  apiClient
    .put<ApiResponse<PersonHumanResourcesDto>>(
      '/api/people/' + encodeURIComponent(id) + '/human-resources',
      values
    )
    .then(res => res.data)
