import { Add, AppRegistration, SearchOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  List,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { useAuth, useLayout } from 'context'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'hooks'
import { useEffect, useState } from 'react'
import { AssetDto, PersonDto } from 'dtos'
import { AssetsParameters } from 'parameters'
import { useDebounce } from 'utils'
import * as controllers from 'controllers'
import AssetAddEditDialog from './id/AssetAddEditDialog'
import { enqueueSnackbar } from 'notistack'
import AssetHeroCard from './AssetHeroCard'
import { ExplanationAccordion } from 'components/_template/accordion'
import TablePagination from 'components/table/TablePagination'

export default function Assets() {
  const {
    user,
    permissions: { USERS_ADD_EDIT }
  } = useAuth()
  const navigate = useNavigate()
  const query = useQuery()

  const defaultParameters: AssetsParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    teamMemberId: user?.id
    // order: 'asc',
    // orderBy: 'assetNumber'
  }

  const { setIsLayoutLoading, setTitle } = useLayout()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [assets, setAssets] = useState<AssetDto[]>([])
  const [asset, setAsset] = useState<AssetDto>(new AssetDto())
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])

  const [isCreatingAsset, setIsCreatingAsset] = useState<boolean>(false)
  const [assetAddEditDialogOpen, setAssetAddEditDialogOpen] = useState<boolean>(false)

  const [parameters, setParameters] = useState<AssetsParameters>(defaultParameters)

  const getAssets = useDebounce((parameters: AssetsParameters) => {
    setIsLayoutLoading(true)
    controllers
      .getAssets(parameters)
      .then(res => {
        setAssets(res.value)
        setTotalCount(res.totalCount ?? 0)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  useEffect(() => {
    controllers
      .getPeople({
        page: 0,
        pageSize: 1000,
        permission: 'TASKS',
        statuses: ['isUser']
      })
      .then(res => {
        setTeamMembers(res.value)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    setTitle('Assets')
  }, [setTitle])

  useEffect(() => {
    getAssets(parameters)
  }, [parameters])

  return (
    <>
      <AssetAddEditDialog
        initialValues={asset}
        isLoading={isCreatingAsset}
        onClose={() => {
          setAssetAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingAsset(true)
          controllers
            .createAsset(values)
            .then(() => {
              getAssets(parameters)
              setAssetAddEditDialogOpen(false)
              enqueueSnackbar('Asset Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsCreatingAsset(false)
            })
        }}
        open={assetAddEditDialogOpen}
      />

      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Assets ({totalCount})
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm='auto'>
                  <TextField
                    data-search
                    label='Search'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <SearchOutlined fontSize='small' />{' '}
                        </InputAdornment>
                      )
                    }}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        search: e.target.value ?? undefined
                      })
                    }}
                    size='small'
                    sx={{ minWidth: '200px', maxWidth: '200px' }}
                    type='search'
                  />
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Team Member'
                    onChange={e => {
                      setParameters({ ...parameters, teamMemberId: e.target.value ?? undefined })
                    }}
                    select
                    SelectProps={{ displayEmpty: true }}
                    sx={{ minWidth: '200px', maxWidth: '200px' }}
                    value={parameters.teamMemberId || ''}
                  >
                    <MenuItem value={''}>All Team Members</MenuItem>
                    {teamMembers
                      .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                      .map(teamMember => (
                        <MenuItem key={teamMember.id} value={teamMember.id}>
                          {teamMember.firstName} {teamMember.lastName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<Add />}
                    onClick={() => {
                      setAsset(new AssetDto())
                      setAssetAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    ADD ASSET
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {assets.map(asset => (
          <Grid item xs={12} key={asset.id}>
            <AssetHeroCard asset={asset}>
              <Box display='flex' justifyContent='flex-end' width='100%'>
                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<AppRegistration />}
                    onClick={() => {
                      navigate(`/assets/${asset.id}`)
                    }}
                    variant='text'
                  >
                    DETAILS
                  </Button>
                </Grid>
              </Box>
            </AssetHeroCard>
          </Grid>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>Assets</ExplanationAccordion>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent='flex-end'>
            <TablePagination
              pagination={{
                count: totalCount,
                onPageChange: (page: number) => {
                  setParameters({ ...parameters, page })
                },
                onPageSizeChange: (pageSize: number) => {
                  setParameters({ ...parameters, pageSize })
                },
                page: parameters.page,
                pageSize: parameters.pageSize
              }}
            />

            {/* <Grid item>Order By:</Grid> */}

            {/* <Grid item>
              <TextField
                InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                onChange={e => {
                  setParameters({ ...parameters, name: e.target.value })
                }}
                select
                size='small'
                value={parameters.name}
                variant='standard'
              >
                <MenuItem value='assetNumber'>Asset Number</MenuItem>
                <MenuItem value='assetDescription'>Description</MenuItem>
                <MenuItem value='assetSerialNumber'>Serial Number</MenuItem>
                <MenuItem value='assetNotes'>Notes</MenuItem>
                <MenuItem value='teamMember'>Team Member</MenuItem>
                <MenuItem value='location'>Location</MenuItem>
              </TextField>
            </Grid> */}

            {/* <Grid item xs={12}>
          <TablePagination
            component='div'
            count={totalCount}
            onPageChange={(_, page: number) => {
              setParameters({
                ...parameters,
                page
              })
            }}
            onRowsPerPageChange={e => {
              setParameters({
                ...parameters,
                pageSize: e.target.value as unknown as number
              })
            }}
            page={parameters.page}
            rowsPerPage={parameters.pageSize}
            labelRowsPerPage={
              <Grid container spacing={1}>
                <Grid item>Order By:</Grid>

                <Grid item>
                  <TextField
                    InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                    onChange={e => {
                      setParameters({ ...parameters, orderBy: e.target.value })
                    }}
                    select
                    size='small'
                    value={parameters.}
                    variant='standard'
                  >
                    <MenuItem value='targetCompany'>Target Company</MenuItem>

                    <MenuItem value='targetPerson'>Target Person</MenuItem>

                    <MenuItem value='opportunityDescription'>Description</MenuItem>

                    <MenuItem value='createdDateTime'>Last Activity Date</MenuItem>

                    <MenuItem value='opportunityActivityType'>Last Activity Type</MenuItem>

                    <MenuItem value='activityNote'>Last Activity Note</MenuItem>

                    <MenuItem value='nextActivityDate'>Next Activity Date</MenuItem>

                    <MenuItem value='opportunityNextActivityType'>Next Activity Type</MenuItem>
                  </TextField>
                </Grid>

               
              </Grid>
            }
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '25', value: 25 },
              { label: '50', value: 50 },
              { label: '100', value: 100 }
            ]}
            SelectProps={{ fullWidth: false }}
          />
        </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
