// export function hhMmToDecimal(hhMmString: string): number {
//   if (hhMmString.split(':').length <= 1) {
//     return 0
//   }

//   let minutes = parseInt(hhMmString.split(':')[0]) * 60 + parseInt(hhMmString.split(':')[1])

//   return parseFloat((minutes / 60).toFixed(2))
// }

export function hhMmToDecimal(hrsToDecimalString: string): number {
  if (!hrsToDecimalString.includes(':')) {
    const decimalValue = parseFloat(hrsToDecimalString)
    if (isNaN(decimalValue) || decimalValue < 0 || decimalValue > 24) {
      throw new Error('Invalid decimal format')
    }

    const decimalParts = hrsToDecimalString.split('.')

    if (decimalParts.length > 2 || (decimalParts[1] && isNaN(Number(decimalParts[1])))) {
      throw new Error('Invalid decimal format')
    }

    return decimalValue
  }

  const [hours, minutes] = hrsToDecimalString.split(':').map(Number)
  if (isNaN(hours) || isNaN(minutes) || hours > 24 || minutes > 59 || hours < 0 || minutes < 0) {
    throw new Error('Invalid time format')
  }

  if (hours === 24 && minutes > 0) {
    throw new Error('Invalid time format')
  }

  const timeParts = hrsToDecimalString.split(':')

  if (timeParts.length > 2 || (timeParts[1] && isNaN(Number(timeParts[1])))) {
    throw new Error('Invalid time format')
  }

  let totalMinutes = hours * 60 + minutes
  return parseFloat((totalMinutes / 60).toFixed(2))
}
