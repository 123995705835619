import Grid from '@mui/material/Grid'
import { useLayout } from 'context'
import { ExplanationAccordion } from 'components/_template/accordion'
import * as controllers from 'controllers'
import { CampaignDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CampaignDetailsCard from './CampaignDetailsCard'

export default function CampaignId() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { id } = useParams()
  const [campaign, setCampaign] = useState<CampaignDto>(new CampaignDto())

  useEffect(() => {
    setTitle('Campaign Details')
  }, [])

  useEffect(() => {
    if (id) {
      // setIsLayoutLoading(true)
      controllers
        .getCampaignById(id)
        .then(response => {
          setCampaign(response.value)
        })
        .finally(() => {
          // setIsLayoutLoading(false)
        })
    }
  }, [id])

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <CampaignDetailsCard campaign={campaign} setCampaign={setCampaign} />
      </Grid>

      <Grid item xs={12}>
        <ExplanationAccordion>
          The Campaign Name can be up to 200 characters. The Campaign Description can be up to 2,000 characters and should
          outline purpose of the campaign and expected results.||The Start Date and End Date do not have any impact on when a
          campaign can be assigned to a company. The purpose of the date range is to help with analysis of the data created by
          tracking campaigns.||Companies can be associated with multiple campaigns.
        </ExplanationAccordion>
      </Grid>
    </Grid>
  )
}
