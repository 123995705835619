import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { PersonDto } from 'dtos'

export class InventoryDto {
  id?: string = undefined
  name: string = ''
  inStock?: string = undefined
  uniqueItems?: string = undefined
  outOfStock?: string = undefined
  shouldHaveRecieved?: string = undefined
  description?: string = undefined
  person?: PersonDto = undefined
}

export const inventoryValidationSchema = yup.object<InventoryDto>({
  id: yup.string().nullable(),
  name: yup
    .string()
    .max(200, 'Inventory Name cannot be more than 200 characters.')
    .required('Inventory Name is required.'),
  inStock: yup
    .number()
    .min(0.0, 'In stock cannot be less than $0.00.')
    .max(999_999_999.99, 'In stock cannot be more than $999,999,999.99.')
    .required(),
  outOfStock: yup
    .number()
    .min(0.0, 'out of stock cannot be less than $0.00.')
    .max(999_999_999.99, 'out of stock cannot be more than $999,999,999.99.')
    .required(),
  uniqueItems: yup
    .number()
    .min(0.0, 'Unique items cannot be less than $0.00.')
    .max(999_999_999.99, 'Unique items cannot be more than $999,999,999.99.')
    .required(),
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .required('Inventory Description is required.'),
  person: yup.object().nullable()
})
