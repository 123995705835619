import * as yup from 'yup'
import {
  maxDecimal194Message,
  maxDecimal194Value,
  maxString200Message,
  maxString200Value,
  maxString50Message,
  maxString50Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  minTextFieldMessage,
  requiredFieldMessage
} from '../constants'
import { OpportunityDto } from './opportunity'
import { OpportunityActivityTypeDto } from './opportunityActivityType'
import { OpportunityNextActivityTypeDto } from './opportunityNextActivityType'
import { OpportunityProbabilityDto } from './opportunityProbability'
import { OpportunityStatusDto } from './opportunityStatus'
import { PersonDto } from './person'

export class OpportunityActivityDto {
  id?: string = undefined
  activityDate?: string = new Date().toISOString().split('T')[0]
  activityNote?: string = undefined
  nextActivityDate?: string = undefined
  projectedCloseDate?: string = undefined
  projectedSaleAmount?: number = undefined
  purchaseOrderNumber?: string = undefined
  reasonForLosingOpportunity?: string = undefined

  opportunity?: OpportunityDto = undefined
  opportunityActivityType?: OpportunityActivityTypeDto = undefined
  opportunityNextActivityType?: OpportunityNextActivityTypeDto = undefined
  opportunityProbability?: OpportunityProbabilityDto = undefined
  opportunityStatus?: OpportunityStatusDto = undefined
  createdBy?: PersonDto = undefined
  createdDateTime?: string = undefined
}

export const opportunityActivityValidationSchema = yup.object({
  activityDate: yup.date().required(requiredFieldMessage),
  opportunityActivityType: yup.object().required(requiredFieldMessage),
  activityNote: yup.string().max(maxString200Value, maxString200Message).required(requiredFieldMessage),
  opportunityStatus: yup.object().required(requiredFieldMessage),
  nextActivityDate: yup.date().when('opportunityStatus.specialIdentifier', (values, schema) => {
    const specialIdentifier = values[0]

    if (
      'OPPORTUNITY_STATUSES_OPPORTUNITY' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_SEND_PROPOSAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_APPROVAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_PAYMENT' === specialIdentifier
    ) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  opportunityNextActivityType: yup.object().when('opportunityStatus.specialIdentifier', (values, schema) => {
    const specialIdentifier = values[0]

    if (
      'OPPORTUNITY_STATUSES_OPPORTUNITY' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_SEND_PROPOSAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_APPROVAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_PAYMENT' === specialIdentifier
    ) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  opportunityProbability: yup.object().when('opportunityStatus.specialIdentifier', (values, schema) => {
    const specialIdentifier = values[0]

    if (
      'OPPORTUNITY_STATUSES_SEND_PROPOSAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_APPROVAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_PAYMENT' === specialIdentifier
    ) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  projectedCloseDate: yup.date().when('opportunityStatus.specialIdentifier', (values, schema) => {
    const specialIdentifier = values[0]

    if (
      'OPPORTUNITY_STATUSES_SEND_PROPOSAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_APPROVAL' === specialIdentifier ||
      'OPPORTUNITY_STATUSES_GET_PAYMENT' === specialIdentifier
    ) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  projectedSaleAmount: yup
    .number()
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
    .when('opportunityStatus.specialIdentifier', (values, schema) => {
      const specialIdentifier = values[0]

      if (
        'OPPORTUNITY_STATUSES_SEND_PROPOSAL' === specialIdentifier ||
        'OPPORTUNITY_STATUSES_GET_APPROVAL' === specialIdentifier ||
        'OPPORTUNITY_STATUSES_GET_PAYMENT' === specialIdentifier
      ) {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  reasonForLosingOpportunity: yup
    .string()
    .min(1, minTextFieldMessage(1))
    .max(maxString50Value, maxString50Message)
    .when('opportunityStatus.specialIdentifier', (values, schema) => {
      const specialIdentifier = values[0]

      if ('OPPORTUNITY_STATUS_LOST' === specialIdentifier) {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    })
})
