import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField
} from '@mui/material'
import { Chip } from 'components/chips'
import * as controllers from 'controllers'
import { CampaignDto, CompanyDto, companyValidationSchema } from 'dtos'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'

interface CompanyAddEditDialogProps {
  initialValues: CompanyDto
  loading: boolean
  onClose: () => void
  onSave: (values: CompanyDto) => void
  open: boolean
}

export default function CompanyAddEditDialog({ initialValues, loading, onClose, onSave, open }: CompanyAddEditDialogProps) {
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([])

  useEffect(() => {
    controllers.getCampaigns({ page: 0, pageSize: 2000, statuses: ['isActive'] }).then(response => {
      setCampaigns(response.value)
    })
  }, [])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={companyValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Company</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.companyCode && errors.companyCode)}
                      fullWidth
                      helperText={touched.companyCode && errors.companyCode}
                      label='Company Code'
                      name='companyCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.companyCode || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.website && errors.website)}
                      fullWidth
                      helperText={touched.website && errors.website}
                      label='Website'
                      name='website'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.website || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id='campaigns-label'>Campaigns</InputLabel>
                      <Select
                        labelId='campaigns-label'
                        disabled={loading || isSubmitting}
                        multiple
                        onChange={(event: SelectChangeEvent<string[]>, _child: React.ReactNode) => {
                          if (Array.isArray(event.target.value)) {
                            setFieldValue(
                              'campaigns',
                              campaigns.filter(campaign => event.target.value.includes(campaign.id!))
                            )
                          }
                        }}
                        input={<OutlinedInput label='Campaigns' />}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map(campaignId => (
                              <Chip key={campaignId} variant='gray'>
                                {campaigns.filter(campaign => campaign.id === campaignId)[0]?.name ?? ''}
                              </Chip>
                            ))}
                          </Box>
                        )}
                        value={values.campaigns.map(campaign => campaign.id!)}
                      >
                        {campaigns
                          .filter(
                            campaign =>
                              // TODO: is this how we want to do this throughout the app, or should we update the parameters to the API?
                              initialValues.campaigns.some(selectedCampaign => selectedCampaign.id == campaign.id) ||
                              campaign.isActive
                          )
                          .map(campaign => campaign.id)
                          .map(campaignId => (
                            <MenuItem key={campaignId} value={campaignId}>
                              <Checkbox checked={values.campaigns.map(campaign => campaign.id).indexOf(campaignId) > -1} />
                              <ListItemText primary={campaigns.filter(campaign => campaign.id === campaignId)[0]?.name ?? ''} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVendor}
                          disabled={loading || isSubmitting}
                          name='isVendor'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isVendor}
                        />
                      }
                      label='Vendor'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          disabled={loading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
