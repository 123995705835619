import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { Chip, ChipStatusSelect } from 'components/chips'
import TablePagination from 'components/table/TablePagination'
import * as controllers from 'controllers'
import { PersonDto, TextMessageDto } from 'dtos'
import { TextMessagesParameters } from 'parameters'
import React, { useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { formatDate, formatDateTime, formatDateValue, useDebounce } from 'utils'

interface TextMessagesCardProps {
  person: PersonDto
}

export default function TextMessagesCard({ person }: TextMessagesCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [textMessages, setTextMessages] = useState<TextMessageDto[]>()
  const [isGettingTextMessages, setIsGettingTextMessages] = useState<boolean>(false)
  const [parameters, setParameters] = useState<TextMessagesParameters>({
    page: 0,
    pageSize: 5,
    statuses: ['includeReceived', 'includeSent'],
    startDate: formatDateValue(new Date())
  })
  const [totalCount, setTotalCount] = useState<number>(0)

  useEffect(() => {
    if (person && person.id) {
      parameters.recipientId = person.id
      parameters.senderId = person.id
      getTextMessages(parameters)
    }
  }, [parameters, person])

  const getTextMessages = useDebounce((parameters: TextMessagesParameters) => {
    setIsGettingTextMessages(true)
    controllers
      .getTextMessages(parameters)
      .then(response => {
        setTextMessages(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingTextMessages(false)
      })
  }, 300)

  const onClickTodayShortcut = () => {
    setParameters({
      ...parameters,
      startDate: new Date().toISOString().split('T')[0],
      endDate: undefined
    })

    handleClose()
  }

  const onClickYesterdayShortcut = () => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    setParameters({
      ...parameters,
      startDate: yesterday.toISOString().split('T')[0],
      endDate: undefined
    })

    handleClose()
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm='auto'>
              <Typography fontWeight='bold' variant='h5'>
                Text Messages
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12}>
              <ChipStatusSelect
                label='Options'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    statuses: value
                  })
                }}
                options={[
                  {
                    label: 'Received',
                    color: 'blue',
                    value: 'includeReceived'
                    // count: 0
                  },
                  {
                    label: 'Sent',
                    color: 'green',
                    value: 'includeSent'
                    // count: 0
                  }
                ]}
                value={parameters.statuses ?? []}
              />
            </Grid>
            <Grid container item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label='Start Date'
                onChange={e => {
                  setParameters({
                    ...parameters,
                    startDate: e.target.value,
                    endDate: undefined
                  })
                }}
                sx={{ minWidth: 200 }}
                type='date'
                value={parameters.startDate || ''}
              />
            </Grid>

            <Grid container item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: parameters.startDate } }}
                label='End Date'
                onChange={e => {
                  setParameters({ ...parameters, endDate: e.target.value })
                }}
                sx={{ minWidth: 200 }}
                type='date'
                value={parameters.endDate || ''}
                disabled={!parameters.startDate}
              />
            </Grid>

            <Grid container item xs={12}>
              <Button variant='text' onClick={handleClick}>
                QUICK FILTERS...
              </Button>
            </Grid>

            <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
              <MenuItem onClick={onClickTodayShortcut}>Today (Default)</MenuItem>

              <MenuItem onClick={onClickYesterdayShortcut}>Yesterday</MenuItem>
            </Menu>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {isGettingTextMessages && (
            <Grid item container alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
              <Grid item>
                <CircularProgress color='primary' size={50} />
              </Grid>
            </Grid>
          )}

          {!isGettingTextMessages &&
            textMessages?.map((textMessage, index) => (
              <Grid item xs={12} key={'textMessage' + index}>
                <Card variant='outlined'>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                          <Grid item xs={12} sm='auto'>
                            <Typography variant='h6' sx={{ pr: 1 }}>
                              {formatDateTime(textMessage.createdDateTime!)}
                            </Typography>
                          </Grid>

                          {textMessage?.recipient?.id == person.id && (
                            <Grid item>
                              <Chip variant='blue'>Received</Chip>
                            </Grid>
                          )}

                          {textMessage?.createdBy?.id == person.id && (
                            <Grid item>
                              <Chip variant='green'>Sent</Chip>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body2'>Recipient</Typography>

                        <Typography color='primary' variant='body1'>
                          <Link component={ReactRouterLink} to={'/people/' + textMessage.recipient?.id}>
                            {textMessage.recipient?.firstName} {textMessage.recipient?.lastName}
                          </Link>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body2'>Sender</Typography>

                        <Typography color='primary' variant='body1'>
                          <Link component={ReactRouterLink} to={'/people/' + textMessage.createdBy?.id}>
                            {textMessage.createdBy?.firstName} {textMessage.createdBy?.lastName}
                          </Link>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='body2'>Message</Typography>

                        <Typography color='primary' variant='body1'>
                          {textMessage.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </CardContent>

      <TablePagination
        pagination={{
          count: totalCount,
          page: parameters.page,
          pageSize: parameters.pageSize,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          }
        }}
        labelRowsPerPage='Cards per page:'
      />
    </Card>
  )
}
