import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import TablePagination from 'components/table/TablePagination'
import * as controllers from 'controllers'
import { CompanyDto, ProjectDto } from 'dtos'
import { ProjectsParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { idIsNotNullUndefinedOrNew, useDebounce } from 'utils'

interface CompanyProjectsCardProps {
  company: CompanyDto
}

export default function CompanyProjectsCard({ company }: CompanyProjectsCardProps) {
  const [projects, setProjects] = useState<ProjectDto[]>([])
  const [isGettingProjects, setIsGettingProjects] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [parameters, setParameters] = useState<ProjectsParameters>({
    page: 0,
    pageSize: 5,
    companyId: ''
  })

  useEffect(() => {
    if (company && idIsNotNullUndefinedOrNew(company)) {
      getProjects({ ...parameters, companyId: company.id })
    }
  }, [parameters, company])

  const getProjects = useDebounce((parameters: ProjectsParameters) => {
    setIsGettingProjects(true)
    controllers
      .getProjects(parameters)
      .then(response => {
        setProjects(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingProjects(false)
      })
  }, 300)

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Projects
                </Typography>
              </Grid>
            </Grid>

            {isGettingProjects && (
              <Grid item container alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                <Grid item>
                  <CircularProgress color='primary' size={50} />
                </Grid>
              </Grid>
            )}

            {!isGettingProjects &&
              projects.map(project => (
                <Grid item xs={12} key={project.id}>
                  <Card variant='outlined'>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                          <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                            <Grid item xs={12} sm='auto'>
                              <Typography variant='h6' sx={{ pr: 1 }}>
                                {project.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </CardContent>

        <TablePagination
          pagination={{
            count: totalCount,
            page: parameters.page,
            pageSize: parameters.pageSize,
            onPageChange: (page: number) => {
              setParameters({ ...parameters, page })
            },
            onPageSizeChange: (pageSize: number) => {
              setParameters({ ...parameters, pageSize })
            }
          }}
          labelRowsPerPage='Cards per page:'
        />
      </Card>
    </>
  )
}
