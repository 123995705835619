import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import * as controllers from 'controllers'
import {
  OpportunityActivityDto,
  OpportunityActivityTypeDto,
  OpportunityNextActivityTypeDto,
  OpportunityProbabilityDto,
  OpportunityStatusDto,
  opportunityActivityValidationSchema
} from 'dtos'
import { Formik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface OpportunityActivityAddDialogProps {
  isLoading?: boolean
  onClose: () => void
  onSave: (values: OpportunityActivityDto) => void
  open: boolean
  initialValues: OpportunityActivityDto
}

export default function OpportunityActivityAddDialog({
  isLoading,
  onClose,
  onSave,
  open,
  initialValues: initialValuesProp
}: OpportunityActivityAddDialogProps) {
  const [initialValues, setInitialValues] = useState<OpportunityActivityDto>(new OpportunityActivityDto())
  const [opportunityActivityTypes, setOpportunityActivityTypes] = useState<OpportunityActivityTypeDto[]>([])
  const [opportunityNextActivityTypes, setOpportunityNextActivityTypes] = useState<OpportunityNextActivityTypeDto[]>([])
  const [opportunityProbabilities, setOpportunityProbabilities] = useState<OpportunityProbabilityDto[]>([])
  const [opportunityStatuses, setOpportunityStatuses] = useState<OpportunityStatusDto[]>([])

  useEffect(() => {
    controllers.getOpportunityActivityTypes({ page: 0, pageSize: 2000 }).then(response => {
      setOpportunityActivityTypes(response.value)
    })

    controllers.getOpportunityNextActivityTypes({ page: 0, pageSize: 2000 }).then(response => {
      setOpportunityNextActivityTypes(response.value)
    })

    controllers.getOpportunityProbabilities({ page: 0, pageSize: 2000 }).then(response => {
      setOpportunityProbabilities(response.value)
    })

    controllers.getOpportunityStatuses({ page: 0, pageSize: 2000 }).then(response => {
      setOpportunityStatuses(response.value)
    })
  }, [])

  useEffect(() => {
    setInitialValues({
      ...new OpportunityActivityDto(),
      ...initialValuesProp,
      id: undefined,
      activityNote: '',
      nextActivityDate: '',
      opportunityNextActivityType: undefined
    })
  }, [open, initialValuesProp])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={opportunityActivityValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Activity</DialogTitle>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('opportunityActivityType', value)
                      }}
                      options={opportunityActivityTypes.map(opportunityActivityType => ({
                        id: opportunityActivityType.id,
                        name: opportunityActivityType.name
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.opportunityActivityType && errors.opportunityActivityType)}
                          helperText={touched.opportunityActivityType && errors.opportunityActivityType}
                          label='Activity Type'
                          name='opportunityActivityType'
                        />
                      )}
                      value={
                        values.opportunityActivityType
                          ? {
                              id: values.opportunityActivityType.id,
                              name: values.opportunityActivityType.name
                            }
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.activityNote && errors.activityNote)}
                      fullWidth
                      helperText={touched.activityNote && errors.activityNote}
                      label='Activity Note'
                      name='activityNote'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.activityNote}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name!}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('opportunityStatus', value)
                      }}
                      options={opportunityStatuses
                        .sort((a, b) => a.sequence! - b.sequence!)
                        .map(opportunityStatus => ({
                          id: opportunityStatus.id,
                          name: opportunityStatus.name,
                          specialIdentifier: opportunityStatus.specialIdentifier
                        }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.opportunityStatus && errors.opportunityStatus)}
                          helperText={touched.opportunityStatus && errors.opportunityStatus}
                          label='Status'
                          name='opportunityStatus'
                        />
                      )}
                      value={
                        values.opportunityStatus
                          ? {
                              id: values.opportunityStatus.id,
                              name: values.opportunityStatus.name,
                              specialIdentifier: values.opportunityStatus.specialIdentifier
                            }
                          : null
                      }
                    />
                  </Grid>

                  {[
                    'OPPORTUNITY_STATUSES_PROSPECT',
                    'OPPORTUNITY_STATUSES_OPPORTUNITY',
                    'OPPORTUNITY_STATUSES_SEND_PROPOSAL',
                    'OPPORTUNITY_STATUSES_GET_APPROVAL',
                    'OPPORTUNITY_STATUSES_GET_PAYMENT'
                  ].includes(values.opportunityStatus?.specialIdentifier!) && (
                    <>
                      <Grid item xs={12}>
                        <DatePicker
                          disabled={isLoading || isSubmitting}
                          label='Next Activity Date'
                          name='nextActivityDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('nextActivityDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('nextActivityDate', undefined)
                            }
                          }}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.nextActivityDate && errors.nextActivityDate),
                              helperText: touched.nextActivityDate && errors.nextActivityDate
                            }
                          }}
                          value={values.nextActivityDate ? moment(values.nextActivityDate) : null}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={isLoading || isSubmitting}
                          disableListWrap
                          getOptionLabel={option => option.name!}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('opportunityNextActivityType', value)
                          }}
                          options={opportunityNextActivityTypes.map(opportunityNextActivityType => ({
                            id: opportunityNextActivityType.id,
                            name: opportunityNextActivityType.name
                          }))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(touched.opportunityNextActivityType && errors.opportunityNextActivityType)}
                              helperText={touched.opportunityNextActivityType && errors.opportunityNextActivityType}
                              label='Next Activity Type'
                              name='opportunityNextActivityType'
                            />
                          )}
                          value={
                            values.opportunityNextActivityType
                              ? {
                                  id: values.opportunityNextActivityType.id,
                                  name: values.opportunityNextActivityType.name
                                }
                              : null
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {[
                    'OPPORTUNITY_STATUSES_SEND_PROPOSAL',
                    'OPPORTUNITY_STATUSES_GET_APPROVAL',
                    'OPPORTUNITY_STATUSES_GET_PAYMENT'
                  ].includes(values.opportunityStatus?.specialIdentifier!) && (
                    <>
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={isLoading || isSubmitting}
                          disableListWrap
                          getOptionLabel={option => option.name!}
                          onBlur={handleBlur}
                          onChange={(_e, value) => {
                            setFieldValue('opportunityProbability', value)
                          }}
                          options={opportunityProbabilities.map(opportunityProbability => ({
                            id: opportunityProbability.id,
                            name: opportunityProbability.name
                          }))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(touched.opportunityProbability && errors.opportunityProbability)}
                              helperText={touched.opportunityProbability && errors.opportunityProbability}
                              label='Probability of Closing Sale'
                              name='opportunityProbability'
                            />
                          )}
                          value={
                            values.opportunityProbability
                              ? {
                                  id: values.opportunityProbability.id,
                                  name: values.opportunityProbability.name
                                }
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DatePicker
                          disabled={isLoading || isSubmitting}
                          label='Projected Sold/Close Date'
                          name='projectedCloseDate'
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue('projectedCloseDate', newValue.format('yyyy-MM-DD'))
                            } else {
                              setFieldValue('projectedCloseDate', undefined)
                            }
                          }}
                          slotProps={{
                            textField: {
                              error: Boolean(touched.projectedCloseDate && errors.projectedCloseDate),
                              helperText: touched.projectedCloseDate && errors.projectedCloseDate
                            }
                          }}
                          value={values.projectedCloseDate ? moment(values.projectedCloseDate) : null}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={isLoading || isSubmitting}
                          error={Boolean(touched.projectedSaleAmount && errors.projectedSaleAmount)}
                          fullWidth
                          helperText={touched.projectedSaleAmount && errors.projectedSaleAmount}
                          label='Projected Sale Amount'
                          name='projectedSaleAmount'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>
                          }}
                          size='small'
                          type='number'
                          value={values.projectedSaleAmount}
                        />
                      </Grid>
                    </>
                  )}

                  {['OPPORTUNITY_STATUSES_SOLD'].includes(values.opportunityStatus?.specialIdentifier!) && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading || isSubmitting}
                        error={Boolean(touched.purchaseOrderNumber && errors.purchaseOrderNumber)}
                        fullWidth
                        helperText={touched.purchaseOrderNumber && errors.purchaseOrderNumber}
                        label='Purchase Authorization #'
                        name='purchaseOrderNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.purchaseOrderNumber}
                      />
                    </Grid>
                  )}

                  {['OPPORTUNITY_STATUSES_LOST'].includes(values.opportunityStatus?.specialIdentifier!) && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading || isSubmitting}
                        error={Boolean(touched.reasonForLosingOpportunity && errors.reasonForLosingOpportunity)}
                        fullWidth
                        helperText={touched.reasonForLosingOpportunity && errors.reasonForLosingOpportunity}
                        label='Reason for Losing Opportunity'
                        name='reasonForLosingOpportunity'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.reasonForLosingOpportunity}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
