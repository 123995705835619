import * as yup from 'yup'

export class SignInDto {
  email: string = ''
  password: string = ''
}

export const signInValidationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Email Address is required.'),
  password: yup.string().required('Password is required.')
})
