import { createTheme } from '@mui/material/styles'
import { baseTheme } from './baseTheme'

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    background: {
      default: `#efefef`
    },
    primary: {
      main: '#8C6751',
      light: '#D9C7A7',
      dark: '#212640'
    },
    secondary: {
      main: '#f1f1f1',
      light: '#7f7f7f',
      dark: '#2e3b34'
    },
    success: {
      main: '#103778',
      light: '#0593a2',
      dark: '#151f30'
    }
  },
  zIndex: {
    // These have been changed from 9997^ because notistack snackbars appear to be hardcoded at zIndex 1400, and
    // the drawer/navigation menu was covering most of the notifications with the recent mobile friendliness changes.
    // "snackbar: 1400" is declared here for consistency between our MUI theme and notistack. MJS 5/3/2024
    appBar: 1397,
    drawer: 1398,
    modal: 1399,
    snackbar: 1400,
    fab: 1400 // What is this? MJS
  }
})
