import { Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { PermissionDto, PersonAccountSetupDto, PersonDto } from 'dtos'
import React, { useEffect, useState } from 'react'
import * as controllers from 'controllers'
import AccountSetupEditDialog from './AccountSetupEditDialog'
import { enqueueSnackbar } from 'notistack'

interface AccountSetupCardProps {
  person: PersonDto
  setPerson: React.Dispatch<React.SetStateAction<PersonDto>>
}

export default function AccountSetupCard({ person, setPerson }: AccountSetupCardProps) {
  const [accountSetup, setAccountSetup] = useState<PersonAccountSetupDto>(new PersonAccountSetupDto())
  const [isSavingAccountSetup, setIsSavingAccountSetup] = useState<boolean>(false)
  const [personAccountSetupEditDialogOpen, setPersonAccountSetupEditDialogOpen] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<PermissionDto[]>([])

  useEffect(() => {
    controllers.getPermissions().then(response => {
      setPermissions(response.value)
    })
  }, [])

  useEffect(() => {
    if (person && person.id) {
      controllers.getAccountSetup(person.id).then(response => {
        setAccountSetup(response.value)
      })
    }
  }, [person])

  return (
    <>
      <AccountSetupEditDialog
        initialValues={accountSetup}
        loading={isSavingAccountSetup}
        onClose={() => {
          setPersonAccountSetupEditDialogOpen(false)
        }}
        permissions={permissions}
        onSave={values => {
          setIsSavingAccountSetup(true)
          controllers
            .updateAccountSetup(person.id!, values)
            .then(response => {
              setAccountSetup(response.value)
              setPerson({
                ...person,
                ...response.value
              })
              setPersonAccountSetupEditDialogOpen(false)
              enqueueSnackbar('Account Setup Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingAccountSetup(false)
            })
        }}
        open={personAccountSetupEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight='bold' variant='h5'>
                  Account Setup
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  onClick={() => {
                    setPersonAccountSetupEditDialogOpen(true)
                  }}
                  variant='text'
                >
                  EDIT&nbsp;
                  <Edit fontSize='small' />
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} container spacing={4}>
              {permissions
                .filter(permission => accountSetup.permissions.map(permission => permission.id).includes(permission.id))
                .filter(permission => permission.groupBy)
                .reduce((groups: string[], permission) => {
                  if (!groups.includes(permission.groupBy!)) {
                    groups.push(permission.groupBy!)
                  }

                  return groups
                }, [])
                .sort((a, b) => a.localeCompare(b))
                .map(group => (
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12}>
                      <Typography fontWeight='bold' variant='body2'>
                        {group}
                      </Typography>
                    </Grid>

                    {permissions
                      .filter(
                        permission =>
                          permission.groupBy &&
                          permission.groupBy! === group &&
                          accountSetup.permissions.map(permission => permission.id).includes(permission.id)
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(permission => (
                        <Grid item xs={12} key={permission.id}>
                          <Typography variant='body1'>{permission.name}</Typography>
                        </Grid>
                      ))}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
