import { apiClient } from '_app'
import axios from 'axios'
import { ApiResponse, PersonDto } from 'dtos'

export const checkIfUserSignedIn = () => axios.get<ApiResponse<PersonDto>>('/api/account/session').then(({ data }) => data)

export const sendPasswordReset = (email: string) =>
  apiClient.post<ApiResponse<string>>('/api/account/forgot-password', { email }).then(({ data }) => data)

export const signIn = (email: string, password: string) =>
  apiClient.post<ApiResponse<PersonDto>>('/api/account/sign-in', { email, password }).then(({ data }) => data)

export const signOut = () => apiClient.post('/api/account/sign-out').then(({ data }) => data)

export const resetPassword = (email: string, password: string, token: string) =>
  apiClient.post<ApiResponse<string>>('/api/account/reset-password', { email, password, token }).then(({ data }) => data)
