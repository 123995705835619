import styled from '@emotion/styled'
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid
} from '@mui/material'
import { useState } from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { WorkOrderReleaseTaskTimeEntryDto, WorkOrderReleaseTaskDto } from 'dtos'
import { ListRowAction } from 'components/list'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function TaskNeedsEstimation() {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            // border: '4px solid white',
            // borderRadius: '1px',
            // backgroundColor: '#8C6751',
            // color: '#fff',
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          My Projects
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>

                <TableCell align='left'>DSLA</TableCell>

                <TableCell align='left'>Open Tasks</TableCell>

                <TableCell align='left'>BT</TableCell>

                <TableCell align='left'>Open Invoices</TableCell>

                <TableCell align='left'>WO#</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Spud ERP</TableCell>

                <TableCell align='left'>2 Days</TableCell>

                <TableCell align='left'>Spec Creation</TableCell>

                <TableCell align='left'>Monthly</TableCell>

                <TableCell align='left'>N/A</TableCell>

                <TableCell align='left'>25781</TableCell>

                <TableCell align='right'>
                  <ListRowAction label='LOG TIME' onClick={() => {}} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
