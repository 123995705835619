import { apiClient } from '_app'
import { ApiResponse, WorkOrderDto, WorkOrdersSummaryDto } from 'dtos'
import { WorkOrdersParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getWorkOrders = (parameters: WorkOrdersParameters) =>
  apiClient
    .get<ApiResponse<WorkOrderDto[]>>('/api/work-orders' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getWorkOrdersSummary = (parameters: WorkOrdersParameters) =>
  apiClient
    .get<ApiResponse<WorkOrdersSummaryDto>>(
      '/api/work-orders/summary' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getWorkOrderById = (id: string) =>
  apiClient
    .get<ApiResponse<WorkOrderDto>>('/api/work-orders/' + id)
    .then(({ data }) => data)

export const createWorkOrder = (values: WorkOrderDto) =>
  apiClient
    .post<ApiResponse<WorkOrderDto>>('/api/work-orders', values)
    .then(({ data }) => data)

export const updateWorkOrder = (values: WorkOrderDto) =>
  apiClient
    .put<ApiResponse<WorkOrderDto>>('/api/work-orders', values)
    .then(({ data }) => data)
