import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { PersonDto } from 'dtos'

export class FilesDto {
  id?: string = undefined
  name: string = ''
  description?: string = undefined
  person?: PersonDto = undefined
}

export const filesValidationSchema = yup.object<FilesDto>({
  id: yup.string().nullable(),
  name: yup
    .string()
    .max(200, 'File Name cannot be more than 200 characters.')
    .required('File Name is required.'),
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .required('Asset Description is required.'),
  person: yup.object().nullable()
})
