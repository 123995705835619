import { Grid, Typography, useMediaQuery } from '@mui/material'
import TablePagination from 'components/table/TablePagination'
import React, { useEffect } from 'react'

interface Pagination {
  count: number
  page: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

interface ListProps {
  pagination?: Pagination
  sortedByDescription?: string
}

export default function List({ children, pagination, sortedByDescription }: React.PropsWithChildren<ListProps>) {
  return (
    <Grid container spacing={1} alignItems='center' sx={{ minWidth: '100%' }}>
      {children}

      <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
        <Grid item>
          {sortedByDescription && (
            <Typography variant='body2' fontStyle='italic'>
              * {sortedByDescription}
            </Typography>
          )}
        </Grid>

        <Grid item>{pagination && <TablePagination pagination={pagination} />}</Grid>
      </Grid>
    </Grid>
  )
}
