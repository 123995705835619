import * as yup from 'yup'
import { CompanyDto } from './company'
import { requiredFieldMessage } from '../constants'

export class ProjectDto {
  id?: string = undefined
  name?: string = undefined
  company?: CompanyDto
}

export const projectValidationSchema = yup.object({
  name: yup.string().max(255, 'Project Name cannot be more than 255 characters.').required('Project is required.')
})
