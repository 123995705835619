import { AppRegistration, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material'
import { Chip } from 'components/chips'
import { CompanyDto } from 'dtos'
import { useNavigate } from 'react-router-dom'

interface CompanyListCardProps {
  company: CompanyDto
}

export default function CompanyListCard({ company }: CompanyListCardProps) {
  const navigate = useNavigate()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {company.name}
                </Typography>
              </Grid>

              {company.isActive && (
                <Grid item>
                  <Chip variant='green'>Active</Chip>
                </Grid>
              )}

              {!company.isActive && (
                <Grid item>
                  <Chip variant='gray'>Inactive</Chip>
                </Grid>
              )}

              {company.isVendor && (
                <Grid item>
                  <Chip variant='purple'>Vendor</Chip>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm='auto' alignItems='flex-end'>
              <Button
                endIcon={<AppRegistration />}
                onClick={() => {
                  navigate('/companies/' + company.id)
                }}
                variant='text'
              >
                DETAILS
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Company Code</Typography>
            <Typography color='primary' variant='body1'>
              {company.companyCode}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Website</Typography>
            <Typography color='primary' variant='body1'>
              <Link href={company.website} target='_blank'>
                {company.website}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Campaigns</Typography>
            <Typography color='primary' variant='body1'>
              {company.campaigns?.map(campaign => campaign.name).join(', ')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant='body2'>Notes</Typography>
            <Typography color='primary' variant='body1'>
              {company.notes}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
