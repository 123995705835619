import * as yup from 'yup'
import { CompanyDto } from './company'
import { DepartmentDto } from './departments'
import { PermissionDto } from './permission'
import { RecruitingDto } from './recruiting'
import { RecruitingActivityDto } from './recruitingActivity'
import { RecruitingActivityTypeDto } from './recruitingActivityType'
import { RecruitingNextActivityTypeDto } from './recruitingNextActivityType'
import { RecruitingStatusDto } from './recruitingStatus'

export class PersonDto {
  id?: string = undefined
  firstName: string = ''
  lastName: string = ''
  company?: CompanyDto = undefined
  title?: string = undefined
  email?: string = undefined
  textEmail?: string = undefined
  mobilePhone?: string = undefined
  workPhone?: string = undefined
  notes?: string = undefined
  allowUserToSignIn: boolean = false
  employmentStartDate?: Date | string = undefined
  employmentEndDate?: Date | string = undefined
  birthDate?: number = undefined
  birthMonth?: number = undefined
  isActive: boolean = true
  isRecruitingCandidate: boolean = false
  isUser: boolean = false
  permissions: PermissionDto[] = []
  departments: DepartmentDto[] = []
  recruiting: RecruitingDto[] = []
  recruitingActivity: RecruitingActivityDto[] = []
  recruitingActivityType: RecruitingActivityTypeDto[] = []
  recruitingNextActivityType: RecruitingNextActivityTypeDto[] = []
  recruitingStatus: RecruitingStatusDto[] = []
}

export const peopleValidationSchema = yup.object<PersonDto>({
  id: yup.string().nullable(),
  firstName: yup.string().required('First Name is required.').max(200, 'First Name cannot be more than 200 characters.'),
  lastName: yup.string().required('Last Name is required.').max(200, 'Last Name cannot be more than 200 characters.'),
  company: yup.object().nullable(),
  title: yup.string().max(200, 'Title cannot be more than 200 characters.').nullable(),
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .max(320, 'Email cannot be more than 320 characters.')
    .nullable(),
  textEmail: yup
    .string()
    .email('Please enter a valid email address.')
    .max(320, 'Text Email cannot be more than 320 characters.')
    .nullable(),
  mobilePhone: yup.string().max(50, 'Mobile Phone cannot be more than 50 characters.').nullable(),
  workPhone: yup.string().max(50, 'Work Phone cannot be more than 50 characters.').nullable(),

  birthMonth: yup
    .number()
    .min(1, 'Birth Month cannot be less than 1.')
    .max(12, 'Birth Month cannot be greater than 12.')
    .nullable(),
  birthDate: yup
    .number()
    .min(1, 'Birth Date cannot be less than 1.')
    .max(31, 'Birth Date cannot be greater than 31.')
    .nullable()
})
