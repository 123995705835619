import { apiClient } from '_app'
import { ApiResponse, SecretDto, SecretGroupDto } from 'dtos'
import { SecretGroupsParameters, SecretsParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getSecretGroups = (parameters: SecretGroupsParameters) =>
  apiClient
    .get<ApiResponse<SecretGroupDto[]>>('/api/secret-groups' + URIQuery({ ...parameters }))
    .then(response => response.data)

export const getSecretGroupById = (id: string) =>
  apiClient.get<ApiResponse<SecretGroupDto>>('/api/secret-groups/' + encodeURIComponent(id)).then(response => response.data)

export const createSecretGroup = (values: SecretGroupDto) =>
  apiClient.post<ApiResponse<SecretGroupDto>>('/api/secret-groups', values).then(response => response.data)

export const updateSecretGroup = (values: SecretGroupDto) =>
  apiClient.put<ApiResponse<SecretGroupDto>>('/api/secret-groups', values).then(response => response.data)

export const getSecretsBySecretGroupGuidAndParameters = (secretGroupGuid: string, parameters: SecretsParameters) =>
  apiClient
    .get<ApiResponse<SecretDto[]>>(
      `/api/secret-groups/${encodeURIComponent(secretGroupGuid)}/secrets` + URIQuery({ ...parameters })
    )
    .then(response => response.data)

export const getSecretBySecretGroupGuidAndSecretGuid = (secretGroupGuid: string, secretGuid: string) =>
  apiClient
    .get<ApiResponse<SecretDto>>(
      `/api/secret-groups/${encodeURIComponent(secretGroupGuid)}/secrets/${encodeURIComponent(secretGuid)}`
    )
    .then(response => response.data)

export const createSecret = (secretGroupGuid: string, values: SecretDto) =>
  apiClient
    .post<ApiResponse<SecretDto>>(`/api/secret-groups/${encodeURIComponent(secretGroupGuid)}/secrets`, values)
    .then(response => response.data)

export const updateSecret = (secretGroupGuid: string, values: SecretDto) =>
  apiClient
    .put<ApiResponse<SecretDto>>(`/api/secret-groups/${encodeURIComponent(secretGroupGuid)}/secrets`, values)
    .then(response => response.data)
