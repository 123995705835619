import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { CompanyDto } from './company'

export class WishListDto {
  name?: string = ''
  item?: string = undefined
  description?: string = undefined
  isActive: boolean = true
  wishList?: WishListDto = undefined
  companies?: CompanyDto = undefined
  company?: CompanyDto = undefined
}

export const wishListValidationSchema = yup.object<WishListDto>({
  name: yup
    .string()
    .max(200, 'Company Name cannot be more than 200 characters.')
    .nullable(),
  item: yup.string().max(200, 'Item cannot be more than 200 characters.').nullable(),
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .nullable()
})
