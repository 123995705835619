import { apiClient } from '_app'
import { ApiResponse, VendorServiceTypeDto } from 'dtos'

export const getVendorServiceTypes = () =>
  apiClient
    .get<ApiResponse<VendorServiceTypeDto[]>>('/api/vendor-service-types')
    .then(({ data }) => data)
    .catch(reason => {
      throw new Error(reason)
    })
