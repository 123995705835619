import { Card, CardContent, Grid } from '@mui/material'
import React, { Children } from 'react'

interface ListFilterProps {}

export default function ListFilters({
  children
}: React.PropsWithChildren<ListFilterProps>) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
            {Children.map(children, (child, index) => (
              <Grid item xs={12} sm='auto' key={index}>
                {child}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
