import styled from '@emotion/styled'
import {
  Card,
  Badge,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { ListRowAction } from 'components/list'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function TaskNeedsEstimation() {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const generateBadgeContent = () => {
    return Math.floor(Math.random() * 100)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            // border: '4px solid white',
            // borderRadius: '1px',
            // backgroundColor: '#8C6751',
            // color: '#fff',
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          Task Needs Estimation
          <Badge
            badgeContent={generateBadgeContent()}
            color='error'
            sx={{ left: '20px', bottom: '4px' }}
          />
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Project#</TableCell>

                <TableCell align='left'>Task</TableCell>

                <TableCell align='right'>Status</TableCell>

                {/*   <TableCell align='right'>Notify Team Member</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>#25789</TableCell>

                <TableCell align='left'>N/A</TableCell>

                <TableCell align='right'>N/A</TableCell>

                <TableCell align='right'>
                  <ListRowAction label='LOG TIME' onClick={() => {}} />
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody></TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
