import { useMemo, useState } from 'react'

export default function useLoadingState<T extends Record<string, boolean>>(initialState: T) {
  const [loadingState, _setLoadingState] = useState(initialState)

  const setLoadingState = (key: keyof T, value: boolean) => {
    _setLoadingState(prev => ({
      ...prev,
      [key]: value
    }))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [loadingState, setLoadingState] as const, [loadingState])
}
