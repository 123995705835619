import { LoadingButton } from '@mui/lab'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { SecretDto, SecretGroupDto, secretGroupValidationSchema, secretValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { useLoadingState } from 'hooks'
import { useEffect, useState } from 'react'
import * as controllers from 'controllers'
import { enqueueSnackbar } from 'notistack'

interface SecretAddEditDialogProps {
  onClose: () => void
  // This is onSaved because it is called after the API request. This is structured this way so the unencrypted secret never leaves this component.
  onSaved: () => void
  open: boolean
  secret: SecretDto
  secretGroup: SecretGroupDto
}

export default function SecretAddEditDialog({ onClose, onSaved, open, secret, secretGroup }: SecretAddEditDialogProps) {
  const [initialValues, setInitialValues] = useState<SecretDto>(new SecretDto())
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingSecret: false,
    isSavingSecret: false
  })

  useEffect(() => {
    if (open) {
      if (secret && secret.secretGuid) {
        setLoadingState('isGettingSecret', true)
        controllers
          .getSecretBySecretGroupGuidAndSecretGuid(secretGroup.secretGroupGuid!, secret.secretGuid)
          .then(response => {
            setInitialValues(response.value)
          })
          .finally(() => {
            setLoadingState('isGettingSecret', false)
          })
      } else {
        setInitialValues(secret)
      }
    }

    return () => {
      setInitialValues({})
    }
  }, [open, secret, secretGroup])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          setLoadingState('isSavingSecret', true)

          const requestMethod = values.secretGuid ? controllers.updateSecret : controllers.createSecret
          requestMethod(secretGroup.secretGroupGuid!, values)
            .then(response => {
              enqueueSnackbar('Secret Saved Successfully!', { variant: 'success' })
              onSaved()
            })
            .finally(() => {
              setLoadingState('isSavingSecret', false)
              formikHelpers.setSubmitting(false)
            })
        }}
        validateOnBlur
        validateOnChange
        validationSchema={secretValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    {loadingState.isGettingSecret ? 'Decrypting' : initialValues.secretGuid ? 'Edit' : 'Add'} Secret
                  </DialogTitle>

                  {loadingState.isGettingSecret && (
                    <Grid item xs={12} container justifyContent='center'>
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  )}

                  {!loadingState.isGettingSecret && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          disabled={loadingState.isSavingSecret || isSubmitting}
                          error={Boolean(errors.key)}
                          helperText={errors.key}
                          label='Key'
                          name='key'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.key || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={loadingState.isSavingSecret || isSubmitting}
                          error={Boolean(errors.value)}
                          helperText={errors.value}
                          label='Value'
                          name='value'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.value || ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={loadingState.isSavingSecret || isSubmitting}
                          error={Boolean(errors.notes)}
                          helperText={errors.notes}
                          label='Notes'
                          maxRows={4}
                          minRows={4}
                          multiline
                          name='notes'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.notes || ''}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loadingState.isSavingSecret || isSubmitting} color='inherit' onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={loadingState.isSavingSecret || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
