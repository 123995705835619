import { Grid } from '@mui/material'
import React, { Children } from 'react'

interface ListFilterGroupProps {}

export default function ListFilterGroup({
  children
}: React.PropsWithChildren<ListFilterGroupProps>) {
  return (
    <Grid container alignItems='center'>
      {Children.map(children, (child, index) => (
        <Grid item xs={12} sm='auto' key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}
