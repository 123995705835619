import { apiClient } from '_app'
import { ApiResponse, CampaignDto } from 'dtos'
import { CampaignsParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getCampaigns = (parameters: CampaignsParameters) =>
  apiClient
    .get<ApiResponse<CampaignDto[]>>('/api/campaigns' + URIQuery({ ...parameters }))
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getCampaignById = (id: string) =>
  apiClient
    .get<ApiResponse<CampaignDto>>('/api/campaigns/' + id)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const createCampaign = (values: CampaignDto) =>
  apiClient
    .post<ApiResponse<CampaignDto>>('/api/campaigns', values)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const updateCampaign = (values: CampaignDto) =>
  apiClient
    .put<ApiResponse<CampaignDto>>('/api/campaigns', values)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })
