import { MoreVert } from '@mui/icons-material'
import { Button, ButtonBase, Grid, Menu, MenuItemProps } from '@mui/material'
import React, { ReactElement } from 'react'

export interface ListRowActionProps {
  color?: 'inherit' | 'primary'
  // variant?: 'text' | 'outlined' | 'contained'
  children?: ReactElement<MenuItemProps> | Array<ReactElement<MenuItemProps>>
  disabled?: boolean
  label: string
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export default function ListRowAction({
  children,
  color = 'primary',
  disabled = false,
  onClick,
  label
}: // variant = 'text'
ListRowActionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item>
      <Button
        color={color}
        disabled={disabled}
        onClick={onClick}
        sx={{
          backgroundColor: 'primary',
          px: '10px',
          py: '5px',
          marginRight: '7px',
          marginBottom: '5px'
        }}
        variant='contained'
      >
        {label}
      </Button>

      {children && (
        <>
          <ButtonBase onClick={handleClick} sx={{ borderRadius: 1, minHeight: 24.5 }}>
            <MoreVert />
          </ButtonBase>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {children}
          </Menu>
        </>
      )}
    </Grid>
  )
}
