import { CompanyDto, VendorServiceTypeDto } from 'dtos'
import * as yup from 'yup'

export class VendorContractInformationDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  vendorServiceType?: VendorServiceTypeDto = undefined
  account?: string = undefined
  serviceDescription?: string = undefined
  annualBudget?: number = undefined
  billingFrequency?: string = undefined
  billingAmountPerFrequency?: number = undefined
  contractExpirationDate?: string = undefined
  notes?: string = undefined
  isActive: boolean = true
}

export const vendorContactInformationValidationSchema = yup.object({
  id: yup.string().nullable(),
  vendorServiceType: yup.object().required('Vendor Service Type is required.'),
  account: yup
    .string()
    .max(200, 'Account cannot be more than 200 characters.')
    .required('Account is required.'),
  serviceDescription: yup
    .string()
    .max(200, 'Service Description cannot be more than 200 characters.')
    .required('Service Description is required.'),
  annualBudget: yup
    .number()
    .min(0, 'Annual Budget cannot be less than $0.00.')
    .max(999_999_999_999.99, 'Annual Budget cannot be more than $999,999,999.99.')
    .required('Annual Budget is required.'),
  billingFrequency: yup
    .string()
    .max(200, 'Billing Frequency cannot be more than 200 characters.')
    .required('Billing Frequency is required.'),
  billingAmountPerFrequency: yup
    .number()
    .min(0, 'Billing Amount Per Frequency cannot be less than $0.00.')
    .max(
      999_999_999_999.99,
      'Billing Amount Per Frequency cannot be more than $999,999,999.99.'
    )
    .required('Billing Amount Per Frequency is required.'),
  contractExpirationDate: yup.string().required('Contract Expiration Date is required.'),
  notes: yup.string().max(2_000, 'Notes cannot be more than 2,000 characters.').nullable()
})
