import * as yup from 'yup'
import { PersonDto } from 'dtos'
import { DepartmentDto } from './departments'
import { RecruitingActivityDto } from './recruitingActivity'

export class RecruitingDto {
  id?: string = undefined
  position?: string = undefined
  departments: DepartmentDto[] = []
  positionName?: string = undefined
  fillDate?: string = undefined
  positionDescription?: string = undefined
  payRange?: string = undefined
  isActive: boolean = true

  Person?: PersonDto = undefined
  readonly_lastRecruitingActivity?: RecruitingActivityDto = undefined
  recruitingActivity: RecruitingActivityDto[] = []
}

export const recruitingValidationSchema = yup.object({
  position: yup
    .string()
    .max(200, 'Position cannot be more than 200 characters.')
    .nullable(),
  Department: yup.object().required('Department is required.'),
  positionDescription: yup
    .string()
    .max(2_000, 'Position Description cannot be more than 2,000 characters.')
    .required('Position Description is required.'),
  payRange: yup.object().nullable()
})
