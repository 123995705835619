import { Add, SearchOutlined } from '@mui/icons-material'
import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import LabelField from 'components/LabelField'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { List, ListBody, ListFilterGroup, ListFilters, ListHeader } from 'components/list'
import * as controllers from 'controllers'
import { CampaignDto, StatusCount } from 'dtos'
import { useQuery } from 'hooks'
import { enqueueSnackbar } from 'notistack'
import { CampaignsParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatusCount, useDebounce } from 'utils'
import CampaignListCard from './CampaignListCard'
import CampaignAddEditDialog from './id/CampaignAddEditDialog'
import { useLayout } from 'context'

export default function Campaigns() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const navigate = useNavigate()
  const query = useQuery()

  const queryParams = new URLSearchParams(window.location.search)
  const initialSearchValue = queryParams.get('search') || ''

  const [count, setCount] = useState<number>(0)
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([])
  const [parameters, setParameters] = useState<CampaignsParameters>({
    activeOnly: true,
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    statuses: ['isActive']
  })

  const [campaign, setCampaign] = useState<CampaignDto>(new CampaignDto())
  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>(false)
  const [campaignAddEditDialogOpen, setCampaignAddEditDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setTitle('Campaigns')
  }, [])

  useEffect(() => {
    getCampaigns(parameters)
  }, [parameters])

  const getCampaigns = useDebounce((parameters: CampaignsParameters) => {
    setIsLayoutLoading(true)
    controllers
      .getCampaigns(parameters)
      .then(res => {
        setCampaigns(res.value)
        setCount(res.totalCount ?? 0)
        setStatusCounts(res.statusCounts ?? [])
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'isActive', count: 0 },
    { status: 'isInactive', count: 0 }
  ])

  useEffect(() => {
    getCampaigns(parameters)

    return () => {}
  }, [parameters])

  const findStatusCount = (status: string) => statusCounts.filter(statusCount => statusCount.status === status)[0].count

  return (
    <>
      <CampaignAddEditDialog
        initialValues={campaign}
        isLoading={isCreatingCampaign}
        onClose={() => {
          setCampaignAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingCampaign(true)

          const requestMethod = values.id ? controllers.updateCampaign : controllers.createCampaign

          requestMethod(values)
            .then(() => {
              enqueueSnackbar('Campaign Created Successfully!', { variant: 'success' })
              getCampaigns(parameters)
              setCampaignAddEditDialogOpen(false)
            })
            .finally(() => {
              setIsCreatingCampaign(false)
            })
        }}
        open={campaignAddEditDialogOpen}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
      >
        <ListHeader>Campaigns</ListHeader>

        <ListFilters>
          <TextField
            data-search
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined fontSize='small' />
                </InputAdornment>
              )
            }}
            label='Search'
            onChange={e => setParameters({ ...parameters, search: e.target.value })}
            size='small'
            type='search'
            value={parameters.search}
          />

          <ChipStatusSelect
            label='Statuses'
            onChange={value => {
              setParameters({
                ...parameters,
                statuses: value
              })
            }}
            options={[
              {
                label: `Active (${getStatusCount(statusCounts, 'isActive')})`,
                color: 'green',
                value: 'isActive'
              },
              {
                label: `Inactive (${getStatusCount(statusCounts, 'isInactive')})`,
                color: 'gray',
                value: 'isInactive'
              }
            ]}
            value={parameters.statuses ?? []}
          />

          {/* <ListFilterGroup>
            <LabelField label='Active' value={findStatusCount('isActive') + ''} />
          </ListFilterGroup>

          <ListFilterGroup>
            <LabelField label='Inactive' value={findStatusCount('isInactive') + ''} />
          </ListFilterGroup> */}

          <Button
            color='primary'
            endIcon={<Add />}
            onClick={() => {
              setCampaign(new CampaignDto())
              setCampaignAddEditDialogOpen(true)
            }}
            size='medium'
            variant='text'
          >
            ADD NEW
          </Button>
        </ListFilters>

        <ListBody>
          {campaigns.map(campaign => (
            <Grid item xs={12}>
              <CampaignListCard campaign={campaign} />
            </Grid>
          ))}
        </ListBody>

        <Grid item xs={12}>
          <ExplanationAccordion>
            Lists all active campaigns by default.||Search searches Campaign Name and Description. ||Companies can be linked to
            these campaigns for marketing and sales analysis.||There should be a campaign for every possible method of how a
            prospect has come to our company. ||The Statuses multi-select filters the list by the selected Statuses. ||Ordered
            by Start Date Ascending then by Campaign Name Ascending.
          </ExplanationAccordion>
        </Grid>
      </List>
    </>
  )
}
