import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material'
import { PermissionDto, PersonAccountSetupDto, personAccountSetupValidationSchema } from 'dtos'
import { Formik } from 'formik'
import React from 'react'

interface AccountSetupEditProps {
  initialValues: PersonAccountSetupDto
  loading: boolean
  onClose: () => void
  onSave: (values: PersonAccountSetupDto) => void
  open: boolean
  permissions: PermissionDto[]
}

export default function AccountSetupEditDialog({
  initialValues,
  loading,
  onClose,
  onSave,
  open,
  permissions
}: AccountSetupEditProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={personAccountSetupValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Edit Account Setup</DialogTitle>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={loading || isSubmitting || !values.isActive}
                          checked={values?.allowUserToSignIn ?? false}
                          name='allowUserToSignIn'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.allowUserToSignIn}
                        />
                      }
                      label='Access to Spud ERP'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} container spacing={4}>
                    {permissions
                      .filter(permission => permission.groupBy)
                      .reduce((groups: string[], permission) => {
                        if (!groups.includes(permission.groupBy!)) {
                          groups.push(permission.groupBy!)
                        }

                        return groups
                      }, [])
                      .sort((a, b) => a.localeCompare(b))
                      .map(group => (
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={12}>
                            <Typography fontWeight='bold'>{group}</Typography>
                          </Grid>

                          {permissions
                            .filter(permission => permission.groupBy && permission.groupBy! === group)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(permission => (
                              <Grid item xs={12} key={permission.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.permissions.map(permission => permission.id).includes(permission.id)}
                                      disabled={loading || isSubmitting || !values.isActive || !values.allowUserToSignIn}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setFieldValue('permissions', [...values.permissions, permission])
                                        } else {
                                          setFieldValue(
                                            'permissions',
                                            values.permissions.filter(
                                              existingPermission => existingPermission.id !== permission.id
                                            )
                                          )
                                        }
                                      }}
                                    />
                                  }
                                  label={permission.name}
                                  sx={{ ml: 2 }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button disabled={loading || isSubmitting} variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <LoadingButton loading={loading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
