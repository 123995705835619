import { apiClient } from '_app'
import { ApiResponse, AssetDto } from 'dtos'
import { AssetsParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getAssets = (parameters: AssetsParameters) =>
  apiClient
    .get<ApiResponse<AssetDto[]>>('/api/assets' + URIQuery({ ...parameters }))
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const getAssetById = (id: string) =>
  apiClient
    .get<ApiResponse<AssetDto>>('/api/assets/' + id)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const createAsset = (values: AssetDto) =>
  apiClient
    .post<ApiResponse<AssetDto>>('/api/assets', values)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })

export const updateAsset = (values: AssetDto) =>
  apiClient
    .put<ApiResponse<AssetDto>>('/api/assets', values)
    .then(res => res.data)
    .catch(apiError => {
      throw new Error(apiError)
    })
