import styled from '@emotion/styled'
import { Card, Chip, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as controllers from 'controllers'
import { ProjectWithNoActivityFor7PlusDaysDto } from 'dtos'
import { useEffect, useState } from 'react'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function ProjectsWithNoActivityFor7PlusDays() {
  const [projectsWithNoActivityFor7PlusDays, setProjectsWithNoActivityFor7PlusDays] = useState<
    ProjectWithNoActivityFor7PlusDaysDto[]
  >([])
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    controllers.getProjectsWithNoActivityFor7PlusDays().then(res => {
      setProjectsWithNoActivityFor7PlusDays(res.value)
    })
  }, [])

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography variant='h6' sx={{ ml: '8px', fontWeight: 'bold', padding: '8px', minWidth: '100%' }}>
          Projects with No Activity for 7+ Days
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>

                <TableCell align='center'>DSLA</TableCell>

                <TableCell align='center'>PM</TableCell>

                <TableCell align='center'>HSN</TableCell>

                <TableCell align='center'>WO#</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {projectsWithNoActivityFor7PlusDays.map(project => (
                <TableRow key={project.workOrder!.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    <Chip
                      label={project.workOrder?.company?.name}
                      size='small'
                      sx={{ backgroundColor: '#eaf9e0', color: '#56ca00', m: 0.25 }}
                      variant='filled'
                    />

                    <Chip
                      label={project.workOrder?.project?.name}
                      size='small'
                      sx={{ backgroundColor: '#fff6e0', color: '#ffb400', m: 0.25 }}
                      variant='filled'
                    />
                  </TableCell>

                  <TableCell align='center'>{project.daysSinceLastActivity}</TableCell>

                  <TableCell align='center'>
                    {project.workOrder?.projectManager?.firstName} {project.workOrder?.projectManager?.lastName}
                  </TableCell>

                  <TableCell align='center'>{project.hoursStillNeeded}</TableCell>

                  <TableCell align='center'>{project.workOrder?.workOrderNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
