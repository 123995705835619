import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  maxString200Message,
  maxString200Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '../constants'
import { WorkOrderDto } from './workOrder'
import { WorkOrderReleaseTypeDto } from './workOrderReleaseType'

export class WorkOrderInvoiceDto {
  id?: string = undefined
  invoiceAmount?: number = undefined
  // DateOnly needs to be a string on the front-end
  needsToBeSentDate?: string = undefined
  // DateOnly needs to be a string on the front-end
  sentDate?: string = undefined
  // DateOnly needs to be a string on the front-end
  receivedDate?: string = undefined
  prePaidHours?: number = undefined
  quickBooksInvoiceNumber?: string = undefined
  specialIdentifier?: string = undefined

  needsToBeSent?: boolean = undefined
  waitingOnPayment?: boolean = undefined
  isActive: boolean = true

  workOrder?: WorkOrderDto
}

export const workOrderInvoiceValidationSchema = yup.object({
  id: yup.string().nullable(),
  invoiceAmount: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  needsToBeSentDate: yup.string().required(requiredFieldMessage),
  sentDate: yup.string().nullable(),
  receivedDate: yup.string().nullable(),
  prePaidHours: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  quickBooksInvoiceNumber: yup.string().max(maxString200Value, maxString200Message).nullable(),
  specialIdentifier: yup.string().max(maxString200Value, maxString200Message).nullable()
})
