import { Chip as MuiChip } from '@mui/material'
import { useEffect, useState } from 'react'

interface ChipProps {
  children: string
  variant: 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'gray'
}

// Standard Spud ERP Chip. The purpose of this component is to ensure every chip throughout the application
// is consistent.
export default function Chip({ children, variant }: ChipProps) {
  const [backgroundColor, setBackgroundColor] = useState<string>('')
  const [color, setColor] = useState<string>('')

  useEffect(() => {
    if (variant === 'red') {
      setBackgroundColor('#ffe9ea')
      setColor('#ff8185')
    } else if (variant === 'green') {
      setBackgroundColor('#eaf9e0')
      setColor('#56ca00')
    } else if (variant === 'blue') {
      setBackgroundColor('#e3f6ff')
      setColor('#26b6ff')
    } else if (variant === 'yellow') {
      setBackgroundColor('#fff6e0')
      setColor('#ffb400')
    } else if (variant === 'purple') {
      setBackgroundColor('#f2eaff')
      setColor('#9155fd')
    } else if (variant === 'gray') {
      setBackgroundColor('#f1f1f2')
      setColor('#9d9fa4')
    }
  }, [variant])

  return <MuiChip label={children} sx={{ height: '24px', backgroundColor, color, m: 0.25 }} variant='filled' />
}
