import { PersonDto } from './person'
import * as yup from 'yup'

export const temporaryTodoValidationSchema = yup.object({
  category: yup
    .string()
    .max(50, 'Category cannot be more than 50 characters.')
    .required('Category is required.'),
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .required('Description is required.'),
  priority: yup
    .number()
    .min(1, 'Priority cannot be less than 1.')
    .max(999, 'Priority cannot be more than 999.')
    .nullable(),
  pushedToQADateTimeUtc: yup.date().nullable(),
  pushedToProdDateTimeUtc: yup.date().nullable()
})

export class _TemporaryTodoDto {
  id?: string = undefined
  category?: string = undefined
  description?: string = undefined
  priority?: number = undefined
  pushedToQADateTimeUtc?: string = undefined
  pushedToProdDateTimeUtc?: string = undefined
  createdBy?: PersonDto = undefined
  updatedBy?: PersonDto = undefined
}
