import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import LabelField from 'components/LabelField'
import { Chip } from 'components/chips'
import { AssetDto } from 'dtos'

interface AssetHeroCardProps {
  asset: AssetDto
}

export default function AssetHeroCard({ children, asset }: React.PropsWithChildren<AssetHeroCardProps>) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={true} container spacing={0} alignItems='center'>
              <Grid item>
                <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                  {asset.name} - {asset.description}
                </Typography>
              </Grid>
              <Grid item>{asset.assetCategory?.name === 'Test' && <Chip variant='green'>Test</Chip>}</Grid>

              <Grid item xs={12} sm={true} container alignItems='center' spacing={0}>
                {children}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* <Grid item xs={12} sm={3}>
              <LabelField label='Name' value={asset.name} />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LabelField label='Description' value={asset.description} />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LabelField label='Asset Type' value={asset.assetCategory?.name} />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <LabelField label='Asset #' value={asset.assetNumber?.toString()} />
            </Grid> */}

            <Grid item xs={12} sm={3}>
              <LabelField label='Serial #' value={asset.serialNumber} />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LabelField label='Notes' value={asset.notes} />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LabelField
                label='Team Member'
                value={`${asset.teamMember?.firstName || '-'} ${asset.teamMember?.lastName || '-'}`.trim()}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LabelField label='Location' value={asset.location} />
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <LabelField label='Purchase Date' value={asset.purchaseDate} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <LabelField label='Purchase Price' value={asset.purchasePrice?.toString()} />
            </Grid> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
