import * as yup from 'yup'
import { DepartmentDto } from './departments'

export class PersonHumanResourcesDto {
  id?: string = undefined
  employmentStartDate?: string = undefined
  employmentEndDate?: string = undefined
  birthDate?: number = undefined
  birthMonth?: number = undefined
  departments: DepartmentDto[] = []
}

export const personHumanResourcesValidationSchema = yup.object({
  id: yup.string()
})
