import { LoadingButton } from '@mui/lab'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { SecretDto, SecretGroupDto, secretGroupValidationSchema, secretValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { useLoadingState } from 'hooks'
import { useEffect, useState } from 'react'
import * as controllers from 'controllers'
import { enqueueSnackbar } from 'notistack'
import LabelField from 'components/LabelField'

interface SecretViewDialogProps {
  onClose: () => void
  open: boolean
  secret: SecretDto
  secretGroup: SecretGroupDto
}

export default function SecretViewDialog({ onClose, open, secret, secretGroup }: SecretViewDialogProps) {
  const [initialValues, setInitialValues] = useState<SecretDto>(new SecretDto())
  const [loadingState, setLoadingState] = useLoadingState({
    isGettingSecret: false
  })

  useEffect(() => {
    if (open) {
      if (secret && secret.secretGuid) {
        setLoadingState('isGettingSecret', true)
        controllers
          .getSecretBySecretGroupGuidAndSecretGuid(secretGroup.secretGroupGuid!, secret.secretGuid)
          .then(response => {
            setInitialValues(response.value)
          })
          .finally(() => {
            setLoadingState('isGettingSecret', false)
          })
      } else {
        setInitialValues(secret)
      }
    }

    return () => {
      setInitialValues({})
    }
  }, [open, secret, secretGroup])

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <DialogTitle>{loadingState.isGettingSecret ? 'Decrypting' : 'View'} Secret</DialogTitle>

          {loadingState.isGettingSecret && (
            <Grid item xs={12} container justifyContent='center'>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}

          {!loadingState.isGettingSecret && (
            <>
              <Grid item xs={12}>
                <LabelField label='Key' value={initialValues.key} copy />
              </Grid>

              <Grid item xs={12}>
                <LabelField label='Value' value={initialValues.value} copy />
              </Grid>

              <Grid item xs={12}>
                <LabelField label='Notes' value={initialValues.notes} />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color='inherit' onClick={onClose} variant='text'>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
