import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ExplanationAccordion } from 'components/_template/accordion'
import { CampaignDto, campaignValidationSchema } from 'dtos'
import { Formik } from 'formik'
import moment from 'moment'

interface CampaignAddEditDialogProps {
  initialValues: CampaignDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: CampaignDto) => void
  open: boolean
}

export default function CampaignAddEditDialog({ initialValues, isLoading, onClose, onSave, open }: CampaignAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={campaignValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Campaign</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Campaign Name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label='Campaign Description'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Start Date'
                      name='startDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('startDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('startDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.startDate && errors.startDate),
                          helperText: touched.startDate && errors.startDate
                        }
                      }}
                      value={values.startDate ? moment(values.startDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='End Date'
                      name='endDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('endDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('endDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.endDate && errors.endDate),
                          helperText: touched.endDate && errors.endDate
                        }
                      }}
                      value={values.endDate ? moment(values.endDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          disabled={isLoading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All fields are required to add or edit a Wish List Item. ||Company dropdown displays a list of current
                      Companies. ||Item field allows the user to enter
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
