import styled from '@emotion/styled'
import {
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Avatar from 'boring-avatars'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import * as controllers from 'controllers'
import { TeamMemberScheduleDto } from 'dtos'
import { useEffect, useState } from 'react'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2px; /* Adjust this value for the desired spacing */
`

export default function TeamMemberSchedule() {
  const [teamMemberSchedule, setTeamMemberSchedule] = useState<TeamMemberScheduleDto[]>(
    []
  )
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    controllers.getTeamMemberSchedule().then(res => {
      setTeamMemberSchedule(res.value)
    })
  }, [])

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledCard sx={{ borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'left',
          padding: '8px',
          justifyContent: 'space-between',
          color: 'secondary',
          cursor: 'pointer'
        }}
        onClick={handleToggleCollapse}
      >
        <Typography
          variant='h6'
          sx={{
            ml: '4px',
            fontWeight: 'bold',
            padding: '8px',
            minWidth: '100%'
          }}
        >
          Team Member Schedule
        </Typography>
        <div>
          {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          {/*{isCollapsed ? '▼' : '▲'}*/}
        </div>
      </div>

      {isCollapsed && (
        <TableContainer component={Paper} sx={{ overflowX: 'hidden' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {/* Left blank for avatar */}
                <TableCell colSpan={2}>Name</TableCell>
                <TableCell align='left'>Today</TableCell>
                <TableCell align='left'>Tomorrow</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {teamMemberSchedule?.map(({ teamMember, today, tomorrow }) => (
                <TableRow
                  key={teamMember?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Avatar
                      size='30'
                      square={false}
                      name={teamMember!.firstName + ' ' + teamMember!.lastName}
                      variant='beam'
                      colors={['#212640', '#D9C7A7', '#BFA380', '#8C6751', '#593E3C']}
                    />
                  </TableCell>

                  <TableCell>
                    <Typography variant='body2' noWrap>
                      {teamMember?.firstName + ' ' + teamMember?.lastName}
                    </Typography>
                  </TableCell>

                  <TableCell align='left'>
                    {today?.map(company => (
                      <Chip
                        label={company.name}
                        size='small'
                        sx={{ backgroundColor: '#eaf9e0', color: '#56ca00', m: 0.25 }}
                        variant='filled'
                      />
                    ))}
                  </TableCell>

                  <TableCell align='left'>
                    {tomorrow?.map(company => (
                      <Chip
                        label={company.name}
                        size='small'
                        sx={{
                          backgroundColor: '#f2eaff',
                          color: '#9155fd',
                          m: 0.25
                        }}
                        variant='filled'
                      />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledCard>
  )
}
