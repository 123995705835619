import { Add, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import LabelField from 'components/LabelField'
import { Chip, ChipStatusSelect } from 'components/chips'
import TablePagination from 'components/table/TablePagination'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import { WorkOrderDto, WorkOrderReleaseDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { WorkOrderReleasesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { formatDate, idIsNotNullUndefinedOrNew, useDebounce } from 'utils'
import WorkOrderReleaseAddEditDialog from './WorkOrderReleaseAddEditDialog'

interface WorkOrderReleasesCardProps {
  workOrder: WorkOrderDto
  getWorkOrder: () => void
}

export default function WorkOrderReleasesCard({ workOrder, getWorkOrder }: WorkOrderReleasesCardProps) {
  const {
    permissions: { WORK_ORDER_RELEASES_ADD_EDIT, WORK_ORDER_RELEASES_READ_ONLY }
  } = useAuth()

  const [workOrderReleases, setWorkOrderReleases] = useState<WorkOrderReleaseDto[]>([])
  const [workOrderRelease, setWorkOrderRelease] = useState<WorkOrderReleaseDto>(new WorkOrderReleaseDto())
  const [workOrderReleaseAddEditDialogOpen, setWorkOrderReleaseAddEditDialogOpen] = useState<boolean>(false)
  const [isGettingWorkOrderReleases, setIsGettingWorkOrderReleases] = useState<boolean>(false)
  const [isSavingWorkOrderRelease, setIsSavingWorkOrderRelease] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [parameters, setParameters] = useState<WorkOrderReleasesParameters>({
    page: 0,
    pageSize: 100,
    statuses: ['isOpen']
  })

  useEffect(() => {
    if (workOrder && idIsNotNullUndefinedOrNew(workOrder)) {
      getWorkOrderReleases(parameters, workOrder)
    }
  }, [parameters, workOrder])

  const getWorkOrderReleases = useDebounce((parameters: WorkOrderReleasesParameters, workOrder: WorkOrderDto) => {
    setIsGettingWorkOrderReleases(true)
    controllers
      .getWorkOrderReleasesByWorkOrderId(parameters, workOrder.id!)
      .then(response => {
        setWorkOrderReleases(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingWorkOrderReleases(false)
      })
  }, 300)

  return (
    <>
      <WorkOrderReleaseAddEditDialog
        initialValues={workOrderRelease}
        isLoading={isSavingWorkOrderRelease}
        onClose={() => {
          setWorkOrderReleaseAddEditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id ? controllers.updateWorkOrderRelease : controllers.createWorkOrderRelease

          setIsSavingWorkOrderRelease(true)
          requestMethod({ ...values, workOrder })
            .then(() => {
              getWorkOrder()
              setWorkOrderReleaseAddEditDialogOpen(false)
              enqueueSnackbar('Work Order Release Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingWorkOrderRelease(false)
            })
        }}
        open={workOrderReleaseAddEditDialogOpen}
        workOrder={workOrder}
        workOrderReleases={workOrderReleases}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Releases
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                {WORK_ORDER_RELEASES_ADD_EDIT && (
                  <Button
                    endIcon={<Add />}
                    onClick={() => {
                      setWorkOrderRelease(new WorkOrderReleaseDto())
                      setWorkOrderReleaseAddEditDialogOpen(true)
                    }}
                    size='small'
                    variant='text'
                  >
                    ADD
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ChipStatusSelect
                label='Statuses'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    statuses: value
                  })
                }}
                options={[
                  {
                    label: 'Open',
                    color: 'green',
                    value: 'isOpen'
                    // count: 0
                  },
                  {
                    label: 'Closed',
                    color: 'yellow',
                    value: 'isClosed'
                    // count: 0
                  }
                ]}
                value={parameters.statuses ?? []}
              />
            </Grid>

            {isGettingWorkOrderReleases && (
              <Grid item container alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                <Grid item>
                  <CircularProgress color='primary' size={50} />
                </Grid>
              </Grid>
            )}

            {!isGettingWorkOrderReleases &&
              workOrderReleases.map(workOrderRelease => (
                <Grid item xs={12} key={workOrderRelease.id}>
                  <Card variant='outlined'>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                          <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                            <Grid item xs={12} sm='auto'>
                              <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                                {workOrderRelease.releaseTitle}
                              </Typography>
                            </Grid>

                            {!workOrderRelease.isReleaseComplete && (
                              <Grid item>
                                <Chip variant='green'>Open</Chip>
                              </Grid>
                            )}

                            {workOrderRelease.isReleaseComplete && (
                              <Grid item>
                                <Chip variant='yellow'>Closed</Chip>
                              </Grid>
                            )}
                          </Grid>

                          <Grid item xs={12} sm='auto'>
                            {WORK_ORDER_RELEASES_ADD_EDIT && (
                              <Button
                                endIcon={<Edit />}
                                onClick={() => {
                                  setWorkOrderRelease(workOrderRelease)
                                  setWorkOrderReleaseAddEditDialogOpen(true)
                                }}
                                size='small'
                                variant='text'
                              >
                                EDIT
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <LabelField label='Release #' value={workOrderRelease.releaseNumber} />
                        </Grid>

                        <Grid item xs={12}>
                          <LabelField
                            label='Release Target Completion'
                            value={workOrderRelease.signOffDueDate ? formatDate(workOrderRelease.signOffDueDate) : ''}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </CardContent>

        <TablePagination
          pagination={{
            count: totalCount,
            page: parameters.page,
            pageSize: parameters.pageSize,
            onPageChange: (page: number) => {
              setParameters({ ...parameters, page })
            },
            onPageSizeChange: (pageSize: number) => {
              setParameters({ ...parameters, pageSize })
            }
          }}
          labelRowsPerPage='Cards per page:'
        />
      </Card>
    </>
  )
}
