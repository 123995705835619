import { PersonDto } from './person'
import * as yup from 'yup'

export class SecretDto {
  secretGuid?: string = undefined
  key?: string = undefined
  createdDateTime?: string = undefined
  updatedDateTime?: string = undefined
  lastViewedDateTime?: string = undefined
  lastViewedBy?: PersonDto = undefined
  value?: string = undefined
  notes?: string = undefined
}

export const secretValidationSchema = yup.object({
  key: yup.string().max(255, 'Key cannot be more than 255 characters.').required('Key is required.'),
  value: yup.string().max(255, 'Value cannot be more than 255 characters.').required('Value is required.'),
  notes: yup.string().max(2_040, 'Notes cannot be more than 2,040 characters.').nullable()
})
