import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material'
import { Copyright } from 'components'
import { GuestLayout } from 'components/_template'
import { useAuth } from 'context'
import { SignInDto, signInValidationSchema } from 'dtos'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { NavLink, Navigate, useLocation } from 'react-router-dom'
import LoadingScreen from './LoadingScreen'

export default function SignIn() {
  const { signIn, user, status } = useAuth()
  const location = useLocation()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Sign In | Spud ERP'
  }, [])

  return (
    <>
      {user && status === 'authorized' && <Navigate to={location.state?.from ?? '/'} />}

      {status === 'loading' && <LoadingScreen />}

      {status === 'unauthorized' && (
        <Formik
          enableReinitialize
          initialValues={new SignInDto()}
          validateOnBlur
          validateOnChange
          validationSchema={signInValidationSchema}
          onSubmit={values => {
            signIn(values)
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
            return (
              <GuestLayout handleSubmit={handleSubmit} title='Welcome to Spud ERP'>
                <TextField
                  autoComplete='email'
                  disabled={isSubmitting}
                  error={Boolean(touched.email && errors.email)}
                  focused
                  fullWidth
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ shrink: true }}
                  label='Email Address'
                  margin='normal'
                  name='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ''}
                />

                <TextField
                  autoComplete='current-password'
                  disabled={isSubmitting}
                  error={Boolean(touched.password && errors.password)}
                  focused
                  fullWidth
                  helperText={touched.password && errors.password}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword)
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  label='Password'
                  margin='normal'
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password || ''}
                />

                <Button disabled={isSubmitting} fullWidth type='submit' variant='contained'>
                  SIGN IN
                </Button>

                <Typography align='center'>
                  <Link component={NavLink} sx={{ textDecoration: 'none' }} to='/account/forgot-password'>
                    Forgot password?
                  </Link>
                </Typography>

                <Copyright sx={{ mt: 5 }} />
              </GuestLayout>
            )
          }}
        </Formik>
      )}
    </>
  )
}
