import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material'
import { AssetCategoryDto, AssetDto, PersonDto, assetValidationSchema } from 'dtos'
import * as controllers from 'controllers'
import { Formik, Form } from 'formik'
import { SetStateAction, useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

interface AssetAddEditDialogProps {
  initialValues: AssetDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: AssetDto) => void
  open: boolean
}

export default function AssetAddEditDialog({ initialValues, isLoading, onClose, onSave, open }: AssetAddEditDialogProps) {
  const [people, setPeople] = useState<PersonDto[]>([])
  const [assetCategories, setAssetCategories] = useState<AssetCategoryDto[]>([])

  useEffect(() => {
    controllers
      .getPeople({ page: 0, pageSize: 2000, statuses: ['isActive'], permission: 'TASKS' })
      .then(res => {
        setPeople(res.value)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    controllers
      .getCategories()
      .then(res => {
        setAssetCategories(res.value)
      })
      .catch(() => {})
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
          console.log('Asset Submitted', values)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={assetValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Asset</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.assetNumber && errors.assetNumber)}
                      fullWidth
                      helperText={touched.assetNumber && errors.assetNumber}
                      label='Asset Number'
                      name='assetNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.assetNumber}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Asset Name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label='Description'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.name}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('assetCategory', value)
                      }}
                      options={assetCategories.map(category => ({
                        id: category.id,
                        name: category.name
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.assetCategory && errors.assetCategory)}
                          fullWidth
                          helperText={touched.assetCategory && errors.assetCategory}
                          label='Asset Category'
                          name='assetCategory'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant='outlined'
                        />
                      )}
                      value={values.assetCategory ? { id: values.assetCategory.id, name: values.assetCategory.name } : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.serialNumber && errors.serialNumber)}
                      fullWidth
                      helperText={touched.serialNumber && errors.serialNumber}
                      label='Serial Number'
                      name='serialNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.serialNumber || ''}
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isLoading || isSubmitting}
                      disableListWrap
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      onBlur={handleBlur}
                      onChange={(_e, value) => {
                        setFieldValue('teamMember', value)
                      }}
                      options={people.map(teamMember => ({
                        id: teamMember.id,
                        firstName: teamMember.firstName,
                        lastName: teamMember.lastName
                      }))}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={Boolean(touched.teamMember && errors.teamMember)}
                          fullWidth
                          helperText={touched.teamMember && errors.teamMember}
                          label='Team Member'
                          name='teamMember'
                        />
                      )}
                      value={
                        values.teamMember
                          ? {
                              id: values.teamMember.id,
                              firstName: values.teamMember.firstName,
                              lastName: values.teamMember.lastName
                            }
                          : null
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.person && errors.person)}
                      fullWidth
                      helperText={touched.person && errors.person}
                      label='Person'
                      name='person'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.person || ''}
                      variant='outlined'
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.location && errors.location)}
                      fullWidth
                      helperText={touched.location && errors.location}
                      label='Location'
                      name='location'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.location || ''}
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Purchase Date'
                      name='purchaseDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('purchaseDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('purchaseDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.purchaseDate && errors.purchaseDate),
                          helperText: touched.purchaseDate && errors.purchaseDate
                        }
                      }}
                      value={values.purchaseDate ? moment(values.purchaseDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.purchasePrice && errors.purchasePrice)}
                      fullWidth
                      helperText={touched.purchasePrice && errors.purchasePrice}
                      InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                      label='Purchase Price'
                      type='number'
                      name='purchasePrice'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.purchasePrice || ''}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>
                <LoadingButton
                  onClick={() => {
                    console.log(errors)
                  }}
                  loading={isLoading || isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
