import { CircularProgress, Grid, Typography } from '@mui/material'

export default function LoadingScreen() {
  return (
    <Grid container spacing={2} direction='column' alignItems='center' justifyContent='center' sx={{ minHeight: '100vh' }}>
      <Grid item>
        <Typography variant='h4' fontWeight={600} display='block'>
          Spud ERP
        </Typography>
      </Grid>

      <Grid item>
        <CircularProgress size={40} />
      </Grid>
    </Grid>
  )
}
