import * as yup from 'yup'
import { maxString200Message, maxString200Value, requiredFieldMessage } from '../constants'
import { AssetCategoryDto, PersonDto } from 'dtos'

export class AssetDto {
  id?: string = undefined
  assetCategory?: AssetCategoryDto = undefined
  name: string = ''
  assetNumber?: number = undefined
  description?: string = undefined
  purchasePrice?: number = undefined
  purchaseDate?: string = undefined
  person?: PersonDto = undefined
  isActive: boolean = true
  serialNumber?: string = undefined
  notes?: string = undefined
  teamMember?: PersonDto = undefined
  location?: string = undefined

  //isInactive: boolean = false
}

export const assetValidationSchema = yup.object<AssetDto>({
  id: yup.string().nullable(),
  name: yup.string().max(200, 'Asset Name cannot be more than 200 characters.').required('Asset Name is required.'),
  assetNumber: yup.string().max(maxString200Value, maxString200Message).required(),
  assetCategory: yup.object().required('Asset Category is required'),
  description: yup
    .string()
    .max(2_000, 'Description cannot be more than 2,000 characters.')
    .required('Asset Description is required.'),
  purchasePrice: yup
    .number()
    .min(0.0, 'Purchase Price cannot be less than $0.00.')
    .max(999_999_999.99, 'Purchase Price cannot be more than $999,999,999.99.')
    .required(),
  purchaseDate: yup.string().required(),
  person: yup.object().nullable(),
  serialNumber: yup.string().nullable(),
  notes: yup.string().nullable(),
  teamMember: yup.object().nullable(),
  location: yup.string().nullable()
})
